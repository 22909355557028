import React from "react";
import { useQualityControl } from "./useQualityControl";
import PopUpComponent from "../../components/PopUp/PopUpComponent";
import { constantNoMesin } from "../../util/constant";
import "./QualityControl.css";

const QualityControl = () => {
  const {
    listProduk,
    handleFormChange,
    handleKirimQc,
    shift,
    setShift,
    popUpQC,
    setPopUpQC,
    popUpMessage,
    subMenuQc,
    setSubMenuQc,
    noMesinQc,
    setNoMesinQc,
    customerQc,
    setCustomerQc,
    moldQc,
    setMoldQc,
    beratTopPlate,
    setBeratTopPlate,
    handleKirimTopPlate,
    errorMessage,
    dataCavity,
    handleFormChangeCavity,
    setBeratToco,
    setBeratRunner,
    moldPick,
    setMoldPick,
    handleKirimDetailProduk,
    beratToco,
    beratRunner,
  } = useQualityControl();

  const sortData =
    listProduk &&
    listProduk.sort(function (a, b) {
      if (Number(a.mesin) < Number(b.mesin)) {
        return -1;
      }
      if (Number(a.mesin) > Number(b.mesin)) {
        return 1;
      }
      return 0;
    });

  return (
    <div className="container_qc">
      {popUpQC ? (
        <PopUpComponent>
          <p>{popUpMessage}</p>
          <div className="wrapper_button_submit_popup">
            <button className="button_popup" onClick={() => setPopUpQC(false)}>
              OK
            </button>
          </div>
        </PopUpComponent>
      ) : (
        ""
      )}
      <div className="wrapper_table_memo_ppic">
        <p className="title_inventory" style={{ marginTop: "24px" }}>
          Quality Control
        </p>
        <div className="wrapper_sub_menu_history">
          <p
            className={
              subMenuQc === "dataBerat"
                ? "sub_menu_history border_active_history"
                : "sub_menu_history"
            }
            onClick={() => setSubMenuQc("dataBerat")}
          >
            Data Berat
          </p>
          <p
            className={
              subMenuQc === "dataTopPlate"
                ? "sub_menu_history border_active_history"
                : "sub_menu_history"
            }
            onClick={() => setSubMenuQc("dataTopPlate")}
          >
            Data TopPlate
          </p>
          <p
            className={
              subMenuQc === "detailProduk"
                ? "sub_menu_history border_active_history"
                : "sub_menu_history"
            }
            onClick={() => setSubMenuQc("detailProduk")}
          >
            Detail Produk
          </p>
        </div>
        {errorMessage ? (
          <p style={{ color: "red", fontWeight: "bold" }}>
            Mohon Mengisi Semua Kolom!
          </p>
        ) : (
          ""
        )}
        <div style={{ marginBottom: "12px" }} id="selectShift">
          <p>
            Pilih Shift <b style={{ color: "red" }}>*</b>
          </p>
          <select
            className="input_select_shift"
            value={shift}
            onChange={(e) => setShift(e.target.value)}
          >
            <option selected={shift === "Pilih Shift"} disabled>
              Pilih Shift
            </option>
            <option value="1">Shift 1</option>
            <option value="2">Shift 2</option>
            <option value="3">Shift 3</option>
          </select>
        </div>
        {subMenuQc === "dataBerat" ? (
          <>
            <table style={{ width: "100%" }}>
              <tr className="container_header_memo_ppic">
                <th className="table_header_memo_ppic width_number_mesin">
                  Mc
                </th>
                <th className="table_header_memo_ppic customer_width">Cust</th>
                <th className="table_header_memo_ppic column_qc_width">Mold</th>
                <th className="table_header_memo_ppic column_qc_width">
                  Bahan
                </th>
                <th className="table_header_memo_ppic color_width">Color</th>
                <th className="table_header_memo_ppic jumlah_width_qc">
                  Berat (gr)
                </th>
              </tr>
              {sortData &&
                sortData.map((data, index) => (
                  <tr
                    className={
                      index % 2 === 0
                        ? "container_data_memo_ppic"
                        : "container_data_memo_ppic background_odd"
                    }
                    key={index}
                  >
                    <td className="width_number_mesin">
                      <p style={{ margin: "0 6px" }}>{data.mesin}</p>
                    </td>
                    <td className="min_width customer_width">
                      {data.customer}
                    </td>
                    <td className="min_width column_qc_width">
                      {data.moldType}
                    </td>
                    <td className="min_width column_qc_width">
                      {data.material}
                    </td>
                    <td className="min_width color_width">{data.color}</td>
                    <td className="jumlah_width_qc">
                      <input
                        className="input_berat_qc_width"
                        type="number"
                        name="beratQc"
                        value={data.beratQc}
                        onChange={(e) => handleFormChange(index, e, "BAKU")}
                        placeholder="Berat"
                        onWheel={(e) => e.target.blur()}
                      />
                      &nbsp; gr
                    </td>
                  </tr>
                ))}
            </table>
            <button
              className="button_submit_memo"
              onClick={() => handleKirimQc()}
            >
              Kirim
            </button>
          </>
        ) : subMenuQc === "dataTopPlate" ? (
          <>
            <div>
              <p>Pilih Mesin</p>
              <select
                className="input_select_mesin"
                style={{ width: "100%" }}
                value={noMesinQc}
                onChange={(e) => setNoMesinQc(e.target.value)}
              >
                <option selected={noMesinQc === "Pilih Mesin"} disabled>
                  Pilih Mesin
                </option>
                {constantNoMesin &&
                  constantNoMesin.map((data) => (
                    <option value={data}>{data}</option>
                  ))}
              </select>
            </div>
            <div>
              <p>Pilih Customer</p>
              <select
                className="input_select_mesin"
                style={{ width: "100%" }}
                value={customerQc}
                onChange={(e) => setCustomerQc(e.target.value)}
              >
                <option selected={customerQc === "Pilih Customer"} disabled>
                  Pilih Customer
                </option>
                <option value="GS">GS</option>
                <option value="TBP">TBP</option>
                <option value="YBI">YBI</option>
                <option value="CBI">CBI</option>
                <option value="MELCO">MELCO</option>
                <option value="TIM">TIM</option>
              </select>
            </div>
            <div>
              <p>
                Pilih Mold <b style={{ color: "red" }}>*</b>
              </p>
              <select
                className="input_select_mesin"
                style={{ width: "100%" }}
                value={moldQc}
                onChange={(e) => setMoldQc(e.target.value)}
              >
                <option selected={moldQc === "Pilih Mold"} disabled>
                  Pilih Mold
                </option>
                {listProduk &&
                  listProduk.map((obj, i) => {
                    if (obj.customer == customerQc) {
                      return (
                        <option value={obj.moldType}>{obj.moldType}</option>
                      );
                    }
                  })}
              </select>
            </div>
            <div>
              <p>Berat (gr)</p>
              <input
                className="input_jumlah"
                placeholder="Masukkan Berat"
                type="number"
                value={beratTopPlate}
                onChange={(e) => setBeratTopPlate(e.target.value)}
                onWheel={(e) => e.target.blur()}
              />
            </div>
            <button
              className="button_submit_memo"
              onClick={() => handleKirimTopPlate()}
            >
              Kirim
            </button>
          </>
        ) : (
          <>
            <div>
              <p>
                Pilih Mold <b style={{ color: "red" }}>*</b>
              </p>
              <select
                className="input_select_mesin"
                style={{ width: "100%" }}
                value={moldPick}
                onChange={(e) => setMoldPick(e.target.value)}
              >
                <option selected={moldPick === "Pilih Mold"} disabled>
                  Pilih Mold
                </option>
                {sortData &&
                  sortData.map((data) => (
                    <option value={data.moldType}>
                      Mc {data.mesin} - {data.moldType}
                    </option>
                  ))}
              </select>
            </div>

            <p style={{ marginBottom: "0px" }}>
              Berat Per-Cavity (gr) <b style={{ color: "red" }}>*</b>
            </p>
            <div style={{ display: "flex", flexWrap: "wrap" }}>
              {dataCavity &&
                dataCavity.map((e, index) => {
                  return (
                    <div
                      style={{
                        marginRight: "10px",
                        fontSize: "14px",
                        width: "48px",
                      }}
                    >
                      <p>Cav {e.cavity}</p>
                      <div
                        className="jumlah_width_qc"
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <input
                          className="input_berat_qc_width"
                          type="number"
                          name="berat"
                          style={{ width: "32px", fontSize: "12px" }}
                          value={e.berat}
                          onChange={(e) => handleFormChangeCavity(index, e)}
                          placeholder="Berat"
                          onWheel={(e) => e.target.blur()}
                        />
                      </div>
                    </div>
                  );
                })}
            </div>

            <div style={{ display: "flex", justifyContent: "space-around" }}>
              <div style={{ marginBottom: "12px" }} id="selectShift">
                <p>Berat Toco</p>
                <div
                  className="jumlah_width_qc"
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <input
                    className="input_berat_qc_width"
                    type="number"
                    style={{ width: "78px" }}
                    value={beratToco}
                    onChange={(e) => setBeratToco(e.target.value)}
                    placeholder="Berat"
                    onWheel={(e) => e.target.blur()}
                  />
                  &nbsp; gr
                </div>
              </div>
              <div style={{ marginBottom: "12px" }} id="selectShift">
                <p>
                  Berat Runner <b style={{ color: "red" }}>*</b>
                </p>
                <div
                  className="jumlah_width_qc"
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <input
                    className="input_berat_qc_width"
                    type="number"
                    style={{ width: "78px" }}
                    value={beratRunner}
                    onChange={(e) => setBeratRunner(e.target.value)}
                    placeholder="Berat"
                    onWheel={(e) => e.target.blur()}
                  />
                  &nbsp; gr
                </div>
              </div>
            </div>
            <button
              className="button_submit_memo"
              onClick={() => handleKirimDetailProduk()}
            >
              Kirim
            </button>
          </>
        )}
      </div>
      <div className="wrapper_table_memo_ppic"></div>
    </div>
  );
};
export default QualityControl;
