import React from "react";
import CardComponent from "../../components/Card/CardComponent";
import LogoGs from "../../Assets/GS-Logo.png";
import LogoYBI from "../../Assets/YBI-Logo.jpeg";
import LogoTBP from "../../Assets/TBP-Logo.jpeg";
import LogoMelco from "../../Assets/MELCO-Logo.png";
import LogoTIM from "../../Assets/TimLogo.png";
import LogoCBI from "../../Assets/CBI-Logo.png";
import LogoMitra from "../../Assets/Mitra_logo.png";
import TableInventory from "../../components/TableInventory/TableInventory";
import PopUpComponent from "../..//components/PopUp/PopUpComponent";
import CloseLogo from "../../Assets/close_icon.png";
import { useInventory } from "./useInventory";
import "./Inventory.css";
import { validateRole } from "../../util/utilFunction";
import moment from "moment";
import { bulan } from "../../util/constant";
import { filterMonthConstant } from "../../util/formatDate";
import SearchLogo from "../../Assets/search-icon.png";

const Inventory = () => {
  const {
    Material,
    setCompanyPick,
    companyPick,
    subMenu,
    setSubMenu,
    dataUser,
    handlePickItem,
    popUpKirimPo,
    setPopUpKirimPo,
    dataKeluar,
    keteranganKirim,
    setKeteranganKirim,
    jumlahKirim,
    setJumlahKirim,
    handleKirimPo,
    errMsg,
    setTanggalKirim,
    tanggalKirim,
    Crusher,
    popUpSearch,
    setPopUpSearch,
    setDataPickHistory,
    tahunPickHistory,
    setTahunPickHistory,
    bulanPickHistory,
    setBulanPickHistory,
    Pewarna,
    setValueSearch,
    dataTemp,
    handlePick,
    valueSearch,
    handleExportBarangJadi,
    setFilterStatus,
    filterStatus,
    dataFilterStatus,
    dataMonitoringKedatanganBahanTemp,
    filterMonthKedatangan,
    setFilterMonthKedatangan,
    filterYearKedatangan,
    setFilterYearKedatangan,
    filterJenisBarang,
    setJenisBarang,
    dataJenisBarang,
    popUpExportMonitoring,
    setPopUpExportMonitoring,
    handleExportMonitoring,
    filterDateExportFrom,
    setFilterDateExportFrom,
    filterDateExportUntil,
    setFilterDateExportUntil,
    handleFilterFrom,
    filterDatePopUpUntil,
    setFilterDatePopUpUntil,
    filterDatePopUpFrom,
    setIsFilter,
    today,
    setFilterDatePopUpFrom,
    handleFilterUntil,
  } = useInventory();

  const formatDot = (value) => {
    return value && value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  };

  const handleCloseSearch = () => {
    setPopUpSearch(false);
    setFilterStatus("Status");
    setIsFilter(false);
    setFilterDatePopUpFrom(today);
    setFilterDatePopUpUntil(today);
  };

  const handleReset = () => {
    setFilterStatus("Status");
    setIsFilter(false);
    setFilterDatePopUpFrom(today);
    setFilterDatePopUpUntil(today);
  };

  let filterKedatangan = [];

  dataMonitoringKedatanganBahanTemp &&
    dataMonitoringKedatanganBahanTemp.forEach((e) => {
      let check =
        filterKedatangan && filterKedatangan.find((o) => o === e.type);
      if (!check) {
        filterKedatangan.push(e.type);
      }
    });

  const sum =
    dataFilterStatus &&
    dataFilterStatus.reduce(function (s, a) {
      return s + a.dataWeight;
    }, 0);

  return (
    <div className="container_inventory">
      {popUpSearch ? (
        <PopUpComponent
          style={{
            width: "80%",
            marginTop: "calc(100vh - 95vh - 20px)",
            height: "90%",
          }}
        >
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div style={{ display: "flex" }}>
              <div className="margin_right_27_qc">
                <p>Bulan</p>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <select
                    defaultValue="Bulan"
                    onChange={(e) => setBulanPickHistory(e.target.value)}
                    className="input_select_memo_ppic"
                    style={{ width: "100%" }}
                    value={bulanPickHistory}
                  >
                    <option selected disabled>
                      Bulan
                    </option>
                    {bulan.map((ele) => (
                      <option value={ele.value}>{ele.name}</option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="margin_right_27_qc">
                <p>Tahun</p>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <select
                    defaultValue="Tahun"
                    onChange={(e) => setTahunPickHistory(e.target.value)}
                    className="input_select_memo_ppic"
                    style={{ width: "100%" }}
                    value={tahunPickHistory}
                  >
                    <option selected disabled>
                      Tahun
                    </option>
                    <option value="2024">2024</option>
                    <option value="2025">2025</option>
                    <option value="2026">2026</option>
                  </select>
                </div>
              </div>
              <div className="margin_right_27_qc">
                <p>Status</p>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <select
                    defaultValue="Status"
                    onChange={(e) => setFilterStatus(e.target.value)}
                    className="input_select_memo_ppic"
                    style={{ width: "100%" }}
                    value={filterStatus}
                  >
                    <option selected disabled>
                      Status
                    </option>
                    <option value="Masuk">Masuk</option>
                    <option value="Keluar">Keluar</option>
                  </select>
                </div>
              </div>

              <div className="margin_right_27_qc">
                <p>Dari</p>
                <input
                  type="date"
                  style={{ margin: "0" }}
                  className="date_pick_memo_ppic"
                  value={filterDatePopUpFrom}
                  onChange={(e) => handleFilterFrom(e)}
                />
              </div>
              <div className="margin_right_27_qc">
                <p>Sampai</p>
                <input
                  type="date"
                  style={{ margin: "0" }}
                  className="date_pick_memo_ppic"
                  value={filterDatePopUpUntil}
                  onChange={(e) => handleFilterUntil(e)}
                />
              </div>

              <div style={{ display: "flex", alignItems: "end" }}>
                <p
                  className="btn_reset_search_inventory"
                  style={{ margin: "0px" }}
                  onClick={() => handleReset()}
                >
                  Reset
                </p>
              </div>
            </div>
            <img
              className="width_edit_logo"
              src={CloseLogo}
              onClick={() => handleCloseSearch()}
              alt="edit_logo"
            />
          </div>
          <br />

          <div className="table_inventory_mobile popUp_history_search">
            <table style={{ width: "100%" }}>
              <tr>
                <td
                  className="font_td_laporan_bahan_baku"
                  style={{
                    padding: "12px 6px",
                    lineHeight: "18px",
                  }}
                ></td>
                <td
                  className="font_td_laporan_bahan_baku"
                  style={{ padding: "12px 6px" }}
                ></td>
                <td
                  className="font_td_laporan_bahan_baku"
                  style={{ padding: "12px 6px" }}
                >
                  <b>TOTAL</b>
                </td>
                <td
                  className="font_td_laporan_bahan_baku"
                  style={{ padding: "12px 6px", letterSpacing: "0.5px" }}
                >
                  {formatDot(sum && sum)}
                </td>
                <td
                  className="font_td_laporan_bahan_baku"
                  style={{ padding: "12px 6px", letterSpacing: "0.5px" }}
                ></td>
                <td
                  className="font_td_laporan_bahan_baku"
                  style={{ padding: "12px 6px" }}
                ></td>
                <td
                  className="font_td_laporan_bahan_baku"
                  style={{ padding: "12px 6px" }}
                ></td>
              </tr>
              <tr>
                <th
                  className="table_header"
                  style={{
                    background: "#e8e9f8",
                    padding: "12px 6px",
                    width: "6%",
                    borderRight: "1px solid lightgray",
                    width: "5%",
                  }}
                >
                  No
                </th>
                <th
                  className="table_header"
                  style={{
                    background: "#e8e9f8",
                    borderRight: "1px solid lightgray",
                    padding: "6px",
                    width: "15%",
                  }}
                >
                  Tanggal
                </th>
                <th
                  className="table_header"
                  style={{
                    background: "#e8e9f8",
                    borderRight: "1px solid lightgray",
                    padding: "6px",
                    width: "30%",
                  }}
                >
                  Material
                </th>
                <th
                  className="table_header"
                  style={{
                    background: "#e8e9f8",
                    borderRight: "1px solid lightgray",
                    padding: "6px",
                    width: "10%",
                  }}
                >
                  {subMenu === "bahanBaku" ? "Jumlah (Kg)" : "Jumlah (Pcs)"}
                </th>
                <th
                  className="table_header"
                  style={{
                    background: "#e8e9f8",
                    borderRight: "1px solid lightgray",
                    padding: "6px",
                    width: "10%",
                  }}
                >
                  Status
                </th>
                <th
                  className="table_header"
                  style={{
                    background: "#e8e9f8",
                    borderRight: "1px solid lightgray",
                    padding: "6px",
                    width: "10%",
                  }}
                >
                  Sisa
                </th>
                <th
                  className="table_header"
                  style={{
                    background: "#e8e9f8",
                    borderRight: "1px solid lightgray",
                    padding: "6px",
                    width: "10%",
                  }}
                >
                  Operator
                </th>
                <th
                  className="table_header"
                  style={{
                    background: "#e8e9f8",
                    borderRight: "1px solid lightgray",
                    padding: "6px",
                    width: "19%",
                  }}
                >
                  Keterangan
                </th>
              </tr>
              {dataFilterStatus &&
                dataFilterStatus.map((e, index) => {
                  return (
                    <tr
                      style={
                        index % 2 === 0
                          ? {
                              background: "white",
                              fontSize: "12px",
                            }
                          : {
                              background: "#f9f9f9",
                              fontSize: "12px",
                            }
                      }
                    >
                      <td
                        className="font_td_laporan_bahan_baku"
                        style={{
                          padding: "12px 6px",
                          lineHeight: "18px",
                        }}
                      >
                        {index + 1}
                      </td>
                      <td
                        className="font_td_laporan_bahan_baku"
                        style={{ padding: "12px 6px" }}
                      >
                        {e && moment(e.updateTime).format("ddd MMM DD YYYY")}
                      </td>
                      <td
                        className="font_td_laporan_bahan_baku"
                        style={{ padding: "12px 6px" }}
                      >
                        {e && e.material}
                      </td>
                      <td
                        className="font_td_laporan_bahan_baku"
                        style={{ padding: "12px 6px" }}
                      >
                        {formatDot(e && e.dataWeight)}
                      </td>
                      <td
                        className="font_td_laporan_bahan_baku"
                        style={
                          e && e.statusBahan === "Masuk"
                            ? { padding: "12px 6px", color: "green" }
                            : { padding: "12px 6px", color: "red" }
                        }
                      >
                        {e && e.statusBahan}
                      </td>
                      <td
                        className="font_td_laporan_bahan_baku"
                        style={{ padding: "12px 6px" }}
                      >
                        {e && e.statusBahan === "Masuk"
                          ? formatDot(e.weightBefore + e.dataWeight)
                          : formatDot(e.weightBefore - e.dataWeight)}
                      </td>
                      <td
                        className="font_td_laporan_bahan_baku"
                        style={{ padding: "12px 6px" }}
                      >
                        {e && e.updatedBy.split(" ").slice(0, 1).join(" ")}
                      </td>
                      <td
                        className="font_td_laporan_bahan_baku"
                        style={{ padding: "12px 6px" }}
                      >
                        {e && e.keterangan}
                      </td>
                    </tr>
                  );
                })}
            </table>
            <br />
          </div>
        </PopUpComponent>
      ) : (
        ""
      )}
      {/* {popUpKirimPo ? (
        <PopUpComponent>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <p>
              <b>{dataKeluar.material}</b>
            </p>
            <img
              className="width_edit_logo"
              src={CloseLogo}
              onClick={() => setPopUpKirimPo(false)}
              alt="edit_logo"
            />
          </div>
          {errMsg ? (
            <p style={{ color: "red" }}>
              Mohon Mengisi Semua Kolom Berbintang merah!
            </p>
          ) : (
            ""
          )}
          <div className="margin_right_27_qc">
            <p>Pilih Tanggal</p>
            <input
              type="date"
              className="date_pick_memo_ppic"
              value={tanggalKirim}
              onChange={(e) => setTanggalKirim(e.target.value)}
            />
          </div>
          <div style={{ display: "flex", flexWrap: "wrap" }}>
            <div className="margin_kirim_po">
              <p>
                Keterangan <b style={{ color: "red" }}>*</b>
              </p>
              <input
                className="input_kirim_po"
                type="text"
                placeholder="Keterangan"
                value={keteranganKirim}
                onChange={(e) => setKeteranganKirim(e.target.value)}
              />
            </div>
            <div>
              <p>
                Jumlah Kirim <b style={{ color: "red" }}>*</b>
              </p>
              <input
                className="input_kirim_po"
                type="number"
                placeholder="Jumlah Barang Kirim (Pcs)"
                value={jumlahKirim}
                onChange={(e) => setJumlahKirim(e.target.value)}
              />
            </div>
          </div>
          <button
            className="button_submit_memo"
            style={{ margin: "24px 0 0 0" }}
            onClick={() => handleKirimPo()}
          >
            Kirim
          </button>
        </PopUpComponent>
      ) : (
        ""
      )} */}
      {popUpExportMonitoring ? (
        <PopUpComponent>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div style={{ display: "flex" }}>
              <div className="margin_right_27_qc">
                <p>Dari</p>
                <input
                  type="date"
                  className="date_pick_memo_ppic"
                  value={filterDateExportFrom}
                  onChange={(e) => setFilterDateExportFrom(e.target.value)}
                />
              </div>
              <div className="margin_right_27_qc">
                <p>Sampai</p>
                <input
                  type="date"
                  className="date_pick_memo_ppic"
                  value={filterDateExportUntil}
                  onChange={(e) => setFilterDateExportUntil(e.target.value)}
                />
              </div>
            </div>
            <img
              className="width_edit_logo"
              src={CloseLogo}
              onClick={() => setPopUpExportMonitoring(false)}
              alt="edit_logo"
            />
          </div>
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <p
              style={{
                background: "red",
                color: "white",
                padding: "12px",
                borderRadius: "6px",
                margin: "0",
                cursor: "pointer",
                marginLeft: "24px",
              }}
              onClick={() => handleExportMonitoring()}
            >
              Export
            </p>
          </div>
        </PopUpComponent>
      ) : (
        ""
      )}
      <div className="wrapper_header">
        <p className="title_inventory">INVENTORY</p>
      </div>
      <div className="flex_wrap">
        <CardComponent
          active={companyPick === "GS"}
          title="PT. GS"
          logo={LogoGs}
          onClick={() => setCompanyPick("GS")}
        />
        <CardComponent
          active={companyPick === "YBI"}
          title="PT. YBI"
          logo={LogoYBI}
          onClick={() => setCompanyPick("YBI")}
        />
        <CardComponent
          active={companyPick === "TBP"}
          title="PT. TBP"
          logo={LogoTBP}
          onClick={() => setCompanyPick("TBP")}
        />
        <CardComponent
          active={companyPick === "MELCO"}
          title="PT. MELCO"
          logo={LogoMelco}
          onClick={() => setCompanyPick("MELCO")}
        />
        <CardComponent
          active={companyPick === "CBI"}
          title="PT. CBI"
          logo={LogoCBI}
          onClick={() => setCompanyPick("CBI")}
        />
        {dataUser.tim === 2 ? (
          <CardComponent
            active={companyPick === "MITRA"}
            title="PT. MITRA"
            logo={LogoMitra}
            onClick={() => setCompanyPick("MITRA")}
          />
        ) : (
          ""
        )}
        <CardComponent
          active={companyPick === "TIM"}
          title="PT. TIM"
          logo={LogoTIM}
          onClick={() => setCompanyPick("TIM")}
        />
      </div>

      <div className="wrapper_sub_menu_history">
        {dataUser.role === "super_user" ||
        dataUser.role === "warehouse_admin" ||
        dataUser.role === "warehouse_gbj_admin" ||
        dataUser.role === "ppic_admin" ||
        dataUser.role === "ppic_user" ? (
          <>
            <p
              className={
                subMenu === "MonitorKedatangan"
                  ? "sub_menu_history border_active_history"
                  : "sub_menu_history"
              }
              onClick={() => setSubMenu("MonitorKedatangan")}
            >
              Kedatangan
            </p>
          </>
        ) : (
          ""
        )}
        {validateRole() === "" || validateRole() === "super" ? (
          <>
            <p
              className={
                subMenu === "bahanBaku"
                  ? "sub_menu_history border_active_history"
                  : "sub_menu_history"
              }
              onClick={() => setSubMenu("bahanBaku")}
            >
              Bahan Baku
            </p>
            {dataUser.role === "super_user" ||
            dataUser.role === "warehouse_admin" ||
            dataUser.role === "warehouse_gbj_admin" ||
            dataUser.role === "ppic_admin" ||
            dataUser.role === "ppic_user" ? (
              <>
                <p
                  className={
                    subMenu === "barangJadi"
                      ? "sub_menu_history border_active_history"
                      : "sub_menu_history"
                  }
                  onClick={() => setSubMenu("barangJadi")}
                >
                  Barang Jadi
                </p>
              </>
            ) : (
              ""
            )}
          </>
        ) : (
          ""
        )}
      </div>

      {subMenu === "bahanBaku" ? (
        <>
          {dataUser.role !== "warehouse_crusher" ? (
            <>
              <p className="title_inventory">BAHAN BAKU PT. {companyPick}</p>
              <TableInventory
                data={Material && Material.bahanBaku}
                setPopUpSearch={setPopUpSearch}
                setDataPickHistory={setDataPickHistory}
              />
            </>
          ) : (
            ""
          )}
          <p className="title_inventory">Crusher PT. {companyPick}</p>
          <TableInventory
            data={Crusher && Crusher.data}
            setPopUpSearch={setPopUpSearch}
            setDataPickHistory={setDataPickHistory}
          />

          {dataUser.role !== "warehouse_crusher" && Pewarna ? (
            <>
              <p className="title_inventory">PEWARNA PT. {companyPick}</p>
              <TableInventory
                data={Pewarna && Pewarna.data}
                setPopUpSearch={setPopUpSearch}
                setDataPickHistory={setDataPickHistory}
              />
            </>
          ) : (
            ""
          )}
        </>
      ) : subMenu === "barangJadi" ? (
        <>
          <p className="title_inventory">STOCK BARANG JADI PT. {companyPick}</p>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              flexWrap: "wrap",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <input
                className="search_box_inventory"
                type="text"
                placeholder="Cari Barang"
                value={valueSearch}
                onChange={(e) => setValueSearch(e.target.value)}
              />
              <p className="search_image_inventory">
                <img src={SearchLogo} style={{ width: "22px" }} />
              </p>
              <p
                className="btn_reset_search_inventory"
                onClick={() => setValueSearch("")}
              >
                Reset
              </p>
            </div>
            {dataUser.role === "super_user" ||
            dataUser.role === "warehouse_admin" ||
            dataUser.role === "warehouse_gbj_admin" ? (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                }}
              >
                <button
                  className="export_btn_inventory"
                  onClick={() => handleExportBarangJadi()}
                >
                  Export Laporan
                </button>
              </div>
            ) : (
              ""
            )}
          </div>
          <div className="table_inventory_mobile">
            <table style={{ width: "100%" }}>
              <tr>
                <th
                  className="table_header"
                  style={{ width: "5%", position: "inherit" }}
                >
                  No.
                </th>
                <th className="table_header" style={{ width: "35%" }}>
                  Nama Produk
                </th>
                <th className="table_header" style={{ width: "15%" }}>
                  Jumlah (Pcs)
                </th>
                <th className="table_header" style={{ width: "20%" }}>
                  Updated By
                </th>
                <th className="table_header" style={{ width: "25%" }}>
                  Update Date
                </th>
                <th className="table_header" style={{ width: "3%" }}></th>
                {/* {(dataUser && dataUser.role === "super_user") ||
                dataUser.role === "warehouse_admin" ||
                dataUser.role === "warehouse_gbj_admin" ? (
                  <th className="table_header" style={{ width: "4%" }}></th>
                ) : (
                  ""
                )} */}
              </tr>
              {dataTemp &&
                dataTemp.map((element, index) => {
                  return (
                    <tr
                      style={
                        index % 2 === 0
                          ? { background: "white" }
                          : { background: "#f9f9f9" }
                      }
                    >
                      <td className="font_data" style={{ width: "5%" }}>
                        {index + 1}
                      </td>
                      <td
                        className="font_data"
                        style={{
                          whiteSpace: "nowrap",
                          padding: "18px",
                          width: "35%",
                        }}
                      >
                        {element && element.material}
                      </td>
                      <td
                        className="font_data"
                        style={
                          element && element.weight < 0
                            ? { padding: "18px", width: "15%", color: "red" }
                            : { padding: "18px", width: "15%" }
                        }
                      >
                        {element && element.weight < 0
                          ? `(${formatDot(
                              Math.round(element && element.weight)
                            )})`
                          : `${formatDot(
                              Math.round(element && element.weight)
                            )}`}
                      </td>
                      <td
                        className="font_data"
                        style={{ padding: "18px", width: "20%" }}
                      >
                        {element &&
                          element.updatedBy.split(" ").slice(0, 1).join(" ")}
                      </td>
                      <td
                        className="font_data"
                        style={{ padding: "18px", width: "25%" }}
                      >
                        {element && element.updateTime
                          ? moment(element.updateTime).format(
                              "ddd MMM DD YYYY - HH:mm:ss"
                            )
                          : ""}
                      </td>
                      <td>
                        <div
                          style={{ width: "10%", cursor: "pointer" }}
                          onClick={() => handlePick(element)}
                        >
                          <img
                            className="img_search"
                            src={SearchLogo}
                            alt="search-logo"
                          />
                        </div>
                      </td>
                      {/* {dataUser.role === "super_user" ||
                      dataUser.role === "warehouse_admin" ||
                      dataUser.role === "warehouse_gbj_admin" ? (
                        <td style={{ width: "5%" }}>
                          <p
                            onClick={() => handlePickItem(element)}
                            className="button_kirim_po"
                            style={{ padding: "6px" }}
                          >
                            Kirim
                          </p>
                        </td>
                      ) : (
                        ""
                      )} */}
                    </tr>
                  );
                })}
            </table>
          </div>
        </>
      ) : "MonitorKedatangan" ? (
        <div>
          <div style={{ display: "flex" }}>
            <select
              defaultValue="Pilih Bulan"
              onChange={(e) => setFilterMonthKedatangan(e.target.value)}
              className="input_select_memo_ppic"
              value={filterMonthKedatangan}
              style={{ marginRight: "12px" }}
            >
              <option disabled>Pilih Bulan</option>
              {filterMonthConstant &&
                filterMonthConstant.map((data) => (
                  <option value={data.value}>{data.label}</option>
                ))}
            </select>
            <select
              defaultValue="Tahun"
              onChange={(e) => setFilterYearKedatangan(e.target.value)}
              className="input_select_memo_ppic"
              value={filterYearKedatangan}
              style={{ marginRight: "12px" }}
            >
              <option disabled>Tahun</option>
              <option value="2024">2024</option>
              <option value="2025">2025</option>
              <option value="2026">2026</option>
            </select>
            <select
              defaultValue="Tahun"
              onChange={(e) => setJenisBarang(e.target.value)}
              className="input_select_memo_ppic"
              value={filterJenisBarang}
            >
              <option disabled>Pilih Jenis</option>
              {filterKedatangan &&
                filterKedatangan.map((element) => (
                  <option value={element}>{element}</option>
                ))}
            </select>
            <div style={{ display: "flex" }}>
              <p
                style={{
                  background: "green",
                  color: "white",
                  padding: "12px",
                  borderRadius: "6px",
                  margin: "0",
                  cursor: "pointer",
                  marginLeft: "24px",
                }}
                onClick={() => setPopUpExportMonitoring(true)}
              >
                Export
              </p>
            </div>
          </div>
          <p
            style={{ margin: "24px 0 0 0", color: "red", fontStyle: "italic" }}
          >
            <label style={{ color: "red" }}>&#9888; </label>Satuan untuk{" "}
            <b>Aksesoris</b> : Pcs, <b>Timah</b> : Pcs, <b>Bahan Baku</b> : Kg,{" "}
            <b>Pewarna, Cat & Thinner</b> : Gr
          </p>
          <div style={{ overflowX: "auto" }}>
            <table>
              <tr>
                <th
                  className="sidebar-monitoring"
                  style={{ background: "none", border: "none", zIndex: -1 }}
                >
                  No.
                </th>
                <th
                  className="sidebar-monitoring"
                  style={{ background: "none", border: "none" }}
                >
                  CUST
                </th>
                <th
                  className="sidebar-monitoring"
                  style={{
                    background: "none",
                    left: "69px",
                    border: "none",
                  }}
                >
                  NAMA BAHAN
                </th>
              </tr>
              {dataJenisBarang &&
                dataJenisBarang.map((obj, index) => {
                  const sortByTanggal =
                    obj &&
                    obj.data.sort(function (a, b) {
                      if (a.updateTime < b.updateTime) {
                        return -1;
                      }
                      if (a.updateTime > b.updateTime) {
                        return 1;
                      }
                      return 0;
                    });

                  const sumKedatangan =
                    obj &&
                    obj.data.reduce(function (s, a) {
                      return s + a.dataWeight;
                    }, 0);

                  return (
                    <tr>
                      <td
                        className="sidebar-monitoring"
                        style={{
                          background: "#FBDEC2",
                          wordBreak: "break-word",
                          borderBottom: "1px solid white",
                          zIndex: -1,
                        }}
                      >
                        {index + 1}.
                      </td>
                      <td
                        className="sidebar-monitoring"
                        style={{
                          background: "#FBDEC2",
                          wordBreak: "break-word",
                          borderBottom: "1px solid white",
                        }}
                      >
                        {obj.company}
                      </td>
                      <td
                        className="sidebar-monitoring"
                        style={{
                          left: "69px",
                          background: "#FBDEC2",
                          borderBottom: "1px solid white",
                          whiteSpace: "nowrap",
                        }}
                      >
                        {obj.material}
                        <br />
                        <br />
                        <b>Total</b>
                        <br />
                        <b>{formatDot(sumKedatangan && sumKedatangan)}</b>
                      </td>

                      {sortByTanggal &&
                        sortByTanggal.map((e, index) => {
                          return (
                            <td
                              style={
                                index % 2 === 0
                                  ? {
                                      background: "#94D9DA",
                                      padding: "12px 12px",
                                      borderBottom: "1px solid white",
                                    }
                                  : {
                                      background: "#B5E1E0",
                                      padding: "12px 12px",
                                      borderBottom: "1px solid white",
                                    }
                              }
                            >
                              <p
                                style={{
                                  margin: 0,
                                  fontWeight: "bold",
                                  fontSize: "18px",
                                }}
                              >
                                {e.updateTime}
                              </p>
                              <p
                                style={{
                                  margin: "6px 0 0 0",
                                  lineHeight: "24px",
                                }}
                              >
                                {formatDot(e.dataWeight)}
                              </p>
                              <p
                                style={{
                                  margin: "6px 0 0 0",
                                  lineHeight: "24px",
                                  maxWidth: "150px",
                                  fontSize: "12px",
                                }}
                              >
                                {e.keterangan}
                              </p>
                            </td>
                          );
                        })}
                    </tr>
                  );
                })}
            </table>
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default Inventory;
