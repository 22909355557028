import React from "react";
import { constantNoMesin } from "../../util/constant";
import { useNewMemoPPIC } from "./useNewMemoPPIC";
import PopUpComponent from "../../components/PopUp/PopUpComponent";
import Select from "react-select";
import DeleteIcon from "../../Assets/delete_icon.png";
import "./MemoPPIC.css";

const NewMemoPPIC = () => {
  const {
    listProduk,
    addFields,
    memoBahanPokok,
    handleFormChange,
    removeFields,
    handleChangeSubMenu,
    subMenuMemo,
    handleSubmitBahanBaku,
    dateMemoPPIC,
    setDateMemoPPIC,
    popUpMemoPPIC,
    setPopUpMemoPPIC,
    memoGantiUtama,
    handleFormChangeGantiUtama,
    handleSubmitGantiMold,
    handleDeleteMemoGantiMold,
  } = useNewMemoPPIC();

  const sortData =
    memoBahanPokok &&
    memoBahanPokok.sort(function (a, b) {
      if (Number(a.mesin) < Number(b.mesin)) {
        return -1;
      }
      if (Number(a.mesin) > Number(b.mesin)) {
        return 1;
      }
      return 0;
    });

  return (
    <div className="container_memo_ppic">
      {popUpMemoPPIC ? (
        <PopUpComponent>
          <p>Terima Kasih! Data Kamu telah di submit!</p>
          <div className="wrapper_button_submit_popup">
            <button
              className="button_popup background_red"
              onClick={() => setPopUpMemoPPIC(false)}
            >
              OK
            </button>
          </div>
        </PopUpComponent>
      ) : (
        ""
      )}
      <p className="title_inventory">MEMO PPIC</p>
      <div className="wrapper_sub_menu_history" style={{ margin: 0 }}>
        <p
          className={
            subMenuMemo === "BAKU"
              ? "sub_menu_history border_active_history"
              : "sub_menu_history"
          }
          onClick={() => handleChangeSubMenu("BAKU")}
        >
          Memo Produksi
        </p>
        <p
          className={
            subMenuMemo === "GANTI_MOLD"
              ? "sub_menu_history border_active_history"
              : "sub_menu_history"
          }
          onClick={() => handleChangeSubMenu("GANTI_MOLD")}
        >
          Ganti Produksi
        </p>
      </div>
      <br />
      {subMenuMemo === "BAKU" ? (
        <>
          <p>Pilih Tanggal</p>
          <input
            type="date"
            className="date_pick_memo_ppic"
            defaultValue={dateMemoPPIC}
            value={dateMemoPPIC}
            onChange={(e) => setDateMemoPPIC(e.target.value)}
          />
          <table style={{ width: "100%" }}>
            <tr>
              <th
                className="table_header"
                style={{
                  background: "#e8e9f8",
                  borderRight: "1px solid lightgray",
                  padding: "12px",
                  width: "5%",
                }}
              >
                MC
              </th>
              <th
                className="table_header"
                style={{
                  background: "#e8e9f8",
                  borderRight: "1px solid lightgray",
                  padding: "12px",
                  width: "5%",
                }}
              >
                Customer
              </th>
              <th
                className="table_header"
                style={{
                  background: "#e8e9f8",
                  borderRight: "1px solid lightgray",
                  padding: "12px",
                  width: "auto",
                }}
              >
                Mold
              </th>
              <th
                className="table_header"
                style={{
                  background: "#e8e9f8",
                  borderRight: "1px solid lightgray",
                  padding: "12px",
                  width: "20%",
                }}
              >
                Warna
              </th>
              <th
                className="table_header"
                style={{
                  background: "#e8e9f8",
                  borderRight: "1px solid lightgray",
                  padding: "12px",
                  width: "20%",
                }}
              >
                Bahan
              </th>
              <th
                className="table_header"
                style={{
                  background: "#e8e9f8",
                  borderRight: "1px solid lightgray",
                  padding: "12px",
                  width: "10%",
                }}
              >
                Total
              </th>
            </tr>
            {sortData &&
              sortData.map((input, index) => {
                return (
                  <tr
                    style={
                      index % 2 === 0
                        ? {
                            background: "white",
                            fontSize: "12px",
                          }
                        : {
                            background: "#f9f9f9",
                            fontSize: "12px",
                          }
                    }
                  >
                    <td
                      className="font_td_laporan_bahan_baku"
                      style={{ padding: "18px 0 0 12px" }}
                    >
                      {input.mesin}
                    </td>
                    <td
                      className="font_td_laporan_bahan_baku"
                      style={{ padding: "12px 0 0 12px" }}
                    >
                      <select
                        defaultValue="Pilih Customer"
                        onChange={(e) => handleFormChange(index, e, "BAKU")}
                        className="input_select_memo_ppic"
                        style={{ width: "64px" }}
                        value={
                          input.customer ? input.customer : "Pilih Customer"
                        }
                        name="customer"
                      >
                        {input.customer === "" ? (
                          <option selected={input.customer === ""} disabled>
                            Pilih Customer
                          </option>
                        ) : (
                          <option value={input.customer} disabled>
                            {input.customer}
                          </option>
                        )}
                        {listProduk &&
                          listProduk.map((data) => (
                            <option value={data.company}>{data.company}</option>
                          ))}
                      </select>
                    </td>
                    <td
                      className="font_td_laporan_bahan_baku"
                      style={{ padding: "12px 24px 12px 24px" }}
                    >
                      <div className="select_item_gbj">
                        <Select
                          placeholder={input.moldType}
                          options={input.listMoldProduk}
                          onChange={(e) =>
                            handleFormChange(index, e, "BAKU", "moldType")
                          }
                        />
                      </div>
                    </td>
                    <td
                      className="font_td_laporan_bahan_baku"
                      style={{ padding: "18px 0 0 12px" }}
                    >
                      {input.color}
                    </td>
                    <td
                      className="font_td_laporan_bahan_baku"
                      style={{ padding: "18px 0 0 12px" }}
                    >
                      {input.material}
                    </td>
                    <td
                      className="font_td_laporan_bahan_baku"
                      style={{ padding: "18px 0 0 12px" }}
                    >
                      {input.total} Kg
                    </td>
                  </tr>
                );
              })}
          </table>
          <button
            className="button_submit_memo"
            onClick={() => handleSubmitBahanBaku()}
          >
            Kirim
          </button>
        </>
      ) : (
        <>
          <p className="title_inventory">GANTI PRODUKSI</p>
          <p>Pilih Tanggal</p>
          <input
            type="date"
            className="date_pick_memo_ppic"
            value={dateMemoPPIC}
            onChange={(e) => setDateMemoPPIC(e.target.value)}
          />
          <table style={{ width: "100%" }}>
            <tr>
              <th
                className="table_header"
                style={{
                  background: "#e8e9f8",
                  borderRight: "1px solid lightgray",
                  padding: "12px",
                  width: "5%",
                }}
              >
                MC
              </th>
              <th
                className="table_header"
                style={{
                  background: "#e8e9f8",
                  borderRight: "1px solid lightgray",
                  padding: "12px",
                  width: "5%",
                }}
              >
                Urutan
              </th>
              <th
                className="table_header"
                style={{
                  background: "#e8e9f8",
                  borderRight: "1px solid lightgray",
                  padding: "12px",
                  width: "8%",
                }}
              >
                Cust
              </th>
              <th
                className="table_header"
                style={{
                  background: "#e8e9f8",
                  borderRight: "1px solid lightgray",
                  padding: "12px",
                  width: "auto",
                }}
              >
                Mold
              </th>
              <th
                className="table_header"
                style={{
                  background: "#e8e9f8",
                  borderRight: "1px solid lightgray",
                  padding: "12px",
                  width: "15%",
                }}
              >
                Warna
              </th>
              <th
                className="table_header"
                style={{
                  background: "#e8e9f8",
                  borderRight: "1px solid lightgray",
                  padding: "12px",
                  width: "17%",
                }}
              >
                Material
              </th>
              <th
                className="table_header"
                style={{
                  background: "#e8e9f8",
                  borderRight: "1px solid lightgray",
                  padding: "12px",
                  width: "12%",
                }}
              >
                Order
              </th>
              <th></th>
            </tr>
            {memoGantiUtama &&
              memoGantiUtama.map((input, index) => {
                return (
                  <tr
                    style={
                      index % 2 === 0
                        ? {
                            background: "white",
                            fontSize: "12px",
                          }
                        : {
                            background: "#f9f9f9",
                            fontSize: "12px",
                          }
                    }
                  >
                    <td
                      className="font_td_laporan_bahan_baku"
                      style={{ padding: "12px" }}
                    >
                      <select
                        defaultValue="Pilih No Mesin"
                        onChange={(e) =>
                          handleFormChangeGantiUtama(index, e, "")
                        }
                        className="input_select_memo_ppic"
                        style={{ width: "48px" }}
                        name="mesin"
                        value={input.mesin}
                      >
                        <option selected disabled>
                          Pilih Nomor Mesin
                        </option>
                        {constantNoMesin &&
                          constantNoMesin.map((data) => (
                            <option value={data}>{data}</option>
                          ))}
                      </select>
                    </td>
                    <td
                      className="font_td_laporan_bahan_baku"
                      style={{ padding: "12px" }}
                    >
                      <input
                        type="text"
                        onChange={(e) =>
                          handleFormChangeGantiUtama(index, e, "")
                        }
                        className="input_select_memo_ppic"
                        style={{ width: "36px", height: "30px" }}
                        name="urutan"
                        placeholder="Urutan"
                        value={input.urutan}
                      />
                    </td>
                    <td
                      className="font_td_laporan_bahan_baku"
                      style={{ padding: "12px" }}
                    >
                      <select
                        defaultValue="Pilih Customer"
                        onChange={(e) =>
                          handleFormChangeGantiUtama(index, e, "")
                        }
                        className="input_select_memo_ppic"
                        style={{ width: "80px" }}
                        value={
                          input.customer ? input.customer : "Pilih Customer"
                        }
                        name="customer"
                      >
                        {input.customer === "" ? (
                          <option selected={input.customer === ""} disabled>
                            Pilih Customer
                          </option>
                        ) : (
                          <option value={input.customer} disabled>
                            {input.customer}
                          </option>
                        )}
                        {listProduk &&
                          listProduk.map((data) => (
                            <option value={data.company}>{data.company}</option>
                          ))}
                      </select>
                    </td>
                    <td
                      className="font_td_laporan_bahan_baku"
                      style={{ padding: "12px 12px 12px 12px" }}
                    >
                      <div className="select_item_gbj">
                        <Select
                          placeholder={input.moldProduk}
                          options={input.listMoldProduk}
                          onChange={(e) =>
                            handleFormChangeGantiUtama(
                              index,
                              e,
                              "BAKU",
                              "moldType"
                            )
                          }
                        />
                      </div>
                    </td>
                    <td
                      className="font_td_laporan_bahan_baku"
                      style={{ padding: "18px" }}
                    >
                      {input.color}
                    </td>
                    <td
                      className="font_td_laporan_bahan_baku"
                      style={{ padding: "18px" }}
                    >
                      {input.material}
                    </td>
                    <td
                      className="font_td_laporan_bahan_baku"
                      style={{ padding: "12px" }}
                    >
                      <input
                        type="number"
                        onChange={(e) =>
                          handleFormChangeGantiUtama(index, e, "")
                        }
                        className="input_select_memo_ppic"
                        style={{ width: "48px", height: "30px" }}
                        name="totalBahan"
                        placeholder="Total"
                        value={input.totalBahan ? input.totalBahan : "Total"}
                        onWheel={(e) => e.target.blur()}
                      />
                      &nbsp; Pcs
                    </td>
                    <td>
                      <img
                        style={{ width: "24px", cursor: "pointer" }}
                        src={DeleteIcon}
                        alt="delete-icon"
                        onClick={() => handleDeleteMemoGantiMold(input)}
                      />
                    </td>
                  </tr>
                );
              })}
          </table>
          {/* <TableMemoGantiMold
            memoBahan={memoGantiUtama}
            handleFormChange={handleFormChangeGantiUtama}
            dataMemo={listProduk}
            jenisBahan="SATUAN"
            isUtama={true}
            handleDeleteMemoGantiMold={handleDeleteMemoGantiMold}
          /> */}
          <div>
            <button
              className="button_tambah_kurang"
              onClick={() => addFields("SATUAN", "UTAMA")}
            >
              Tambah
            </button>
            <button
              className="button_tambah_kurang"
              onClick={() => removeFields("SATUAN", "UTAMA")}
            >
              kurang
            </button>
          </div>
          <div>
            <button
              className="button_submit_memo"
              onClick={() => handleSubmitGantiMold()}
            >
              Kirim
            </button>
          </div>
        </>
      )}
    </div>
  );
};
export default NewMemoPPIC;
