import React from "react";
import "./TableHistoryPPIC.css";

const TableHistoryPPIC = ({ data, jenisBahan, satuan }) => {
  return (
    <div style={{ overflowX: "auto" }}>
      <table style={{ width: "100%" }}>
        <tr className="container_header_memo_ppic">
          <th
            style={{
              background: "#e8e9f8",
              padding: "12px 6px",
              width: "6%",
              width: "auto",
              fontSize: "14px",
            }}
          >
            Mc
          </th>
          <th
            style={{
              background: "#e8e9f8",
              padding: "12px 6px",
              width: "6%",
              width: "auto",
              fontSize: "14px",
            }}
          >
            Cust
          </th>
          <th
            style={{
              background: "#e8e9f8",
              padding: "12px 6px",
              width: "6%",
              width: "auto",
              fontSize: "14px",
            }}
          >
            Produk
          </th>
          <th
            style={{
              background: "#e8e9f8",
              padding: "12px 6px",
              width: "6%",
              width: "auto",
              fontSize: "14px",
            }}
          >
            Bahan
          </th>
          <th
            style={{
              background: "#e8e9f8",
              padding: "12px 6px",
              width: "6%",
              width: "auto",
              fontSize: "14px",
            }}
          >
            Color
          </th>
          <th
            style={{
              background: "#e8e9f8",
              padding: "12px 6px",
              width: "6%",
              width: "auto",
              fontSize: "14px",
            }}
          >
            Total
          </th>
        </tr>
        {data &&
          data.map((obj, index) => {
            return (
              <>
                {!obj.isProduksi ? (
                  <tr
                    className={
                      index % 2 === 0
                        ? "container_data_memo_ppic"
                        : "container_data_memo_ppic background_odd"
                    }
                    key={index}
                  >
                    <td style={{ fontSize: "14px" }}>
                      <p style={{ margin: "0 6px" }}>{obj.mesin}</p>
                    </td>
                    <td style={{ fontSize: "14px" }}>{obj.customer}</td>
                    <td style={{ fontSize: "14px" }}>{obj.moldType}</td>
                    <td style={{ fontSize: "14px" }}>
                      {Array.isArray(obj.material)
                        ? obj.material.map((el) => {
                            return (
                              <>
                                {el.material}
                                <br />
                                <br />
                              </>
                            );
                          })
                        : obj.material}
                    </td>
                    <td style={{ fontSize: "14px" }}>{obj.color}</td>
                    {jenisBahan === "SATUAN" ? (
                      <td style={{ fontSize: "14px" }}>
                        {obj.total}&nbsp; Pcs
                      </td>
                    ) : (
                      <td style={{ fontSize: "14px" }}>
                        {obj.totalBahan
                          ? Array.isArray(obj.material)
                            ? obj.material.map((el) => {
                                return (
                                  <>
                                    {el.perbandingan * obj.totalBahan} &nbsp;
                                    {`${jenisBahan === "BAKU" ? "Kg" : "Pcs"}`}
                                    <br />
                                    <br />
                                  </>
                                );
                              })
                            : `${obj.totalBahan} ${
                                jenisBahan === "BAKU" ? "Kg" : "Pcs"
                              }`
                          : Array.isArray(obj.material)
                          ? obj.material.map((el) => {
                              return (
                                <>
                                  {el.total} &nbsp;
                                  {`${jenisBahan === "BAKU" ? "Kg" : "Pcs"}`}
                                  <br />
                                  <br />
                                </>
                              );
                            })
                          : `${obj.total} ${
                              jenisBahan === "BAKU" ? "Kg" : "Pcs"
                            }`}
                      </td>
                    )}
                  </tr>
                ) : (
                  ""
                )}
              </>
            );
          })}
      </table>
    </div>
  );
};
export default TableHistoryPPIC;
