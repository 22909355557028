import React, { useEffect, useState } from "react";
import MenuComponent from "../components/Menu/MenuComponent";
import Inventory from "./Inventory/Inventory";
import StockIn from "./StockIn/StockIn";
import History from "./History/History";
import ListBahan from "./ListBahan/ListBahan";
import QualityControl from "./QC/QualityControl";
import ListProduk from "./Produk/ListProduk";
import Dashboard from "./Dashboard/Dashboard";
import Operator from "./Operator/Operator";
import Mold from "./Mold/Mold";
import ApprovalLaporan from "./ApprovalLaporan/ApprovalLaporan";
import PatrolQc from "./PatrolQc/PatrolQc.mjs";
import Security from "./Security/Security";
import PO from "./PO/PO";
import SecondProcess from "./SecondProcess/SecondProcess";
import { useHistory } from "react-router-dom";
import ListProdukSP from "./listProdukSP/listProdukSP";
import ApprovalSP from "./ApprovalSP/ApprovalSP";
import CheckSheet from "./CheckSheet/CheckSheet";
import NgSetting from "./NgSetting/NgSetting";
import Karantina from "./Karantina/Karantina";
import InputBarangJadi from "./InputBarangJadi/InputBarangJadi";
import BarcodeYBI from "./BarcodeYBI/BarcodeYBI";
import CheckSheetQc from "./CheckSheetQc/CheckSheetQc";
import NewMemoPPIC from "./MemoPPIC/NewMemoPPIC";
import LemburKaryawan from "./LemburKaryawan/LemburKaryawan";

const IndexPage = () => {
  let history = useHistory();
  const dataAuth = localStorage.getItem("auth");
  const dataUser = JSON.parse(dataAuth);
  const [menu, setMenu] = useState("Dashboard");

  useEffect(() => {
    if (dataUser === null) {
      history.push("/login");
    } else if (
      dataUser.role === "warehouse_admin" ||
      dataUser.role === "warehouse_mixing" ||
      dataUser.role === "warehouse_crusher"
    ) {
      setMenu("InputBahan");
    } else if (dataUser.role === "qc_user") {
      setMenu("QC");
    } else if (dataUser.role === "produksi_user") {
      setMenu("Operator");
    } else if (dataUser.role === "security") {
      setMenu("Security");
    } else if (dataUser.role === "warehouse_gbj_admin") {
      setMenu("InputBarangJadi");
    }
  }, []);

  return (
    <div>
      {dataUser !== null ? <MenuComponent setMenu={setMenu} menu={menu} /> : ""}
      {menu === "Dashboard" ? (
        <Dashboard />
      ) : menu === "CheckSheetQc" ? (
        <CheckSheetQc />
      ) : menu === "Inventory" ? (
        <Inventory />
      ) : menu === "InputBahan" ? (
        <StockIn />
      ) : menu === "History" ? (
        <History />
      ) : menu === "ListBahan" ? (
        <ListBahan />
      ) : menu === "QC" ? (
        <QualityControl />
      ) : menu === "Produk" ? (
        <ListProduk />
      ) : menu === "Operator" ? (
        <Operator />
      ) : menu === "ApprovalLaporan" ? (
        <ApprovalLaporan />
      ) : menu === "Mold" ? (
        <Mold />
      ) : menu === "Patrol" ? (
        <PatrolQc />
      ) : menu === "PO" ? (
        <PO />
      ) : menu === "SP" ? (
        <SecondProcess />
      ) : menu === "listProdukSP" ? (
        <ListProdukSP />
      ) : menu === "ApprovalLaporanSP" ? (
        <ApprovalSP />
      ) : menu === "checkSheet" ? (
        <CheckSheet />
      ) : menu === "NgSetting" ? (
        <NgSetting />
      ) : menu === "Security" ? (
        <Security />
      ) : menu === "Karantina" ? (
        <Karantina />
      ) : menu === "InputBarangJadi" ? (
        <InputBarangJadi />
      ) : menu === "BarcodeYBI" ? (
        <BarcodeYBI />
      ) : menu === "NewMemoPPIC" ? (
        <NewMemoPPIC />
      ) : menu === "LemburKaryawan" ? (
        <LemburKaryawan />
      ) : (
        ""
      )}
    </div>
  );
};
export default IndexPage;
