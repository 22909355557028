import React from "react";
import { bulan, tahun } from "../../util/constant";
import "./TableQualityControl.css";
const TableQualityControl = ({
  dataQc,
  shiftQc,
  setShiftQc,
  dateQc,
  setDateQc,
  dataTopPlate,
  exportBerat,
  bulanExport,
  setBulanExport,
  tahunExport,
  setTahunExport,
}) => {
  return (
    <div className="container_qc" style={{ margin: "0" }}>
      <div className="wrapper_table_memo_ppic">
        <div style={{ marginBottom: "12px", display: "flex" }}>
          <div className="margin_right_27_qc">
            <p>Pilih Shift</p>
            <select
              className="input_select_shift"
              value={shiftQc}
              onChange={(e) => setShiftQc(e.target.value)}
            >
              <option selected={shiftQc === "Pilih Shift"} disabled>
                Pilih Shift
              </option>
              <option value="1">Shift 1</option>
              <option value="2">Shift 2</option>
              <option value="3">Shift 3</option>
            </select>
          </div>
          <div className="margin_right_27_qc">
            <p>Pilih Tanggal</p>
            <input
              type="date"
              className="date_pick_memo_ppic"
              value={dateQc}
              onChange={(e) => setDateQc(e.target.value)}
            />
          </div>
          <div className="margin_right_27_qc">
            <p>Bulan</p>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <select
                defaultValue="Bulan"
                onChange={(e) => setBulanExport(e.target.value)}
                className="input_select_memo_ppic"
                style={{ width: "100%" }}
                value={bulanExport}
              >
                <option selected disabled>
                  Bulan
                </option>
                {bulan.map((ele) => (
                  <option value={ele.value}>{ele.name}</option>
                ))}
              </select>
            </div>
          </div>
          <div className="margin_right_27_qc" style={{ marginBottom: "12px" }}>
            <p>Pilih Tahun</p>
            <select
              className="input_select_memo_ppic"
              value={tahunExport}
              style={{ width: "100%" }}
              onChange={(e) => setTahunExport(e.target.value)}
            >
              <option disabled>Pilih Bulan</option>
              {tahun.map((data) => (
                <option value={data}>{data}</option>
              ))}
            </select>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              padding: "28px 0 0 0",
            }}
          >
            <button
              style={{
                height: "32px",
                width: "96px",
                border: "none",
                background: "green",
                color: "white",
                borderRadius: "4px",
                cursor: "pointer",
              }}
              onClick={() => exportBerat()}
            >
              Export Berat
            </button>
          </div>
        </div>
        <>
          <table style={{ width: "100%" }}>
            <tr>
              <th className="table_header_memo_ppic width_number_mesin_qc">
                Mesin
              </th>
              <th className="table_header_memo_ppic customer_width width_number_mesin_qc">
                Customer
              </th>
              <th className="table_header_memo_ppic min_width_qc_27">Mold</th>
              <th className="table_header_memo_ppic min_width_qc_27">Bahan</th>
              <th className="table_header_memo_ppic color_width none">Color</th>
              <th className="table_header_memo_ppic jumlah_width_qc width_number_mesin_qc">
                Berat (gr)
              </th>
            </tr>
            {dataQc &&
              dataQc.map((data, index) => (
                <tr
                  className={
                    index % 2 === 0
                      ? "container_data_memo_ppic"
                      : "container_data_memo_ppic background_odd"
                  }
                  key={index}
                >
                  <td className="width_number_mesin_qc">
                    <p style={{ margin: "0 6px" }}>{data.mesin}</p>
                  </td>
                  <td className="min_width customer_width width_number_mesin_qc">
                    {data.customer}
                  </td>
                  <td className="min_width column_qc_width">{data.moldType}</td>
                  <td className="min_width column_qc_width">{data.material}</td>
                  <td className="min_width color_width none">{data.color}</td>
                  <td className="jumlah_width_qc width_number_mesin_qc">
                    {data.beratQc ? data.beratQc : "0"} &nbsp; gr
                  </td>
                </tr>
              ))}
          </table>
        </>

        {dataTopPlate && dataTopPlate ? (
          <>
            <p className="title_inventory">Data Top Plate</p>
            <table style={{ width: "100%" }}>
              <tr className="container_header_memo_ppic">
                <th className="table_header_memo_ppic width_number_mesin_qc">
                  Mesin
                </th>
                <th className="table_header_memo_ppic customer_width width_number_mesin_qc">
                  Customer
                </th>
                <th className="table_header_memo_ppic min_width_qc_27">Mold</th>
                <th className="table_header_memo_ppic min_width_qc_27">
                  Bahan
                </th>
                <th className="table_header_memo_ppic jumlah_width_qc width_number_mesin_qc">
                  Berat (gr)
                </th>
              </tr>
              {dataTopPlate &&
                dataTopPlate.map((data, index) => (
                  <tr
                    className={
                      index % 2 === 0
                        ? "container_data_memo_ppic"
                        : "container_data_memo_ppic background_odd"
                    }
                    key={index}
                  >
                    <td className="width_number_mesin_qc">
                      <p style={{ margin: "0 6px" }}>{data.mesin}</p>
                    </td>
                    <td className="min_width customer_width width_number_mesin_qc">
                      {data.customer}
                    </td>
                    <td className="min_width column_qc_width">
                      {data.moldType}
                    </td>
                    <td className="min_width column_qc_width">
                      {data.material}
                    </td>
                    <td className="jumlah_width_qc width_number_mesin_qc">
                      {data.berat ? data.berat : "0"} &nbsp; gr
                    </td>
                  </tr>
                ))}
            </table>
          </>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};
export default TableQualityControl;
