import React, { useState } from "react";
import LogoTim from "../../Assets/TimLogo.png";
import { useLogin } from "./useLogin";
import PopUpComponent from "../../components/PopUp/PopUpComponent";
import CloseLogo from "../../Assets/close_icon.png";
import "./Login.css";

const Login = () => {
  const {
    username,
    setUsername,
    password,
    setPassword,
    handleLogin,
    popUpLogin,
    setPopUpLogin,
    popUpChangePassword,
    setPopUpChangePassword,
    ktpNumber,
    setKtpNumber,
    newPassword,
    setNewPassword,
    oldPassword,
    setOldPassword,
    handleKirimGantiPassword,
    handleSaveCatatan,
    setCatatan,
    popUpCatatan,
    setPopUpCatatan,
    catatan,
  } = useLogin();

  return (
    <div className="container_login" style={{ flexDirection: "column" }}>
      {popUpCatatan ? (
        <PopUpComponent>
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <img
              style={{ width: "32px", cursor: "pointer" }}
              src={CloseLogo}
              alt="cancel"
              onClick={() => setPopUpCatatan(false)}
            />
          </div>
          <div>
            <p style={{ color: "red", fontSize: "12px", lineHeight: "18px" }}>
              <b>
                Ini adalah catatan sementara, mohon di copy ke laporan operator
                jika ingin menulis laporan!
              </b>
            </p>
            <textarea
              style={{
                width: "98%",
                resize: "none",
                height: "84px",
                fontFamily: "arial",
              }}
              value={catatan}
              onChange={(e) => setCatatan(e.target.value)}
            />
            <br />
            <br />
            <div>
              <button
                className="button_submit_memo"
                style={{ margin: "0" }}
                onClick={() => handleSaveCatatan()}
              >
                Simpan
              </button>
              <button
                className="button_submit_memo"
                style={{
                  margin: "0 12px 0 0",
                  color: "black",
                  border: "1px solid black",
                  background: "white",
                }}
                onClick={() => navigator.clipboard.writeText(catatan)}
              >
                Copy
              </button>
              <button
                className="button_submit_memo"
                style={{
                  margin: "0 12px 0 0",
                  color: "black",
                  border: "1px solid black",
                  background: "yellow",
                }}
                onClick={() => setCatatan("")}
              >
                Hapus
              </button>
            </div>
          </div>
        </PopUpComponent>
      ) : (
        ""
      )}
      {popUpChangePassword ? (
        <PopUpComponent>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <p>Ganti Password</p>
            <img
              className="width_edit_logo"
              src={CloseLogo}
              onClick={() => setPopUpChangePassword(false)}
              alt="edit_logo"
            />
          </div>
          <p>No.KTP (Cth: 3172010512370001)</p>
          <div className="wrapper_radio">
            <input
              className="input_jumlah"
              type="number"
              placeholder="No.KTP"
              value={ktpNumber}
              onChange={(e) => setKtpNumber(e.target.value)}
              onWheel={(e) => e.target.blur()}
            />
          </div>

          <div
            className="wrapper_radio"
            style={{ justifyContent: "space-between", paddingRight: "12px" }}
          >
            <div style={{ width: "43%" }}>
              <p>Password Lama</p>
              <input
                className="input_dashboard_operator"
                style={{ width: "100%", height: "32px" }}
                type="text"
                placeholder="Password Lama"
                value={oldPassword}
                onChange={(e) => setOldPassword(e.target.value)}
                onWheel={(e) => e.target.blur()}
              />
            </div>
            <div style={{ width: "43%" }}>
              <p>Password Baru</p>
              <input
                className="input_dashboard_operator"
                style={{ width: "100%", height: "32px" }}
                type="text"
                placeholder="Password Baru"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
                onWheel={(e) => e.target.blur()}
              />
            </div>
          </div>
          <div className="wrapper_button_submit_popup">
            <button
              className="button_popup background_red"
              onClick={() => handleKirimGantiPassword()}
            >
              Kirim
            </button>
          </div>
        </PopUpComponent>
      ) : (
        ""
      )}
      {popUpLogin ? (
        <PopUpComponent>
          <p>Username atau Password salah!</p>
          <div className="wrapper_button_submit_popup">
            <button
              className="button_popup background_red"
              onClick={() => setPopUpLogin(false)}
            >
              OK
            </button>
          </div>
        </PopUpComponent>
      ) : (
        ""
      )}
      <div className="login_card">
        <p className="title_login">Login</p>
        <img className="logo_tim" src={LogoTim} alt="Logo-TIM" />
        <input
          className="input_login"
          type="text"
          placeholder="Username"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              handleLogin();
            }
          }}
        />
        <input
          className="input_login"
          type="password"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              handleLogin();
            }
          }}
        />
        <button className="button_login" onClick={() => handleLogin()}>
          Masuk
        </button>
      </div>
      <p
        style={{ color: "red", cursor: "pointer" }}
        onClick={() => setPopUpCatatan(true)}
      >
        Catatan
      </p>
    </div>
  );
};
export default Login;
