import { useEffect, useState } from "react";
import { postAxios } from "../../util/apiCall";

export const useListProduk = () => {
  const dataAuth = localStorage.getItem("auth");
  const dataUser = JSON.parse(dataAuth);

  const [companyPickList, setCompanyPickList] = useState("GS");
  const [dataListProduk, setDataListProduk] = useState();
  const [dataInventory, setDataInventory] = useState();
  const [namaProduk, setNamaProduk] = useState("");
  const [addNewCompany, setAddNewCompany] = useState("");
  const [editRunner, setEditRunner] = useState("");
  const [runner, setRunner] = useState("");
  const [total, setTotal] = useState("");
  const [color, setColor] = useState("");
  const [bahan, setBahan] = useState("");
  const [material, setMaterial] = useState("");
  const [warnaMaterial, setWarnaMaterial] = useState("");
  const [tipeMaterial, setTipeMaterial] = useState("");
  const [popUp, setPopUp] = useState(false);
  const [popUpDeleteProduk, setPopUpDeleteProduk] = useState(false);
  const [popUpEditProduk, setPopUpEditProduk] = useState(false);
  const [isApiRefresh, setIsApiRefresh] = useState(false);
  const [dataListDelete, setDataListDelete] = useState();

  useEffect(() => {
    postAxios(
      `${process.env.REACT_APP_ENDPOINT}/getListProdukMerge`,
      { tim: dataUser.tim },
      dataUser.auth,
      setDataListProduk,
      ""
    );
    setIsApiRefresh(false);
  }, [isApiRefresh]);

  useEffect(() => {
    postAxios(
      `${process.env.REACT_APP_ENDPOINT}/getInventory`,
      { tim: dataUser.tim, type: "all" },
      dataUser.auth,
      setDataInventory,
      ""
    );
  }, []);

  const dataPick = () => {
    let data;
    dataListProduk &&
      dataListProduk.forEach((element) => {
        if (element.company === companyPickList) {
          data = element;
        }
      });
    return data;
  };
  const ProdukList = dataPick();

  const handleCancelAddBahan = () => {
    setPopUp(false);
    setNamaProduk("");
    setColor("");
    setMaterial("");
    setRunner("");
    setBahan("");
    setTotal("");
    setAddNewCompany("");
  };

  const handleKirim = async () => {
    await postAxios(
      `${process.env.REACT_APP_ENDPOINT}/addNewProduct`,
      {
        namaProduk,
        color: color,
        material: material,
        runner: Number(runner),
        bahan: bahan,
        total: Number(total),
        company: addNewCompany,
        tim: dataUser.tim,
      },
      dataUser.auth,
      "",
      ""
    );

    setIsApiRefresh(true);
    handleCancelAddBahan();
  };

  const handleDeleteProduk = async () => {
    const merge = {
      company: companyPickList,
      type: "delete",
      tim: dataUser.tim,
      ...dataListDelete,
    };

    await postAxios(
      `${process.env.REACT_APP_ENDPOINT}/deleteProduk`,
      merge,
      dataUser.auth,
      "",
      ""
    );
    setIsApiRefresh(true);
    setPopUpDeleteProduk(false);
  };

  const handleEditProduk = async () => {
    const merge = {
      company: companyPickList,
      type: "edit",
      tim: dataUser.tim,
      runnerEdit: Number(editRunner),
      ...dataListDelete,
    };

    await postAxios(
      `${process.env.REACT_APP_ENDPOINT}/deleteProduk`,
      merge,
      dataUser.auth,
      "",
      ""
    );
    setEditRunner("");
    setIsApiRefresh(true);
    setPopUpEditProduk(false);
  };

  const validateMaterial = () => {
    let data = [];
    if (bahan === "BAKU") {
      dataInventory &&
        dataInventory.forEach((obj) => {
          if (obj.company === addNewCompany) {
            data = obj.bahanBaku;
          }
        });
    } else if (bahan === "PENDUKUNG") {
      dataInventory &&
        dataInventory.forEach((obj) => {
          if (obj.company === addNewCompany) {
            data = obj.bahanPendukung;
          }
        });
    }
    return data;
  };

  const dataMaterial = validateMaterial();

  return {
    ProdukList,
    popUp,
    setPopUp,
    companyPickList,
    setCompanyPickList,
    handleCancelAddBahan,
    dataListProduk,
    handleDeleteProduk,
    setDataListDelete,
    popUpDeleteProduk,
    setPopUpDeleteProduk,
    setNamaProduk,
    setColor,
    setMaterial,
    setRunner,
    setBahan,
    setTotal,
    setAddNewCompany,
    handleKirim,
    dataMaterial,
    setWarnaMaterial,
    setTipeMaterial,
    bahan,
    popUpEditProduk,
    setPopUpEditProduk,
    handleEditProduk,
    editRunner,
    setEditRunner,
  };
};
