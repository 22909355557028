import { useEffect, useState } from "react";
import { getDateUtil, yesterday, today, noFormat } from "../../util/formatDate";
import { postAxios } from "../../util/apiCall";
import { v4 as uuidv4 } from "uuid";

export const useOperator = () => {
  const dataAuth = localStorage.getItem("auth");
  const dataUser = JSON.parse(dataAuth);

  const [dataReject, setDataReject] = useState([
    { id: "Pilih Alasan", totalNG: "" },
  ]);
  const [dateOperator, setDateOperator] = useState(today);
  const [shiftOperator, setShiftOperator] = useState("Pilih Shift");
  const [mesinOperator, setMesinOperator] = useState("Pilih Mesin");
  const [rejectDesc, setRejectDesc] = useState();
  const [isiPerKemasan, setIsiPerKemasan] = useState();
  const [aktualCavity, setAktualCavity] = useState();
  const [counterAkhir, setCounterAkhir] = useState();
  const [counterAwal, setCounterAwal] = useState();
  const [jumlahNG, setJumlahNG] = useState(0);
  const [jumlahBarang, setJumlahBarang] = useState();
  const [downtime, setDowntime] = useState();
  const [keterangan, setKeterangan] = useState("");
  const [isError, setIsError] = useState(false);
  const [popUpKirim, setPopUpKirim] = useState(false);
  const [listTopPlate, setListTopPlate] = useState();
  const [subMenuOperator, setSubMenuOperator] = useState("laporanProduksi");
  const [isToco, setIsToco] = useState(false);
  const [sisaShiftSebelumnya, setSisaShiftSebelumnya] = useState();
  const [produkVP, setProdukVP] = useState("Pilih Produk");
  const [shiftPasangKaret, setShiftPasangKaret] = useState("Pilih Shift");
  const [mesinPasangKaret, setMesinPasangKaret] = useState("Pilih Mesin");
  const [keteranganPasangKaret, setKeteranganPasangKaret] = useState("");
  const [jumlahPasangKaret, setJumlahPasangKaret] = useState("");
  const [isErrorPasangKaret, setIsErrorPasangKaret] = useState(false);
  const [downtimePasangKaret, setDowntimePasangKaret] = useState("");
  const [kepalaShift, setKepalaShift] = useState("Pilih Kepala Shift");
  const [warnaVP, setWarnaVP] = useState("Pilih Warna");
  const [aktualCavityPasangKaret, setAktualCavityPasangKaret] = useState("");
  const [customerVentPlug, setCustomerVentPlug] = useState("Pilih Customer");
  const [keteranganSementara, setKeteranganSementara] = useState("");

  const [aktualBarang, setAktualBarang] = useState();

  const v4Id = uuidv4();

  useEffect(() => {
    postAxios(
      `${process.env.REACT_APP_ENDPOINT}/getDataTopPlate`,
      { tim: dataUser.tim, type: "editData" },
      dataUser.auth,
      setListTopPlate,
      ""
    );
    postAxios(
      `${process.env.REACT_APP_ENDPOINT}/getListPerincian`,
      {},
      dataUser.auth,
      setRejectDesc,
      ""
    );
    postAxios(
      `${process.env.REACT_APP_ENDPOINT}/createNotes`,
      {
        tim: dataUser.tim,
        id: dataUser.id,
        keteranganSementara: keteranganSementara,
      },
      dataUser.auth,
      setKeteranganSementara,
      ""
    );
  }, []);

  const handleRejectDesc = (index, event, type) => {
    let data = [...dataReject];
    if (type === "kindOfReject") {
      data[index].id = event.label;
    } else {
      data[index][event.target.name] = Number(event.target.value);
    }

    setDataReject(data);
  };

  const handleJumlahTopPlate = (index, event) => {
    let data = [...listTopPlate];

    data[index][event.target.name] = Number(event.target.value);
    setListTopPlate(data);
  };

  const addFields = () => {
    let newfield = {
      id: "Pilih Alasan",
      totalNG: "",
    };
    setDataReject([...dataReject, newfield]);
  };

  const removeFields = () => {
    let data = [...dataReject];
    if (data.length > 1) {
      data.splice(data.length - 1);
      setDataReject(data);
    }
  };

  const handleKirim = () => {
    let jumlahReject = 0;

    dataReject &&
      dataReject.forEach((element) => {
        jumlahReject += Number(element.totalNG);
      });
    const reStructure = {
      id: v4Id,
      tim: dataUser.tim,
      shift: shiftOperator,
      mesin: mesinOperator,
      date: getDateUtil(dateOperator),
      yesterday: getDateUtil(yesterday),
      isiPerKemasan: Number(isiPerKemasan),
      aktualCavity: Number(aktualCavity),
      perincianNG: dataReject,
      counterAkhir: Number(counterAkhir),
      counterAwal: Number(counterAwal),
      jumlahNG: jumlahNG ? Number(jumlahNG) : 0,
      sisaShiftSebelumnya: Number(sisaShiftSebelumnya),
      jumlahBarang: Number(jumlahBarang),
      dateSubmit: noFormat,
      downtime: Number(downtime),
      keterangan: keterangan,
      operator: dataUser.name,
      isKepala: false,
      isAdmin: false,
      isWarehouse: false,
      isToco: isToco,
      aktualBarang: Number(aktualBarang),
    };

    if (
      shiftOperator !== "Pilih Shift" &&
      mesinOperator !== "Pilih Mesin" &&
      isiPerKemasan !== "" &&
      aktualCavity !== "" &&
      counterAkhir !== "" &&
      counterAwal !== "" &&
      jumlahBarang !== "" &&
      jumlahReject === Number(jumlahNG)
    ) {
      postAxios(
        `${process.env.REACT_APP_ENDPOINT}/laporanOperator`,
        { data: reStructure },
        dataUser.auth,
        "",
        ""
      );
      setIsError(false);
      setPopUpKirim(true);
      window.scrollTo({ top: 0, behavior: "smooth" });
      setShiftOperator("Pilih Shift");
      setMesinOperator("Pilih Mesin");
      setWarnaVP("Pilih Warna");
      setDataReject([{ id: "Pilih Alasan", totalNG: "" }]);
      setIsToco(false);

      setSisaShiftSebelumnya(0);
      setAktualBarang(0);
      setIsiPerKemasan(0);
      setAktualCavity(0);
      setCounterAkhir(0);
      setCounterAwal(0);
      setJumlahNG(0);
      setJumlahBarang(0);
      setDowntime(0);
      setKeterangan("");
    } else {
      setIsError(true);
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  };

  const handleKirimTopPlate = () => {
    postAxios(
      `${process.env.REACT_APP_ENDPOINT}/submitJumlahTopPlate`,
      {
        tim: dataUser.tim,
        data: listTopPlate,
      },
      dataUser.auth,
      "",
      ""
    );
    setSubMenuOperator("laporanProduksi");
    setPopUpKirim(true);
  };

  const handleKirimPasangKaret = () => {
    if (
      produkVP !== "Pilih Produk" &&
      shiftPasangKaret !== "Pilih Shift" &&
      mesinPasangKaret !== "Pilih Mesin" &&
      jumlahPasangKaret !== "" &&
      kepalaShift !== "Pilih Kepala Shift" &&
      warnaVP !== "Pilih Warna" &&
      customerVentPlug !== "Pilih Customer"
    ) {
      postAxios(
        `${process.env.REACT_APP_ENDPOINT}/submitPasangKaret`,
        {
          data: {
            id: v4Id,
            tim: dataUser.tim,
            date: getDateUtil(dateOperator),
            produk: produkVP,
            warna: warnaVP,
            shift: shiftPasangKaret,
            mesin: mesinPasangKaret,
            jumlah: Number(jumlahPasangKaret),
            operator: dataUser.name,
            keterangan: keteranganPasangKaret,
            downtime: Number(downtimePasangKaret),
            aktualCavity: Number(aktualCavityPasangKaret),
            customer: customerVentPlug,
            isKepala: false,
            isAdmin: false,
            kepalaShift: kepalaShift,
          },
        },
        dataUser.auth,
        setListTopPlate,
        ""
      );

      setProdukVP("Pilih Produk");
      setShiftPasangKaret("Pilih Shift");
      setMesinPasangKaret("Pilih Mesin");
      setWarnaVP("Pilih Warna");
      setDowntimePasangKaret("");
      setKeteranganPasangKaret("");
      setJumlahPasangKaret("");
      setAktualCavityPasangKaret("");
      setKepalaShift("Pilih Kepala Shift");
      setCustomerVentPlug("Pilih Customer");
      setIsErrorPasangKaret(false);
      setPopUpKirim(true);
    } else {
      setIsErrorPasangKaret(true);
    }
  };

  const handleSaveKeterangan = () => {
    postAxios(
      `${process.env.REACT_APP_ENDPOINT}/createNotes`,
      {
        tim: dataUser.tim,
        id: dataUser.id,
        keteranganSementara: keteranganSementara,
        type: "create",
      },
      dataUser.auth,
      setKeteranganSementara,
      ""
    );
  };

  let listReject = [];

  rejectDesc &&
    rejectDesc.forEach((element) => {
      listReject.push({ value: element.id, label: element.desc });
    });

  return {
    dataUser,
    dataReject,
    handleRejectDesc,
    addFields,
    removeFields,
    shiftOperator,
    setShiftOperator,
    setIsiPerKemasan,
    setAktualCavity,
    setCounterAkhir,
    setCounterAwal,
    setJumlahNG,
    setJumlahBarang,
    setKeterangan,
    setMesinOperator,
    setDateOperator,
    handleKirim,
    mesinOperator,
    isiPerKemasan,
    aktualCavity,
    counterAkhir,
    counterAwal,
    jumlahNG,
    jumlahBarang,
    keterangan,
    isError,
    rejectDesc,
    popUpKirim,
    setDowntime,
    downtime,
    setPopUpKirim,
    subMenuOperator,
    setSubMenuOperator,
    listTopPlate,
    handleJumlahTopPlate,
    handleKirimTopPlate,
    dateOperator,
    isToco,
    setIsToco,
    sisaShiftSebelumnya,
    setSisaShiftSebelumnya,
    produkVP,
    setProdukVP,
    shiftPasangKaret,
    setShiftPasangKaret,
    mesinPasangKaret,
    setMesinPasangKaret,
    keteranganPasangKaret,
    setKeteranganPasangKaret,
    handleKirimPasangKaret,
    jumlahPasangKaret,
    setJumlahPasangKaret,
    isErrorPasangKaret,
    setIsErrorPasangKaret,
    setDowntimePasangKaret,
    downtimePasangKaret,
    kepalaShift,
    setKepalaShift,
    warnaVP,
    setWarnaVP,
    aktualCavityPasangKaret,
    setAktualCavityPasangKaret,
    setCustomerVentPlug,
    customerVentPlug,
    keteranganSementara,
    setKeteranganSementara,
    handleSaveKeterangan,
    listReject,
    aktualBarang,
    setAktualBarang,
  };
};
