import React from "react";
import { useListProdukSP } from "./useListProdukSP";
import CardComponent from "../../components/Card/CardComponent";
import LogoGs from "../../Assets/GS-Logo.png";
import LogoYBI from "../../Assets/YBI-Logo.jpeg";
import LogoTBP from "../../Assets/TBP-Logo.jpeg";
import LogoMelco from "../../Assets/MELCO-Logo.png";
import LogoTIM from "../../Assets/TimLogo.png";
import LogoCBI from "../../Assets/CBI-Logo.png";
import EditLogo from "../../Assets/editLogo.png";
import LogoMitra from "../../Assets/Mitra_logo.png";
import CloseLogo from "../../Assets/close_icon.png";
import PopUpComponent from "../../components/PopUp/PopUpComponent";
import { constCustomer } from "../../util/constant";
import { validateRole } from "../../util/utilFunction";
import "./listProdukSP.css";

const ListProdukSP = () => {
  const {
    dataUser,
    customer,
    setCustomer,
    Material,
    handleAddProduk,
    namaProduk,
    setNamaProduk,
    targetPrinting,
    setTargetPrinting,
    targetPunching,
    setTargetPunching,
    targetSticker,
    setTargetSticker,
    targetUltrasonic,
    setTargetUltrasonic,
    targetTestBocor,
    setTargetTestBocor,
    targetFillingPlug,
    setTargetFillingPlug,
    addNewCustomer,
    setAddNewCustomer,
    popUpTambahProduk,
    setPopUpTambahProduk,
    popUpEdit,
    setPopUpEdit,
    handlePickEdit,
    pickProduk,
    handleChangeEditProduk,
    handleKirimEditProduk,
    targetSortir,
    setTargetSortir,
    targetPutusRonce,
    setTargetPutusRonce,
    targetBnw,
    setTargetBnw,
    targetPasangHandle,
    setTargetPasangHandle,
    targetValveFilter,
    setTargetValveFilter,
  } = useListProdukSP();

  const sortListProduk =
    Material &&
    Material.data.sort(function (a, b) {
      if (a.produk < b.produk) {
        return -1;
      }
      if (a.produk > b.produk) {
        return 1;
      }
      return 0;
    });

  return (
    <div className="container_memo_ppic">
      {popUpEdit ? (
        <PopUpComponent
          style={{
            marginTop: "calc(116vh - 100vh - 10px)",
            overflowY: "scroll",
            height: "70vh",
          }}
        >
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <p>
              <b>{pickProduk.produk}</b>
            </p>
            <img
              className="width_edit_logo"
              src={CloseLogo}
              onClick={() => setPopUpEdit(false)}
              alt="edit_logo"
            />
          </div>
          {validateRole() === "PR" ? (
            <div style={{ display: "flex", flexWrap: "wrap" }}>
              <div className="wrapper_input">
                <p className="font_weight_bold">
                  Target Printing<label style={{ color: "red" }}>*</label>
                </p>
                <input
                  type="number"
                  className="input_jumlah"
                  placeholder="Target Printing"
                  name="targetPrint"
                  value={pickProduk.targetPrint}
                  onChange={(e) => handleChangeEditProduk(e)}
                />
              </div>
              <div className="wrapper_input">
                <p className="font_weight_bold">
                  Target Punching<label style={{ color: "red" }}>*</label>
                </p>
                <input
                  type="number"
                  className="input_jumlah"
                  placeholder="Target Punching"
                  name="targetPunching"
                  value={pickProduk.targetPunching}
                  onChange={(e) => handleChangeEditProduk(e)}
                />
              </div>
              <div className="wrapper_input">
                <p className="font_weight_bold">
                  Target Pasang Sticker
                  <label style={{ color: "red" }}>*</label>
                </p>
                <input
                  type="number"
                  className="input_jumlah"
                  placeholder="Target Pasang Sticker"
                  name="targetPasangStiker"
                  value={pickProduk.targetPasangStiker}
                  onChange={(e) => handleChangeEditProduk(e)}
                />
              </div>
            </div>
          ) : validateRole() === "SP" ? (
            <div style={{ display: "flex", flexWrap: "wrap" }}>
              <div className="wrapper_input">
                <p className="font_weight_bold">
                  Ultrasonic<label style={{ color: "red" }}>*</label>
                </p>
                <input
                  type="number"
                  className="input_jumlah"
                  placeholder="Target Ultrasonic"
                  name="targetUltrasonic"
                  value={pickProduk.targetUltrasonic}
                  onChange={(e) => handleChangeEditProduk(e)}
                />
              </div>
              <div className="wrapper_input">
                <p className="font_weight_bold">
                  Test Bocor<label style={{ color: "red" }}>*</label>
                </p>
                <input
                  type="number"
                  className="input_jumlah"
                  placeholder="Target Test Bocor"
                  name="targetTestBocor"
                  value={pickProduk.targetTestBocor}
                  onChange={(e) => handleChangeEditProduk(e)}
                />
              </div>
              <div className="wrapper_input">
                <p className="font_weight_bold">
                  Filling Plug
                  <label style={{ color: "red" }}>*</label>
                </p>
                <input
                  type="number"
                  className="input_jumlah"
                  placeholder="Target Filling Plug"
                  name="targetFilling"
                  value={pickProduk.targetFilling}
                  onChange={(e) => handleChangeEditProduk(e)}
                />
              </div>
              <div className="wrapper_input">
                <p className="font_weight_bold">
                  Sortir
                  <label style={{ color: "red" }}>*</label>
                </p>
                <input
                  type="number"
                  className="input_jumlah"
                  placeholder="Target Sortir"
                  name="targetSortir"
                  value={pickProduk.targetSortir}
                  onChange={(e) => handleChangeEditProduk(e)}
                />
              </div>
              <div className="wrapper_input">
                <p className="font_weight_bold">
                  Putus Ronce
                  <label style={{ color: "red" }}>*</label>
                </p>
                <input
                  type="number"
                  className="input_jumlah"
                  placeholder="Target Putus Ronce"
                  name="targetPutusRonce"
                  value={pickProduk.targetPutusRonce}
                  onChange={(e) => handleChangeEditProduk(e)}
                />
              </div>
              <div className="wrapper_input">
                <p className="font_weight_bold">
                  Pemasangan BNW
                  <label style={{ color: "red" }}>*</label>
                </p>
                <input
                  type="number"
                  className="input_jumlah"
                  placeholder="Target Pemasangan BNW"
                  name="targetBnw"
                  value={pickProduk.targetBnw}
                  onChange={(e) => handleChangeEditProduk(e)}
                />
              </div>
              <div className="wrapper_input">
                <p className="font_weight_bold">
                  Pemasangan Valve&Filter
                  <label style={{ color: "red" }}>*</label>
                </p>
                <input
                  type="number"
                  className="input_jumlah"
                  placeholder="Target Pemasangan Valve&Filter"
                  name="targetValveFilter"
                  value={pickProduk.targetValveFilter}
                  onChange={(e) => handleChangeEditProduk(e)}
                />
              </div>
              <div className="wrapper_input">
                <p className="font_weight_bold">
                  Pemasangan Handle
                  <label style={{ color: "red" }}>*</label>
                </p>
                <input
                  type="number"
                  className="input_jumlah"
                  placeholder="Target Pemasangan Handle"
                  name="targetPasangHandle"
                  value={pickProduk.targetPasangHandle}
                  onChange={(e) => handleChangeEditProduk(e)}
                />
              </div>
            </div>
          ) : (
            ""
          )}
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <button
              className="button_popup background_red"
              onClick={() => handleKirimEditProduk()}
            >
              Kirim
            </button>
          </div>
        </PopUpComponent>
      ) : (
        ""
      )}
      {popUpTambahProduk ? (
        <PopUpComponent
          style={{
            overflowY: "scroll",
            marginTop: "calc(110vh - 100vh - 20px)",
            height: "80vh",
          }}
        >
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <img
              className="width_edit_logo"
              src={CloseLogo}
              onClick={() => setPopUpTambahProduk(false)}
              alt="edit_logo"
            />
          </div>
          <div className="wrapper_content_popup">
            <div className="wrapper_input">
              <p className="font_weight_bold">
                Perusahaan<label style={{ color: "red" }}>*</label>
              </p>
              <select
                defaultValue="Pilih Nama Perusahaan"
                value={addNewCustomer}
                onChange={(e) => setAddNewCustomer(e.target.value)}
                className="input_select"
                style={{ width: "192px" }}
              >
                <option
                  selected={addNewCustomer === "Pilih Perusahaan"}
                  disabled
                >
                  Pilih Perusahaan
                </option>
                {constCustomer &&
                  constCustomer.map((data) => (
                    <option value={data}>{data}</option>
                  ))}
              </select>
            </div>
            <div className="wrapper_input">
              <p className="font_weight_bold">
                Nama Produk<label style={{ color: "red" }}>*</label>
              </p>
              <input
                type="text"
                className="input_jumlah"
                placeholder="Masukkan Nama Produk"
                value={namaProduk}
                onChange={(e) => setNamaProduk(e.target.value)}
              />
            </div>

            {validateRole() === "PR" ? (
              <>
                <div className="wrapper_input">
                  <p className="font_weight_bold">
                    Target Printing<label style={{ color: "red" }}>*</label>
                  </p>
                  <input
                    type="number"
                    className="input_jumlah"
                    placeholder="Target Printing"
                    value={targetPrinting}
                    onChange={(e) => setTargetPrinting(e.target.value)}
                  />
                </div>
                <div className="wrapper_input">
                  <p className="font_weight_bold">
                    Target Punching<label style={{ color: "red" }}>*</label>
                  </p>
                  <input
                    type="number"
                    className="input_jumlah"
                    placeholder="Target Punching"
                    value={targetPunching}
                    onChange={(e) => setTargetPunching(e.target.value)}
                  />
                </div>
                <div className="wrapper_input">
                  <p className="font_weight_bold">
                    Target Pasang Sticker
                    <label style={{ color: "red" }}>*</label>
                  </p>
                  <input
                    type="number"
                    className="input_jumlah"
                    placeholder="Target Pasang Sticker"
                    value={targetSticker}
                    onChange={(e) => setTargetSticker(e.target.value)}
                  />
                </div>
              </>
            ) : validateRole() === "SP" ? (
              <>
                <div className="wrapper_input">
                  <p className="font_weight_bold">
                    Ultrasonic<label style={{ color: "red" }}>*</label>
                  </p>
                  <input
                    type="number"
                    className="input_jumlah"
                    placeholder="Target Ultrasonic"
                    value={targetUltrasonic}
                    onChange={(e) => setTargetUltrasonic(e.target.value)}
                  />
                </div>
                <div className="wrapper_input">
                  <p className="font_weight_bold">
                    Test Bocor<label style={{ color: "red" }}>*</label>
                  </p>
                  <input
                    type="number"
                    className="input_jumlah"
                    placeholder="Target Test Bocor"
                    value={targetTestBocor}
                    onChange={(e) => setTargetTestBocor(e.target.value)}
                  />
                </div>
                <div className="wrapper_input">
                  <p className="font_weight_bold">
                    Filling Plug
                    <label style={{ color: "red" }}>*</label>
                  </p>
                  <input
                    type="number"
                    className="input_jumlah"
                    placeholder="Target Filling Plug"
                    value={targetFillingPlug}
                    onChange={(e) => setTargetFillingPlug(e.target.value)}
                  />
                </div>
                <div className="wrapper_input">
                  <p className="font_weight_bold">
                    Sortir
                    <label style={{ color: "red" }}>*</label>
                  </p>
                  <input
                    type="number"
                    className="input_jumlah"
                    placeholder="Target Sortir"
                    value={targetSortir}
                    onChange={(e) => setTargetSortir(e.target.value)}
                  />
                </div>
                <div className="wrapper_input">
                  <p className="font_weight_bold">
                    Putus Ronce
                    <label style={{ color: "red" }}>*</label>
                  </p>
                  <input
                    type="number"
                    className="input_jumlah"
                    placeholder="Target Putus Ronce"
                    value={targetPutusRonce}
                    onChange={(e) => setTargetPutusRonce(e.target.value)}
                  />
                </div>

                <div className="wrapper_input">
                  <p className="font_weight_bold">
                    Pemasangan BNW
                    <label style={{ color: "red" }}>*</label>
                  </p>
                  <input
                    type="number"
                    className="input_jumlah"
                    placeholder="Target Pemasangan BNW"
                    value={targetBnw}
                    onChange={(e) => setTargetBnw(e.target.value)}
                  />
                </div>
                <div className="wrapper_input">
                  <p className="font_weight_bold">
                    Pemasangan Valve&Filter
                    <label style={{ color: "red" }}>*</label>
                  </p>
                  <input
                    type="number"
                    className="input_jumlah"
                    placeholder="Target Pemasangan Valve&Filter"
                    value={targetValveFilter}
                    onChange={(e) => setTargetValveFilter(e.target.value)}
                  />
                </div>
                <div className="wrapper_input">
                  <p className="font_weight_bold">
                    Pemasangan Handle
                    <label style={{ color: "red" }}>*</label>
                  </p>
                  <input
                    type="number"
                    className="input_jumlah"
                    placeholder="Target Pemasangan Handle"
                    value={targetPasangHandle}
                    onChange={(e) => setTargetPasangHandle(e.target.value)}
                  />
                </div>
              </>
            ) : (
              ""
            )}
          </div>
          <br />
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <button
              className="button_popup background_red"
              onClick={() => handleAddProduk()}
            >
              Kirim
            </button>
          </div>
        </PopUpComponent>
      ) : (
        ""
      )}
      <div className="wrapper_header">
        <p className="title_inventory">LIST PRODUK SP</p>
        <div
          className="wrapper_tambah_list"
          onClick={() => setPopUpTambahProduk(true)}
        >
          <div className="icon_add_list">+&nbsp;</div>
          <div className="font_tambah_button">Tambah</div>
        </div>
      </div>
      <div className="flex_wrap">
        <CardComponent
          active={customer === "GS"}
          title="PT. GS"
          logo={LogoGs}
          onClick={() => setCustomer("GS")}
        />
        <CardComponent
          active={customer === "YBI"}
          title="PT. YBI"
          logo={LogoYBI}
          onClick={() => setCustomer("YBI")}
        />
        <CardComponent
          active={customer === "TBP"}
          title="PT. TBP"
          logo={LogoTBP}
          onClick={() => setCustomer("TBP")}
        />
        <CardComponent
          active={customer === "MELCO"}
          title="PT. MELCO"
          logo={LogoMelco}
          onClick={() => setCustomer("MELCO")}
        />
        <CardComponent
          active={customer === "CBI"}
          title="PT. CBI"
          logo={LogoCBI}
          onClick={() => setCustomer("CBI")}
        />
        {dataUser.tim === 2 ? (
          <CardComponent
            active={customer === "MITRA"}
            title="PT. MITRA"
            logo={LogoMitra}
            onClick={() => setCustomer("MITRA")}
          />
        ) : (
          ""
        )}
        <CardComponent
          active={customer === "TIM"}
          title="PT. TIM"
          logo={LogoTIM}
          onClick={() => setCustomer("TIM")}
        />
        <div className="table_inventory_mobile" style={{ width: "100%" }}>
          <p className="title_inventory">LIST PRODUK PT. {customer}</p>
          <table style={{ width: "100%", marginTop: "12px" }}>
            <tr>
              <th className="table_header" style={{ position: "inherit" }}>
                No.
              </th>
              {validateRole() === "PR" ? (
                <>
                  <th className="table_header">Nama Produk</th>
                  <th className="table_header">Target Printing</th>
                  <th className="table_header">Target Punching</th>
                  <th className="table_header">Target Pasang Stiker</th>
                  <th className="table_header"></th>
                </>
              ) : (
                <>
                  <th className="table_header">Nama Produk</th>
                  <th className="table_header">Ultrasonic</th>
                  <th className="table_header">Test Bocor</th>
                  <th className="table_header">Filling Plug</th>
                  <th className="table_header">Pemasangan Valve&Filter</th>
                  <th className="table_header">Putus Ronce</th>
                  <th className="table_header">Pasang BNW</th>
                  <th className="table_header">Sortir</th>
                  <th className="table_header">Pasang Handle</th>
                  <th className="table_header"></th>
                </>
              )}
            </tr>
            {sortListProduk &&
              sortListProduk.map((data, index) => {
                return (
                  <tr
                    style={
                      index % 2 === 0
                        ? {
                            background: "white",
                          }
                        : {
                            background: "#f9f9f9",
                          }
                    }
                  >
                    <td
                      className="font_14_mobile_sp"
                      style={{ padding: "24px 0 18px 0" }}
                    >
                      {index + 1}
                    </td>
                    {validateRole() === "PR" ? (
                      <>
                        <td
                          className="font_14_mobile_sp"
                          style={{ padding: "24px 0 18px 0" }}
                        >
                          {data.produk}
                        </td>
                        <td
                          className="font_14_mobile_sp"
                          style={{ padding: "24px 0 18px 0" }}
                        >
                          {data.targetPrint} Pcs
                        </td>
                        <td
                          className="font_14_mobile_sp"
                          style={{ padding: "24px 0 18px 0" }}
                        >
                          {data.targetPunching} Pcs
                        </td>
                        <td
                          className="font_14_mobile_sp"
                          style={{ padding: "24px 0 18px 0" }}
                        >
                          {data.targetPasangStiker} Pcs
                        </td>
                      </>
                    ) : (
                      <>
                        <td
                          className="font_14_mobile_sp"
                          style={{ padding: "24px 0 18px 0" }}
                        >
                          {data.produk}
                        </td>
                        <td
                          className="font_14_mobile_sp"
                          style={{ padding: "24px 0 18px 0" }}
                        >
                          {data.targetUltrasonic} Pcs
                        </td>
                        <td
                          className="font_14_mobile_sp"
                          style={{ padding: "24px 0 18px 0" }}
                        >
                          {data.targetTestBocor} Pcs
                        </td>
                        <td
                          className="font_14_mobile_sp"
                          style={{ padding: "24px 0 18px 0" }}
                        >
                          {data.targetFilling} Pcs
                        </td>
                        <td
                          className="font_14_mobile_sp"
                          style={{ padding: "24px 0 18px 0" }}
                        >
                          {data.targetValveFilter} Pcs
                        </td>
                        <td
                          className="font_14_mobile_sp"
                          style={{ padding: "24px 0 18px 0" }}
                        >
                          {data.targetPutusRonce} Pcs
                        </td>
                        <td
                          className="font_14_mobile_sp"
                          style={{ padding: "24px 0 18px 0" }}
                        >
                          {data.targetBnw} Pcs
                        </td>
                        <td
                          className="font_14_mobile_sp"
                          style={{ padding: "24px 0 18px 0" }}
                        >
                          {data.targetSortir} Pcs
                        </td>
                        <td
                          className="font_14_mobile_sp"
                          style={{ padding: "24px 0 18px 0" }}
                        >
                          {data.targetPasangHandle} Pcs
                        </td>
                      </>
                    )}
                    <img
                      className="width_edit_logo"
                      style={{
                        background: "yellow",
                        borderRadius: "6px",
                        margin: "24px 0 18px 0",
                      }}
                      onClick={() => handlePickEdit(data)}
                      src={EditLogo}
                      alt="edit_logo"
                    />
                  </tr>
                );
              })}
          </table>
        </div>
      </div>
    </div>
  );
};

export default ListProdukSP;
