import React, { useState } from "react";
import "./CheckSheetQc.css";
import { useCheckSheetQc } from "./useCheckSheetQc";
import Select from "react-select";
import axios from "axios";
import * as XLSX from "xlsx";

const CheckSheetQc = () => {
  const {
    dataUser,
    subMenu,
    setSubMenu,
    jenisCheck,
    setJenisCheck,
    jenisProduk,
    setJenisProduk,
    handleFormChange,
    listChecklist,
    customer,
    setCustomer,
    produk,
    date,
    setDate,
    reFormat,
    produkPick,
    setProdukPick,
    shift,
    setShift,
  } = useCheckSheetQc();

  const [file, setFile] = useState(null);

  //handle masukin listMold
  // const handleConvert = () => {
  //   if (file) {
  //     const reader = new FileReader();
  //     reader.onload = async (e) => {
  //       const data = e.target.result;
  //       const workbook = XLSX.read(data, { type: "binary" });
  //       const sheetName = workbook.SheetNames[0];
  //       const worksheet = workbook.Sheets[sheetName];
  //       const json = XLSX.utils.sheet_to_json(worksheet);

  //       await axios.post(`${process.env.REACT_APP_ENDPOINT}/custom`, {
  //         data: json,
  //       });
  //     };
  //     reader.readAsBinaryString(file);
  //   }
  // };

  return (
    <div className="container_dashboard">
      {/* <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
          margin: "12px 12px 0 0",
        }}
      >
        <input
          type="file"
          accept=".xls,.xlsx"
          onChange={(e) => setFile(e.target.files[0])}
          style={{ cursor: "pointer", width: "25%" }}
        />
        <button
          style={{
            cursor: "pointer",
            padding: "12px",
            cursor: "pointer",
            background: "gold",
            border: "none",
            borderRadius: "6px",
            fontWeight: "bold",
          }}
          onClick={handleConvert}
        >
          IMPORT
        </button>
      </div> */}
      <p className="title_inventory" style={{ marginTop: "24px" }}>
        Check Sheet Qc
      </p>
      <div className="wrapper_sub_menu_history">
        {dataUser.role === "super_user" ? (
          <p
            className={
              subMenu === "laporan"
                ? "sub_menu_history border_active_history"
                : "sub_menu_history"
            }
            onClick={() => setSubMenu("laporan")}
          >
            Input Laporan
          </p>
        ) : (
          ""
        )}
        {dataUser.role === "super_user" ? (
          <p
            className={
              subMenu === "report"
                ? "sub_menu_history border_active_history"
                : "sub_menu_history"
            }
            onClick={() => setSubMenu("report")}
          >
            Daily Report
          </p>
        ) : (
          ""
        )}
      </div>
      <div style={{ display: "flex", flexWrap: "wrap" }}>
        <div className="width_number_mesin" style={{ marginRight: "12px" }}>
          <select
            defaultValue="Pilih Jenis Produk"
            onChange={(e) => setJenisProduk(e.target.value)}
            className="input_select_memo_ppic"
            value={jenisProduk}
            name="customer"
          >
            <option disabled>Pilih Jenis Produk</option>
            <option value="CONTAINER">CONTAINER</option>
            <option value="COVER">COVER</option>
          </select>
        </div>
        {/* <input
          type="date"
          className="date_pick_memo_ppic"
          style={{ margin: "0 12px 12px 12px" }}
          value={date}
          onChange={(e) => setDate(e.target.value)}
        /> */}
        <div className="width_number_mesin" style={{ marginRight: "12px" }}>
          <select
            defaultValue="Pilih Customer"
            onChange={(e) => setCustomer(e.target.value)}
            className="input_select_memo_ppic"
            value={customer}
            name="customer"
          >
            <option disabled>Pilih Customer</option>
            {produk &&
              produk.map((obj) => (
                <option value={obj.company}>{obj.company}</option>
              ))}
          </select>
        </div>
        <div className="wrapper_input">
          <div className="select_item_gbj">
            <Select
              placeholder="Pilih Mold"
              options={reFormat}
              value={produkPick || ""}
              onChange={setProdukPick}
            />
          </div>
        </div>
      </div>

      <div style={{ display: "flex", marginTop: "6px", flexWrap: "wrap" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginRight: "6px",
            border: "2px solid lightgrey",
            borderRadius: "6px",
          }}
        >
          <div
            style={{
              display: "flex",
              width: "15%",
              justifyContent: "space-between",
              padding: "0px 4px 0px 8px",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "flex-start",
                margin: "12px 12px 12px 0",
              }}
              onClick={() => setJenisCheck("VISUAL")}
            >
              <input type="checkbox" checked={jenisCheck === "VISUAL"} />
              <label>Visual</label>
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "flex-start",
                margin: "12px",
              }}
              onClick={() => setJenisCheck("DIMENSI")}
            >
              <input type="checkbox" checked={jenisCheck === "DIMENSI"} />
              <label>Dimensi</label>
            </div>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            padding: "0px 4px 0px 8px",
            border: "2px solid lightgrey",
            borderRadius: "6px",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "flex-start",
              margin: "12px 12px 12px 0",
            }}
            onClick={() => setShift("1")}
          >
            <input type="checkbox" checked={shift === "1"} />
            <label>Shift 1</label>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "flex-start",
              margin: "12px",
            }}
            onClick={() => setShift("2")}
          >
            <input type="checkbox" checked={shift === "2"} />
            <label>Shift 2</label>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "flex-start",
              margin: "12px",
            }}
            onClick={() => setShift("3")}
          >
            <input type="checkbox" checked={shift === "3"} />
            <label>Shift 3</label>
          </div>
        </div>
      </div>

      <br />
      {jenisCheck === "VISUAL" ? (
        <p>
          <b>Visual Check</b>
        </p>
      ) : jenisCheck === "DIMENSI" ? (
        <p>
          <b>Dimensi Check</b>
        </p>
      ) : (
        ""
      )}
      <div style={{ overflowY: "auto" }}>
        <table>
          <tr>
            <th style={{ width: "4%", fontSize: "14px", padding: "12px 6px" }}>
              No.
            </th>
            <th style={{ width: "21%", fontSize: "14px", padding: "12px 6px" }}>
              Jenis Cacat
            </th>
            <th style={{ width: "25%", fontSize: "14px", padding: "12px 6px" }}>
              Standard
            </th>
            <th style={{ width: "17%", fontSize: "14px", padding: "12px 6px" }}>
              Metode Check
            </th>
            <th style={{ width: "5%", fontSize: "14px", padding: "12px 6px" }}>
              Check
            </th>
            <th style={{ width: "21%", fontSize: "14px", padding: "12px 6px" }}>
              Catatan
            </th>
            <th style={{ width: "5%", fontSize: "14px", padding: "12px 6px" }}>
              Status
            </th>
          </tr>
          {listChecklist &&
            listChecklist.map((e, index) => {
              return (
                <tr
                  style={
                    index % 2 === 0
                      ? {
                          background: "white",
                          fontSize: "12px",
                        }
                      : {
                          background: "#f9f9f9",
                          fontSize: "12px",
                        }
                  }
                >
                  <td
                    className="font_td_laporan_bahan_baku"
                    style={{
                      padding: "6px",
                      lineHeight: "18px",
                      fontSize: "14px",
                    }}
                  >
                    {index + 1}.
                  </td>
                  <td
                    className="font_td_laporan_bahan_baku"
                    style={{
                      padding: "6px",
                      lineHeight: "18px",
                      textAlign: "left",
                      fontSize: "14px",
                    }}
                  >
                    {e.desc}
                  </td>
                  <td
                    className="font_td_laporan_bahan_baku"
                    style={{
                      padding: "6px 0 8px 0",
                      lineHeight: "18px",
                      fontSize: "14px",
                    }}
                  >
                    {e.standard}
                  </td>
                  <td
                    className="font_td_laporan_bahan_baku"
                    style={{
                      padding: "6px",
                      lineHeight: "18px",
                      fontSize: "14px",
                    }}
                  >
                    {e.method}
                  </td>
                  <td
                    className="font_td_laporan_bahan_baku"
                    style={{
                      padding: "6px",
                      lineHeight: "18px",
                      fontSize: "14px",
                    }}
                  >
                    <input
                      type="checkbox"
                      style={{ width: "24px", height: "24px" }}
                      checked={e.isChecked}
                      name="isChecked"
                      onClick={(e) => handleFormChange(index, e)}
                    />
                  </td>
                  <td
                    className="font_td_laporan_bahan_baku"
                    style={{
                      padding: "6px 0",
                      lineHeight: "18px",
                    }}
                  >
                    <textarea
                      style={{
                        width: "90%",
                        height: "28px",
                        fontSize: "12px",
                        fontFamily: "sans-serif",
                        resize: "none",
                      }}
                      name="catatan"
                      value={e.catatan}
                      onChange={(e) => handleFormChange(index, e)}
                    />
                  </td>
                  <td
                    className="font_td_laporan_bahan_baku"
                    style={{
                      padding: "6px 0",
                      lineHeight: "18px",
                      fontSize: "14px",
                    }}
                  >
                    {e.isChecked ? (
                      <b style={{ color: "green" }}>OK</b>
                    ) : (
                      <b style={{ color: "red" }}>NG</b>
                    )}
                  </td>
                </tr>
              );
            })}
        </table>
      </div>
    </div>
  );
};

export default CheckSheetQc;
