import React from "react";
import TableHistory from "../../components/TableHistory/TableHistory";
import { useHistory } from "./useHistory";
import TableHistoryPPIC from "../../components/TableHistoryPPIC/TableHistoryPPIC";
import TableQualityControl from "../../components/TableQualityControl/TableQualityControl";
import PopUpComponent from "../../components/PopUp/PopUpComponent";
import EditLogo from "../../Assets/editLogo.png";
import CloseLogo from "../../Assets/close_icon.png";
import "./History.css";

const History = () => {
  const {
    subMenuHistory,
    setSubMenuHistory,
    handleSubMenuHistory,
    dataHistoryPPIC,
    dateHistoryPPIC,
    handleChangeDate,
    dataHistoryPendukungPPIC,
    dateQc,
    setDateQc,
    shiftQc,
    setShiftQc,
    dataQc,
    memoGanti,
    dataUser,
    shiftProduksi,
    setShiftProduksi,
    dateProduksi,
    setDateProduksi,
    historyDataOperator,
    handleEditChecklist,
    popUpGantiMold,
    setPopUpGantiMold,
    dataPopUpGantiMold,
    bagian,
    listTugas,
    handleFormChange,
    handleKirimStatus,
    setCommentGantiMold,
    commentGantiMold,
    handleKirimComment,
    exportLaporanProduksi,
    exportDataCycleTime,
    popUpLaporanProduksi,
    setPopUpLaporanProduksi,
    dariTanggalLaporanProduksi,
    setDariTanggalLaporanProduksi,
    sampaiTanggalLaporanProduksi,
    setSampaiTanggalLaporanProduksi,
    listTopPlate,
    dateCycleTime,
    setDateCycleTime,
    dateLaporanPasangKaret,
    setDateLaporanPasangKaret,
    dataLaporanPasangKaret,
    dateUntilLaporanPasangKaret,
    setDateUntilLaporanPasangKaret,
    exportVentPlug,
    dataDetailProduk,
    shiftVP,
    setShiftVP,
    exporDataBerat,
    bulanExport,
    setBulanExport,
    tahunExport,
    setTahunExport,
  } = useHistory();

  const dataUtama = memoGanti && memoGanti.utama;
  const dataSatuan = memoGanti && memoGanti.satuan;

  const sortData =
    historyDataOperator &&
    historyDataOperator.sort(function (a, b) {
      if (Number(a.mesin) < Number(b.mesin)) {
        return -1;
      }
      if (Number(a.mesin) > Number(b.mesin)) {
        return 1;
      }
      return 0;
    });

  const sortDataPPIC =
    dataHistoryPPIC &&
    dataHistoryPPIC.sort(function (a, b) {
      if (Number(a.mesin) < Number(b.mesin)) {
        return -1;
      }
      if (Number(a.mesin) > Number(b.mesin)) {
        return 1;
      }
      return 0;
    });

  const sortDataQuality =
    dataQc &&
    dataQc.data.sort(function (a, b) {
      if (Number(a.mesin) < Number(b.mesin)) {
        return -1;
      }
      if (Number(a.mesin) > Number(b.mesin)) {
        return 1;
      }
      return 0;
    });

  const sortDataTopPlate =
    listTopPlate &&
    listTopPlate.sort(function (a, b) {
      if (Number(a.mesin) < Number(b.mesin)) {
        return -1;
      }
      if (Number(a.mesin) > Number(b.mesin)) {
        return 1;
      }
      return 0;
    });

  const sortDataPasangKaret =
    dataLaporanPasangKaret &&
    dataLaporanPasangKaret.sort(function (a, b) {
      if (Number(a.mesin) < Number(b.mesin)) {
        return -1;
      }
      if (Number(a.mesin) > Number(b.mesin)) {
        return 1;
      }
      if (a.mesin < b.mesin) {
        return -1;
      }
      if (a.mesin > b.mesin) {
        return 1;
      }
      return 0;
    });

  return (
    <div className="container_history">
      {popUpLaporanProduksi === "laporanProduksi" ? (
        <PopUpComponent>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <p>Laporan Produksi</p>
            <img
              className="width_edit_logo"
              src={CloseLogo}
              onClick={() => setPopUpLaporanProduksi("")}
              alt="edit_logo"
            />
          </div>
          <div style={{ display: "flex" }}>
            <div className="margin_right_27_qc">
              <p>Dari</p>
              <input
                type="date"
                className="date_pick_memo_ppic"
                value={dariTanggalLaporanProduksi}
                onChange={(e) => setDariTanggalLaporanProduksi(e.target.value)}
              />
            </div>
            <div className="margin_right_27_qc">
              <p>Sampai</p>
              <input
                type="date"
                className="date_pick_memo_ppic"
                value={sampaiTanggalLaporanProduksi}
                onChange={(e) =>
                  setSampaiTanggalLaporanProduksi(e.target.value)
                }
              />
            </div>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginBottom: "12px",
            }}
          >
            <button
              className="button_popup background_red"
              onClick={() => exportLaporanProduksi()}
            >
              Kirim
            </button>
          </div>
        </PopUpComponent>
      ) : popUpLaporanProduksi === "dataCycleTime" ? (
        <PopUpComponent>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <p>Data Cycle Time</p>
            <img
              className="width_edit_logo"
              src={CloseLogo}
              onClick={() => setPopUpLaporanProduksi("")}
              alt="edit_logo"
            />
          </div>
          <div style={{ display: "flex" }}>
            <div className="margin_right_27_qc">
              <p>Tanggal</p>
              <input
                type="date"
                className="date_pick_memo_ppic"
                value={dateCycleTime}
                onChange={(e) => setDateCycleTime(e.target.value)}
              />
            </div>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginBottom: "12px",
            }}
          >
            <button
              className="button_popup background_red"
              onClick={() => exportDataCycleTime()}
            >
              Kirim
            </button>
          </div>
        </PopUpComponent>
      ) : popUpLaporanProduksi === "pasangKaret" ? (
        <PopUpComponent>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <p>Pasang Karet</p>
            <img
              className="width_edit_logo"
              src={CloseLogo}
              onClick={() => setPopUpLaporanProduksi("")}
              alt="edit_logo"
            />
          </div>
          <div style={{ display: "flex" }}>
            <div className="margin_right_27_qc">
              <p>Dari</p>
              <input
                type="date"
                className="date_pick_memo_ppic"
                value={dateLaporanPasangKaret}
                onChange={(e) => setDateLaporanPasangKaret(e.target.value)}
              />
            </div>
            <div className="margin_right_27_qc">
              <p>Sampai</p>
              <input
                type="date"
                className="date_pick_memo_ppic"
                value={dateUntilLaporanPasangKaret}
                onChange={(e) => setDateUntilLaporanPasangKaret(e.target.value)}
              />
            </div>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginBottom: "12px",
            }}
          >
            <button
              className="button_popup background_red"
              onClick={() => exportVentPlug()}
            >
              Kirim
            </button>
          </div>
        </PopUpComponent>
      ) : (
        ""
      )}
      {popUpGantiMold ? (
        <PopUpComponent>
          <div style={{ height: "520px", overflowY: "scroll" }}>
            <div style={{ margin: "0 6px" }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <p>Checklist &nbsp; {bagian}</p>
                <img
                  className="width_edit_logo"
                  src={CloseLogo}
                  onClick={() => setPopUpGantiMold(false)}
                  alt="edit_logo"
                />
              </div>
              <p>{dataPopUpGantiMold.mold}</p>
              <div style={{ display: "flex", fontSize: "14px" }}>
                <p style={{ width: "7%", fontWeight: "bold" }}>No</p>
                <p style={{ width: "34%", fontWeight: "bold" }}>Point</p>
                <p style={{ width: "44%", fontWeight: "bold" }}>Standar</p>
                <div
                  style={{
                    display: "flex",
                    width: "15%",
                    justifyContent: "space-between",
                  }}
                >
                  <p style={{ fontWeight: "bold" }}>OK</p>
                  <p style={{ fontWeight: "bold" }}>NO</p>
                </div>
              </div>
              {listTugas &&
                listTugas.map((data, index) => (
                  <div style={{ display: "flex", fontSize: "14px" }}>
                    <p style={{ width: "7%" }}>{data.id}.</p>
                    {Array.isArray(data.desc) ? (
                      <div style={{ width: "100%" }}>
                        {data.desc.map((e) => (
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <p style={{ width: "35%" }}>{e.desc}</p>
                            <p style={{ width: "45%" }}>{e.standar}</p>
                            <div
                              style={{
                                display: "flex",
                                width: "15%",
                                justifyContent: "space-between",
                              }}
                            >
                              <input type="checkbox" />
                              <input type="checkbox" />
                            </div>
                          </div>
                        ))}
                      </div>
                    ) : (
                      <>
                        <p style={{ width: "35%" }}>{data.desc}</p>
                        <p style={{ width: "45%" }}>{data.standar}</p>
                        <div
                          style={{
                            display: "flex",
                            width: "15%",
                            justifyContent: "space-between",
                          }}
                        >
                          <input
                            type="checkbox"
                            name="status"
                            checked={data.status === "Ok"}
                            value="Ok"
                            onClick={(e) => handleFormChange(index, e)}
                          />
                          <input
                            type="checkbox"
                            name="status"
                            checked={data.status === "No"}
                            value="No"
                            onClick={(e) => handleFormChange(index, e)}
                          />
                        </div>
                      </>
                    )}
                  </div>
                ))}

              <div
                className="wrapper_button_submit_popup"
                style={{ marginTop: "24px" }}
              >
                <button
                  className="button_popup background_red"
                  onClick={() => handleKirimStatus("No")}
                >
                  Trouble
                </button>
                <button
                  className="button_popup background_red"
                  style={{ background: "green" }}
                  onClick={() => handleKirimStatus("Ok")}
                >
                  OK
                </button>
              </div>
            </div>
          </div>
        </PopUpComponent>
      ) : (
        ""
      )}
      <p className="title_inventory">HISTORY</p>
      <div className="wrapper_sub_menu_history">
        {dataUser.role === "super_user" ||
        dataUser.role === "produksi_admin" ||
        dataUser.role === "ppic_admin" ? (
          <p
            className={
              subMenuHistory === "laporanProduksi"
                ? "sub_menu_history border_active_history"
                : "sub_menu_history"
            }
            onClick={() => setSubMenuHistory("laporanProduksi")}
          >
            Laporan Produksi
          </p>
        ) : (
          ""
        )}
        {dataUser.role === "super_user" ||
        dataUser.role === "produksi_admin" ||
        dataUser.role === "ppic_admin" ? (
          <p
            className={
              subMenuHistory === "ventPlug"
                ? "sub_menu_history border_active_history"
                : "sub_menu_history"
            }
            onClick={() => setSubMenuHistory("ventPlug")}
          >
            Vent Plug
          </p>
        ) : (
          ""
        )}
        {dataUser.role === "super_user" ||
        dataUser.role === "ppic_admin" ||
        dataUser.role === "qc_user" ||
        dataUser.role === "qc_admin" ||
        dataUser.role === "produksi_admin" ||
        dataUser.role === "produksi_leader" ? (
          <p
            className={
              subMenuHistory === "qc"
                ? "sub_menu_history border_active_history"
                : "sub_menu_history"
            }
            onClick={() => setSubMenuHistory("qc")}
          >
            QC
          </p>
        ) : (
          ""
        )}
        {dataUser.role === "super_user" ||
        dataUser.role === "qc_user" ||
        dataUser.role === "qc_admin" ? (
          <p
            className={
              subMenuHistory === "detailProduk"
                ? "sub_menu_history border_active_history"
                : "sub_menu_history"
            }
            onClick={() => setSubMenuHistory("detailProduk")}
          >
            Detail Produk
          </p>
        ) : (
          ""
        )}
        <p
          className={
            subMenuHistory === "ppic"
              ? "sub_menu_history border_active_history"
              : "sub_menu_history"
          }
          onClick={() => handleSubMenuHistory("ppic")}
        >
          PPIC
        </p>
        <p
          className={
            subMenuHistory === "gantiMold"
              ? "sub_menu_history border_active_history"
              : "sub_menu_history"
          }
          onClick={() => handleSubMenuHistory("gantiMold")}
        >
          Ganti Produksi
        </p>
      </div>
      {subMenuHistory === "qc" ? (
        <TableQualityControl
          dateQc={dateQc}
          setDateQc={setDateQc}
          shiftQc={shiftQc}
          setShiftQc={setShiftQc}
          dataQc={sortDataQuality && sortDataQuality}
          dataTopPlate={sortDataTopPlate && sortDataTopPlate}
          exportBerat={exporDataBerat}
          bulanExport={bulanExport}
          setBulanExport={setBulanExport}
          tahunExport={tahunExport}
          setTahunExport={setTahunExport}
        />
      ) : subMenuHistory === "ppic" ? (
        <>
          <p className="title_inventory">Bahan Baku</p>
          <p>Pilih Tanggal</p>
          <input
            type="date"
            className="date_pick_memo_ppic"
            value={dateHistoryPPIC}
            onChange={(e) => handleChangeDate(e)}
          />
          <TableHistoryPPIC data={sortDataPPIC} jenisBahan="BAKU" />
          <br />
        </>
      ) : subMenuHistory === "gantiMold" ? (
        <>
          <p>Pilih Tanggal</p>
          <input
            type="date"
            className="date_pick_memo_ppic"
            value={dateHistoryPPIC}
            onChange={(e) => handleChangeDate(e)}
          />
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
            }}
          >
            {dataUtama &&
              dataUtama.map((obj) => {
                return (
                  <div className="card_ganti_mold">
                    <div
                      style={{ display: "flex", textAlign: "center" }}
                      className="font_14_history"
                    >
                      <p style={{ width: "33.33%", fontWeight: "bold" }}>
                        Mesin {obj.mesin} {obj.urutan}
                      </p>
                      <p style={{ width: "33.33%", fontWeight: "bold" }}>
                        {obj.customer}
                      </p>
                      <div
                        style={{
                          width: "33.33%",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        {(dataUser.role === "mold_admin" &&
                          obj.statusMold.isOk === "good") ||
                        (dataUser.role === "mold_user" &&
                          obj.statusMold.isOk === "good") ||
                        (dataUser.role === "mesin_admin" &&
                          obj.statusMesin.isOk === "good") ||
                        (dataUser.role === "mesin_user" &&
                          obj.statusMesin.isOk === "good") ||
                        (dataUser.role === "warehouse_admin" &&
                          obj.statusWarehouse.isOk === "good") ||
                        (dataUser.role === "warehouse_mixing" &&
                          obj.statusWarehouse.isOk === "good") ||
                        (dataUser.role === "produksi_leader" &&
                          obj.statusProduksi.isOk === "good") ||
                        (dataUser.role === "qc_admin" &&
                          obj.statusQuality.isOk === "good") ||
                        (dataUser.role === "qc_user" &&
                          obj.statusQuality.isOk === "good") ||
                        dataUser.role === "ppic_admin" ? (
                          ""
                        ) : (
                          <img
                            className="width_edit_logo"
                            style={{ background: "yellow" }}
                            src={EditLogo}
                            onClick={() => handleEditChecklist(obj, "utama")}
                            alt="edit_logo"
                          />
                        )}
                      </div>
                    </div>
                    <div
                      style={{ display: "flex", textAlign: "center" }}
                      className="font_14_history"
                    >
                      <p style={{ width: "33.33%", fontWeight: "bold" }}>
                        {obj.moldProduk}
                      </p>
                      <p style={{ width: "33.33%", fontWeight: "bold" }}>
                        {obj.color}
                      </p>
                      <p style={{ width: "33.33%", fontWeight: "bold" }}>
                        {obj.totalBahan ? `${obj.totalBahan} Pcs` : ""}
                        <br />
                        {obj.totalBahan ? "STOP" : ""}
                      </p>
                    </div>
                    <div
                      style={{ display: "flex", textAlign: "center" }}
                      className="font_14_history"
                    >
                      <p style={{ width: "10%" }}>1.</p>
                      <p style={{ width: "26%" }}>Mold</p>
                      <p
                        style={
                          obj.statusMold.isOk === "good"
                            ? { width: "32%", color: "green" }
                            : obj.statusMold.isOk === "block"
                            ? { width: "32%", color: "red" }
                            : { width: "32%" }
                        }
                      >
                        {obj.statusMold.isOk === "good"
                          ? "Complete"
                          : obj.statusMold.isOk === "block"
                          ? "Trouble"
                          : "On Progress"}
                      </p>
                      <p style={{ width: "32%" }}>
                        {obj &&
                          obj.statusMold.approver
                            .split(" ")
                            .slice(0, 1)
                            .join(" ")}
                      </p>
                      <p style={{ width: "32%" }}>
                        <div style={{ marginBottom: "12px" }}>
                          {obj.statusMold.date}
                        </div>
                        <div>{obj.statusMold.time}</div>
                      </p>
                    </div>
                    <div
                      style={{ display: "flex", textAlign: "center" }}
                      className="font_14_history"
                    >
                      <p style={{ width: "10%" }}>2.</p>
                      <p style={{ width: "26%" }}>Mesin</p>
                      <p
                        style={
                          obj.statusMesin.isOk
                            ? { width: "32%", color: "green" }
                            : obj.statusMesin.isOk === "block"
                            ? { width: "32%", color: "red" }
                            : { width: "32%" }
                        }
                      >
                        {obj.statusMesin.isOk
                          ? "Complete"
                          : obj.statusMesin.isOk === "block"
                          ? "Trouble"
                          : "On Progress"}
                      </p>
                      <p style={{ width: "32%" }}>
                        {obj &&
                          obj.statusMesin.approver
                            .split(" ")
                            .slice(0, 1)
                            .join(" ")}
                      </p>
                      <p style={{ width: "32%" }}>
                        <div style={{ marginBottom: "12px" }}>
                          {obj.statusMesin.date}
                        </div>
                        <div>{obj.statusMesin.time}</div>
                      </p>
                    </div>
                    <div
                      style={{ display: "flex", textAlign: "center" }}
                      className="font_14_history"
                    >
                      <p style={{ width: "10%" }}>3.</p>
                      <p style={{ width: "26%" }}>Warehouse</p>
                      <p
                        style={
                          obj.statusWarehouse.isOk
                            ? { width: "32%", color: "green" }
                            : obj.statusWarehouse.isOk === "block"
                            ? { width: "32%", color: "red" }
                            : { width: "32%" }
                        }
                      >
                        {obj.statusWarehouse.isOk
                          ? "Complete"
                          : obj.statusWarehouse.isOk === "block"
                          ? "Trouble"
                          : "On Progress"}
                      </p>
                      <p style={{ width: "32%" }}>
                        {obj &&
                          obj.statusWarehouse.approver
                            .split(" ")
                            .slice(0, 1)
                            .join(" ")}
                      </p>
                      <p style={{ width: "32%" }}>
                        <div style={{ marginBottom: "12px" }}>
                          {obj.statusWarehouse.date}
                        </div>
                        <div>{obj.statusWarehouse.time}</div>
                      </p>
                    </div>
                    <div
                      style={{ display: "flex", textAlign: "center" }}
                      className="font_14_history"
                    >
                      <p style={{ width: "10%" }}>4.</p>
                      <p style={{ width: "26%" }}>Produksi</p>
                      <p
                        style={
                          obj.statusProduksi.isOk
                            ? { width: "32%", color: "green" }
                            : obj.statusProduksi.isOk === "block"
                            ? { width: "32%", color: "red" }
                            : { width: "32%" }
                        }
                      >
                        {obj.statusProduksi.isOk
                          ? "Complete"
                          : obj.statusProduksi.isOk === "block"
                          ? "Trouble"
                          : "On Progress"}
                      </p>
                      <p style={{ width: "32%" }}>
                        {obj &&
                          obj.statusProduksi.approver
                            .split(" ")
                            .slice(0, 1)
                            .join(" ")}
                      </p>
                      <p style={{ width: "32%" }}>
                        <div style={{ marginBottom: "12px" }}>
                          {obj.statusProduksi.date}
                        </div>
                        <div>{obj.statusProduksi.time}</div>
                      </p>
                    </div>
                    <div
                      style={{ display: "flex", textAlign: "center" }}
                      className="font_14_history"
                    >
                      <p style={{ width: "10%" }}>5.</p>
                      <p style={{ width: "26%" }}>Quality</p>
                      <p
                        style={
                          obj.statusQuality.isOk
                            ? { width: "32%", color: "green" }
                            : obj.statusQuality.isOk === "block"
                            ? { width: "32%", color: "red" }
                            : { width: "32%" }
                        }
                      >
                        {obj.statusQuality.isOk
                          ? "Complete"
                          : obj.statusQuality.isOk === "block"
                          ? "Trouble"
                          : "On Progress"}
                      </p>
                      <p style={{ width: "32%" }}>
                        {obj &&
                          obj.statusQuality.approver
                            .split(" ")
                            .slice(0, 1)
                            .join(" ")}
                      </p>
                      <p style={{ width: "32%" }}>
                        <div style={{ marginBottom: "12px" }}>
                          {obj.statusQuality.date}
                        </div>
                        <div>{obj.statusQuality.time}</div>
                      </p>
                    </div>
                    <div>
                      <textarea
                        style={{
                          resize: "none",
                          height: "84px",
                          fontFamily: "arial",
                          width: "96%",
                          padding: "6px",
                          margin: "12px 0",
                        }}
                        value={commentGantiMold}
                        onChange={(e) => setCommentGantiMold(e.target.value)}
                        placeholder="Comment"
                      />
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        marginBottom: "12px",
                      }}
                    >
                      <button
                        className="button_popup background_red"
                        onClick={() => handleKirimComment(obj.mesin, "utama")}
                      >
                        Kirim
                      </button>
                    </div>
                    {obj.comment &&
                      obj.comment.map((data) => (
                        <div
                          style={{
                            background: "#f6f6fc",
                            padding: "6px 12px",
                            borderRadius: "6px",
                            marginBottom: "12px",
                          }}
                        >
                          <p style={{ fontWeight: "bold" }}>
                            {data.name} - {data.time}
                          </p>
                          <p
                            style={{ maxWidth: "100%", wordBreak: "break-all" }}
                          >
                            {data.desc}
                          </p>
                        </div>
                      ))}
                  </div>
                );
              })}

            {dataSatuan &&
              dataSatuan.map((obj) => {
                return (
                  <div className="card_ganti_mold">
                    <div
                      style={{ display: "flex", textAlign: "center" }}
                      className="font_14_history"
                    >
                      <p style={{ width: "33.33%", fontWeight: "bold" }}>
                        Mesin {obj.mesin}
                      </p>
                      <p style={{ width: "33.33%", fontWeight: "bold" }}>
                        {obj.customer}
                      </p>
                      <div
                        style={{
                          width: "33.33%",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        {(dataUser.role === "mold_admin" &&
                          obj.statusMold.isOk === "good") ||
                        (dataUser.role === "mold_user" &&
                          obj.statusMold.isOk === "good") ||
                        (dataUser.role === "mesin_admin" &&
                          obj.statusMesin.isOk === "good") ||
                        (dataUser.role === "mesin_user" &&
                          obj.statusMesin.isOk === "good") ||
                        (dataUser.role === "warehouse_admin" &&
                          obj.statusWarehouse.isOk === "good") ||
                        (dataUser.role === "warehouse_mixing" &&
                          obj.statusWarehouse.isOk === "good") ||
                        (dataUser.role === "produksi_leader" &&
                          obj.statusProduksi.isOk === "good") ||
                        (dataUser.role === "qc_admin" &&
                          obj.statusQuality.isOk === "good") ||
                        (dataUser.role === "qc_user" &&
                          obj.statusQuality.isOk === "good") ||
                        dataUser.role === "ppic_admin" ? (
                          ""
                        ) : (
                          <img
                            className="width_edit_logo"
                            style={{ background: "yellow" }}
                            src={EditLogo}
                            onClick={() => handleEditChecklist(obj, "satuan")}
                            alt="edit_logo"
                          />
                        )}
                      </div>
                    </div>
                    <div
                      style={{ display: "flex", textAlign: "center" }}
                      className="font_14_history"
                    >
                      <p style={{ width: "33.33%", fontWeight: "bold" }}>
                        {obj.moldProduk}
                      </p>
                      <p style={{ width: "33.33%", fontWeight: "bold" }}>
                        {obj.color}
                      </p>
                      <p style={{ width: "33.33%", fontWeight: "bold" }}>
                        {obj.totalBahan} Pcs
                      </p>
                    </div>
                    <div
                      style={{ display: "flex", textAlign: "center" }}
                      className="font_14_history"
                    >
                      <p style={{ width: "10%" }}>1.</p>
                      <p style={{ width: "26%" }}>Mold</p>
                      <p
                        style={
                          obj.statusMold.isOk === "good"
                            ? { width: "32%", color: "green" }
                            : obj.statusMold.isOk === "block"
                            ? { width: "32%", color: "red" }
                            : { width: "32%" }
                        }
                      >
                        {obj.statusMold.isOk === "good"
                          ? "Complete"
                          : obj.statusMold.isOk === "block"
                          ? "Trouble"
                          : "On Progress"}
                      </p>
                      <p style={{ width: "32%" }}>{obj.statusMold.approver}</p>
                      <p style={{ width: "32%" }}>
                        <div style={{ marginBottom: "12px" }}>
                          {obj.statusMold.date}
                        </div>
                        <div>{obj.statusMold.time}</div>
                      </p>
                    </div>
                    <div
                      style={{ display: "flex", textAlign: "center" }}
                      className="font_14_history"
                    >
                      <p style={{ width: "10%" }}>2.</p>
                      <p style={{ width: "26%" }}>Mesin</p>
                      <p
                        style={
                          obj.statusMesin.isOk === "good"
                            ? { width: "32%", color: "green" }
                            : obj.statusMesin.isOk === "block"
                            ? { width: "32%", color: "red" }
                            : { width: "32%" }
                        }
                      >
                        {obj.statusMesin.isOk === "good"
                          ? "Complete"
                          : obj.statusMesin.isOk === "block"
                          ? "Trouble"
                          : "On Progress"}
                      </p>
                      <p style={{ width: "32%" }}>{obj.statusMesin.approver}</p>
                      <p style={{ width: "32%" }}>
                        <div style={{ marginBottom: "12px" }}>
                          {obj.statusMesin.date}
                        </div>
                        <div>{obj.statusMesin.time}</div>
                      </p>
                    </div>
                    <div
                      style={{ display: "flex", textAlign: "center" }}
                      className="font_14_history"
                    >
                      <p style={{ width: "10%" }}>3.</p>
                      <p style={{ width: "26%" }}>Warehouse</p>
                      <p
                        style={
                          obj.statusWarehouse.isOk === "good"
                            ? { width: "32%", color: "green" }
                            : obj.statusWarehouse.isOk === "block"
                            ? { width: "32%", color: "red" }
                            : { width: "32%" }
                        }
                      >
                        {obj.statusWarehouse.isOk === "good"
                          ? "Complete"
                          : obj.statusWarehouse.isOk === "block"
                          ? "Trouble"
                          : "On Progress"}
                      </p>
                      <p style={{ width: "32%" }}>
                        {obj.statusWarehouse.approver}
                      </p>
                      <p style={{ width: "32%" }}>
                        <div style={{ marginBottom: "12px" }}>
                          {obj.statusWarehouse.date}
                        </div>
                        <div>{obj.statusWarehouse.time}</div>
                      </p>
                    </div>
                    <div
                      style={{ display: "flex", textAlign: "center" }}
                      className="font_14_history"
                    >
                      <p style={{ width: "10%" }}>4.</p>
                      <p style={{ width: "26%" }}>Produksi</p>
                      <p
                        style={
                          obj.statusProduksi.isOk === "good"
                            ? { width: "32%", color: "green" }
                            : obj.statusProduksi.isOk === "block"
                            ? { width: "32%", color: "red" }
                            : { width: "32%" }
                        }
                      >
                        {obj.statusProduksi.isOk === "good"
                          ? "Complete"
                          : obj.statusProduksi.isOk === "block"
                          ? "Trouble"
                          : "On Progress"}
                      </p>
                      <p style={{ width: "32%" }}>
                        {obj.statusProduksi.approver}
                      </p>
                      <p style={{ width: "32%" }}>
                        <div style={{ marginBottom: "12px" }}>
                          {obj.statusProduksi.date}
                        </div>
                        <div>{obj.statusProduksi.time}</div>
                      </p>
                    </div>
                    <div
                      style={{ display: "flex", textAlign: "center" }}
                      className="font_14_history"
                    >
                      <p style={{ width: "10%" }}>5.</p>
                      <p style={{ width: "26%" }}>Quality</p>
                      <p
                        style={
                          obj.statusQuality.isOk === "good"
                            ? { width: "32%", color: "green" }
                            : obj.statusQuality.isOk === "block"
                            ? { width: "32%", color: "red" }
                            : { width: "32%" }
                        }
                      >
                        {obj.statusQuality.isOk === "good"
                          ? "Complete"
                          : obj.statusQuality.isOk === "block"
                          ? "Trouble"
                          : "On Progress"}
                      </p>
                      <p style={{ width: "32%" }}>
                        {obj.statusQuality.approver}
                      </p>
                      <p style={{ width: "32%" }}>
                        <div style={{ marginBottom: "12px" }}>
                          {obj.statusQuality.date}
                        </div>
                        <div>{obj.statusQuality.time}</div>
                      </p>
                    </div>
                    <div>
                      <textarea
                        style={{
                          resize: "none",
                          height: "84px",
                          fontFamily: "arial",
                          width: "96%",
                          padding: "6px",
                          margin: "12px 0",
                        }}
                        value={commentGantiMold}
                        onChange={(e) => setCommentGantiMold(e.target.value)}
                        placeholder="Comment"
                      />
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        marginBottom: "12px",
                      }}
                    >
                      <button
                        className="button_popup background_red"
                        onClick={() => handleKirimComment(obj.mesin, "satuan")}
                      >
                        Kirim
                      </button>
                    </div>
                    {obj.comment &&
                      obj.comment.map((data) => (
                        <div
                          style={{
                            background: "#f6f6fc",
                            padding: "6px 12px",
                            borderRadius: "6px",
                            marginBottom: "12px",
                          }}
                        >
                          <p style={{ fontWeight: "bold" }}>
                            {data.name} - {data.time}
                          </p>
                          <p
                            style={{ maxWidth: "100%", wordBreak: "break-all" }}
                          >
                            {data.desc}
                          </p>
                        </div>
                      ))}
                  </div>
                );
              })}
          </div>
        </>
      ) : subMenuHistory === "laporanProduksi" ? (
        <>
          {dataUser.role === "super_user" ||
          dataUser.role === "produksi_admin" ||
          dataUser.role === "ppic_admin" ? (
            <>
              <div style={{ marginBottom: "12px", display: "flex" }}>
                <div
                  className="margin_right_27_qc"
                  id="data-history-laporan-produksi"
                >
                  <p>Pilih Shift</p>
                  <select
                    className="input_select_shift"
                    value={shiftProduksi}
                    onChange={(e) => setShiftProduksi(e.target.value)}
                  >
                    <option selected={shiftQc === "Pilih Shift"} disabled>
                      Pilih Shift
                    </option>
                    <option value="1">Shift 1</option>
                    <option value="2">Shift 2</option>
                    <option value="3">Shift 3</option>
                  </select>
                </div>
                <div className="margin_right_27_qc">
                  <p>Pilih Tanggal</p>
                  <input
                    type="date"
                    className="date_pick_memo_ppic"
                    value={dateProduksi}
                    onChange={(e) => setDateProduksi(e.target.value)}
                  />
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    textAlign: "center",
                    cursor: "pointer",
                    marginRight: "24px",
                  }}
                >
                  <p
                    style={{
                      background: "green",
                      color: "white",
                      padding: "12px",
                      borderRadius: "6px",
                      marginRight: "12px",
                    }}
                    onClick={() => setPopUpLaporanProduksi("laporanProduksi")}
                  >
                    Laporan
                    <br />
                    Produksi
                  </p>
                  <p
                    style={{
                      background: "green",
                      color: "white",
                      padding: "12px",
                      borderRadius: "6px",
                    }}
                    onClick={() => setPopUpLaporanProduksi("dataCycleTime")}
                  >
                    Data
                    <br />
                    Cycle Time
                  </p>
                </div>
              </div>
              <div id="container" style={{ overflowX: "auto", height: "80vh" }}>
                <table>
                  <tr>
                    <th style={{ borderRadius: "6px 0 0 0" }}>Mesin</th>
                    <th>Cust</th>
                    <th>Produk</th>
                    <th>Operator</th>
                    <th>Isi Per Kemasan</th>
                    <th>Aktual Cavity</th>
                    <th>Counter Akhir</th>
                    <th>Counter Awal</th>
                    <th>Selisih Counter</th>
                    <th>Jumlah OK</th>
                    <th>Jumlah "NG"</th>
                    <th>Total Produksi</th>
                    <th>Down Time</th>
                    <th>Cycle Time</th>
                    <th>Perincian "NG"</th>
                    <th>Jumlah</th>
                    <th
                      style={{
                        whiteSpace: "nowrap",
                      }}
                    >
                      Edited By
                    </th>
                    <th
                      style={{
                        whiteSpace: "nowrap",
                      }}
                    >
                      Edited Admin
                    </th>
                    <th style={{ borderRadius: "0 6px 0 0" }}>Keterangan</th>
                  </tr>
                  {sortData &&
                    sortData.map((obj, index) => {
                      const downtime = obj.downtime ? obj.downtime : 0;
                      const dataCounterAwal = obj.counterAwal
                        ? obj.counterAwal
                        : 0;
                      const dataCounterAkhir = obj.counterAkhir
                        ? obj.counterAkhir
                        : 0;
                      const formula =
                        (28800 - downtime * 60) /
                        (dataCounterAkhir - dataCounterAwal);

                      return (
                        <tr
                          style={
                            index % 2 === 0
                              ? { background: "white" }
                              : { background: "#f9f9f9" }
                          }
                        >
                          <td>{obj.mesin}</td>
                          <td>{obj.customer}</td>
                          <td
                            style={{
                              whiteSpace: "nowrap",
                              padding: "18px 25px",
                            }}
                          >
                            {obj.moldType}
                          </td>
                          <td style={{ whiteSpace: "nowrap" }}>
                            {obj.operator}
                          </td>
                          <td>{obj.isiPerKemasan}</td>
                          <td>{obj.aktualCavity}</td>
                          <td>{obj.counterAkhir}</td>
                          <td>{obj.counterAwal}</td>
                          <td>{obj.counterAkhir - obj.counterAwal}</td>
                          <td>{obj.jumlahBarang}</td>
                          <td>{obj.jumlahNG}</td>
                          <td>{obj.jumlahBarang + obj.jumlahNG}</td>
                          <td>{downtime}</td>
                          <td>{formula.toFixed(1)}</td>
                          <td
                            style={{
                              padding: "0 18px",
                              textAlign: "center",
                              whiteSpace: "nowrap",
                            }}
                          >
                            {obj.perincianNG &&
                              obj.perincianNG.map((ng) => <p>{ng.id}</p>)}
                          </td>
                          <td
                            style={{
                              padding: "0 18px",
                              textAlign: "center",
                              whiteSpace: "nowrap",
                            }}
                          >
                            {obj.perincianNG &&
                              obj.perincianNG.map((ng) => <p>{ng.totalNG}</p>)}
                          </td>
                          <td style={{ whiteSpace: "nowrap" }}>
                            {obj.editedBy}
                          </td>
                          <td
                            style={{
                              whiteSpace: "nowrap",
                              padding: "18px 32px",
                            }}
                          >
                            {obj.editedByAdmin}
                          </td>
                          <td
                            style={{
                              wordBreak: "break-all",
                              paddingRight: "16px",
                            }}
                          >
                            {obj.keterangan &&
                              obj.keterangan.split("\n").map((subStr) => {
                                return <p>{subStr}</p>;
                              })}
                          </td>
                        </tr>
                      );
                    })}
                </table>
              </div>
            </>
          ) : (
            ""
          )}
        </>
      ) : subMenuHistory === "ventPlug" ? (
        <>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginBottom: "24px",
            }}
          >
            <div className="margin_right_27_qc">
              <p>Pilih Shift</p>
              <select
                className="input_select_shift"
                value={shiftVP}
                onChange={(e) => setShiftVP(e.target.value)}
              >
                <option value="1">Shift 1</option>
                <option value="2">Shift 2</option>
                <option value="3">Shift 3</option>
              </select>
            </div>
            <div className="margin_right_27_qc">
              <p>Pilih Tanggal</p>
              <input
                style={{ margin: 0 }}
                type="date"
                className="date_pick_memo_ppic"
                value={dateLaporanPasangKaret}
                onChange={(e) => setDateLaporanPasangKaret(e.target.value)}
              />
            </div>
            <p
              style={{
                background: "green",
                color: "white",
                padding: "12px",
                borderRadius: "6px",
                marginRight: "12px",
                textAlign: "center",
                cursor: "pointer",
              }}
              onClick={() => setPopUpLaporanProduksi("pasangKaret")}
            >
              Laporan
              <br />
              Pasang Karet
            </p>
          </div>
          <div style={{ overflowX: "auto", height: "80vh," }}>
            <table>
              <tr>
                <th>Mesin</th>
                <th>Nama Vent Plug</th>
                <th>Warna</th>
                <th>Cust.</th>
                <th>Operator</th>
                <th>Downtime</th>
                <th>Cavity</th>
                <th>Jumlah (Pcs)</th>
                <th>Keterangan</th>
              </tr>
              {sortDataPasangKaret &&
                sortDataPasangKaret.map((element, index) => {
                  if (element.shift === shiftVP) {
                    return (
                      <tr
                        style={
                          index % 2 === 0
                            ? { background: "white" }
                            : { background: "#f9f9f9" }
                        }
                      >
                        <td style={{ padding: "16px" }}>{element.mesin}</td>
                        <td style={{ whiteSpace: "nowrap" }}>
                          {element.produk}
                        </td>
                        <td>{element.warna}</td>
                        <td>{element.customer}</td>
                        <td
                          style={{
                            minWidth: "236px",
                          }}
                        >
                          {element.operator}
                        </td>
                        <td>{element.downtime}</td>
                        <td>{element.aktualCavity}</td>
                        <td>{element.jumlah}</td>
                        <td
                          style={{
                            minWidth: "420px",
                            textAlign: "left",
                            lineHeight: "24px",
                          }}
                        >
                          {element.keterangan}
                        </td>
                      </tr>
                    );
                  }
                })}
            </table>
          </div>
        </>
      ) : subMenuHistory === "detailProduk" ? (
        <>
          <div style={{ marginBottom: "12px", display: "flex" }}>
            <div className="margin_right_27_qc">
              <p>Pilih Shift</p>
              <select
                className="input_select_shift"
                value={shiftQc}
                onChange={(e) => setShiftQc(e.target.value)}
              >
                <option selected={shiftQc === "Pilih Shift"} disabled>
                  Pilih Shift
                </option>
                <option value="1">Shift 1</option>
                <option value="2">Shift 2</option>
                <option value="3">Shift 3</option>
              </select>
            </div>
            <div className="margin_right_27_qc">
              <p>Pilih Tanggal</p>
              <input
                type="date"
                className="date_pick_memo_ppic"
                value={dateQc}
                onChange={(e) => setDateQc(e.target.value)}
              />
            </div>
          </div>
          <div style={{ overflowX: "auto", height: "80vh," }}>
            <table>
              <tr>
                <th>Mold</th>
                <th>CHECKER</th>
                <th>Cav 1</th>
                <th>Cav 2</th>
                <th>Cav 3</th>
                <th>Cav 4</th>
                <th>Cav 5</th>
                <th>Cav 6</th>
                <th>Cav 7</th>
                <th>Cav 8</th>
                <th>Cav 9</th>
                <th>Cav 10</th>
                <th>Berat TOCO</th>
                <th>Berat RUNNER</th>
              </tr>
              {dataDetailProduk &&
                dataDetailProduk.map((element, index) => {
                  return (
                    <tr
                      style={
                        index % 2 === 0
                          ? { background: "white" }
                          : { background: "#f9f9f9" }
                      }
                    >
                      <td style={{ whiteSpace: "nowrap" }}>{element.mold}</td>
                      <td>{element.checker}</td>
                      {element.dataCavity.map((cav) => (
                        <td>{cav.berat}</td>
                      ))}
                      <td>{element.toco}</td>
                      <td>{element.runner}</td>
                    </tr>
                  );
                })}
            </table>
          </div>
        </>
      ) : (
        ""
      )}
    </div>
  );
};
export default History;
