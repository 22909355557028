import React, { useEffect, useState } from "react";
import { postAxios } from "../../util/apiCall";
import "./DashboardPPIC.css";
import moment from "moment";

const DashboardPPIC = ({ data }) => {
  const dataAuth = localStorage.getItem("auth");
  const dataUser = JSON.parse(dataAuth);

  const [isOn, setIsOn] = useState(true);

  useEffect(() => {
    postAxios(
      `${process.env.REACT_APP_ENDPOINT}/emergency`,
      { tim: dataUser.tim, dataType: "emergency", type: "get" },
      dataUser.auth,
      setIsOn,
      ""
    );
  }, []);

  return (
    <div className="flex_wrap">
      {data &&
        data.map((obj) => {
          const downtime = obj.downtime ? obj.downtime : 0;
          const dataCounterAwal = obj.counterAwal ? obj.counterAwal : 0;
          const dataCounterAkhir = obj.counterAkhir ? obj.counterAkhir : 0;
          const dataTargetProduk = obj.targetProduk ? obj.targetProduk : 0;
          const dataAktualCavity = obj.aktualCavity ? obj.aktualCavity : 0;

          const formulaTargetProduksi = dataTargetProduk * dataAktualCavity;

          const formula =
            (8 * 3600 - downtime * 60) / (dataCounterAkhir - dataCounterAwal);
          return (
            <div className="wrapper_card_approval_laporan">
              <div className="section_approval_laporan">
                <p className="title_machine" style={{ margin: "12px 0 6px 0" }}>
                  Mesin&nbsp;{obj.mesin}
                </p>
                <p style={{ margin: "12px 0 6px 0" }}>
                  {moment(obj.date).format("DD MMM YYYY")}
                </p>
                <p
                  className="font_bold"
                  style={{
                    margin: "12px 0 6px 0",
                    fontSize: "18px",
                    fontWeight: "bold",
                  }}
                >
                  {obj.customer}
                </p>
              </div>
              <div className="flex_justify_between">
                <p style={{ margin: "6px 0" }}>{obj.operator}</p>
              </div>
              <div className="flex_justify_between">
                <p
                  className="font_bold"
                  style={{ margin: "6px 0", fontWeight: "bold" }}
                >
                  {obj.moldType}
                </p>
              </div>
              <div className="flex_justify_between">
                <p className="font_bold" style={{ margin: "6px 0" }}>
                  {obj.color}
                </p>
                <p style={{ textAlign: "end", margin: "6px 0" }}>
                  Shift&nbsp;{obj.shift}
                </p>
              </div>
              <div className="flex_justify_between"></div>
              {!isOn ? (
                <div className="flex_justify_between">
                  <div style={{ width: "50%" }}>
                    <p
                      style={
                        formulaTargetProduksi > obj.jumlahBarang + obj.jumlahNG
                          ? {
                              color: "red",
                              fontWeight: "bold",
                              margin: "6px 0",
                            }
                          : {
                              fontWeight: "bold",
                              margin: "6px 0",
                            }
                      }
                    >
                      Target Produksi
                    </p>
                    <p
                      style={
                        formulaTargetProduksi > obj.jumlahBarang + obj.jumlahNG
                          ? { color: "red", margin: "12px 0 6px 0" }
                          : { margin: "12px 0 6px 0" }
                      }
                    >
                      {formulaTargetProduksi.toFixed(0)}
                    </p>
                  </div>
                  <div style={{ width: "40%" }}>
                    <p
                      style={
                        28800 / dataTargetProduk < formula &&
                        28800 / dataTargetProduk !== Infinity
                          ? {
                              color: "red",
                              fontWeight: "bold",
                              margin: "6px 0",
                            }
                          : {
                              fontWeight: "bold",
                              margin: "6px 0",
                            }
                      }
                    >
                      Target CT
                    </p>
                    <p
                      style={
                        28800 / dataTargetProduk < formula &&
                        28800 / dataTargetProduk !== Infinity
                          ? { color: "red", margin: "12px 0 6px 0" }
                          : { margin: "12px 0 6px 0" }
                      }
                    >
                      {28800 / dataTargetProduk === Infinity
                        ? 0
                        : (28800 / dataTargetProduk).toFixed(2)}
                      &nbsp;Detik
                    </p>
                  </div>
                </div>
              ) : (
                ""
              )}
              <div className="flex_justify_between">
                <div style={{ width: "50%" }}>
                  <p
                    className="font_bold"
                    style={{ margin: "6px 0", fontWeight: "bold" }}
                  >
                    Isi Per Kemasan
                  </p>
                  <p className="font_14" style={{ margin: "12px 0 6px 0" }}>
                    {obj.isiPerKemasan}&nbsp;Pcs
                  </p>
                </div>
                {!isOn ? (
                  <div style={{ width: "40%" }}>
                    <p
                      className="font_bold"
                      style={{ margin: "6px 0", fontWeight: "bold" }}
                    >
                      Aktual CT
                    </p>
                    <p className="font_14" style={{ margin: "12px 0 6px 0" }}>
                      {formula === Infinity ? 0 : formula.toFixed(1)}&nbsp;Detik
                    </p>
                  </div>
                ) : (
                  ""
                )}
              </div>

              <div className="flex_justify_between">
                <div style={{ width: "50%" }}>
                  <p
                    className="font_bold"
                    style={{ margin: "6px 0", fontWeight: "bold" }}
                  >
                    Aktual Cavity
                  </p>
                  <p className="font_14" style={{ margin: "12px 0 6px 0" }}>
                    {obj.aktualCavity}
                  </p>
                </div>
                <div style={{ width: "40%" }}>
                  <p
                    className="font_bold"
                    style={{ margin: "6px 0", fontWeight: "bold" }}
                  >
                    Counter Akhir
                  </p>
                  <p className="font_14" style={{ margin: "12px 0 6px 0" }}>
                    {obj.counterAkhir}
                  </p>
                </div>
              </div>
              <div className="flex_justify_between">
                <div style={{ width: "50%" }}>
                  <p
                    className="font_bold"
                    style={{ margin: "6px 0", fontWeight: "bold" }}
                  >
                    Counter Awal
                  </p>
                  <p className="font_14" style={{ margin: "12px 0 6px 0" }}>
                    {obj.counterAwal}
                  </p>
                </div>
                <div style={{ width: "40%" }}>
                  <p
                    className="font_bold"
                    style={{ margin: "6px 0", fontWeight: "bold" }}
                  >
                    Selisih Counter
                  </p>
                  <p className="font_14" style={{ margin: "12px 0 6px 0" }}>
                    {obj.counterAkhir - obj.counterAwal}
                  </p>
                </div>
              </div>
              <div className="flex_justify_between">
                <div style={{ width: "50%" }}>
                  <p
                    className="font_bold"
                    style={{ margin: "6px 0", fontWeight: "bold" }}
                  >
                    Sisa Shift Sebelumnya
                  </p>
                  <p className="font_14" style={{ margin: "12px 0 6px 0" }}>
                    {obj.sisaShiftSebelumnya ? obj.sisaShiftSebelumnya : 0}
                    &nbsp;Pcs
                  </p>
                </div>
                <div style={{ width: "40%" }}>
                  <p
                    className="font_bold"
                    style={{ margin: "6px 0", fontWeight: "bold" }}
                  >
                    Jumlah "NG"
                  </p>
                  <p className="font_14" style={{ margin: "12px 0 6px 0" }}>
                    {obj.jumlahNG}&nbsp;Pcs
                  </p>
                </div>
              </div>
              <div className="flex_justify_between">
                <div style={{ width: "50%" }}>
                  <p
                    className="font_bold"
                    style={{ margin: "6px 0", fontWeight: "bold" }}
                  >
                    Barang OK
                  </p>
                  <p className="font_14" style={{ margin: "12px 0 6px 0" }}>
                    {obj.jumlahBarang}&nbsp;Pcs
                  </p>
                </div>
                {!isOn ? (
                  <div style={{ width: "40%" }}>
                    <p
                      className="font_bold"
                      style={{ margin: "6px 0", fontWeight: "bold" }}
                    >
                      Down Time
                    </p>
                    <p className="font_14" style={{ margin: "12px 0 6px 0" }}>
                      {downtime}&nbsp;Menit
                    </p>
                  </div>
                ) : (
                  ""
                )}
              </div>
              {dataUser.tim === 2 ? (
                <div className="flex_justify_between">
                  <div style={{ width: "50%" }}>
                    <p
                      className="font_bold"
                      style={{ margin: "6px 0", fontWeight: "bold" }}
                    >
                      Aktual Barang
                    </p>
                    <p className="font_14" style={{ margin: "12px 0 6px 0" }}>
                      {obj.aktualBarang}&nbsp;Pcs
                    </p>
                  </div>
                  <div style={{ width: "40%" }}>
                    <p style={{ margin: "6px 0", fontWeight: "bold" }}>
                      Selisih Barang
                    </p>
                    <p style={{ margin: "12px 0 6px 0" }}>
                      {obj.aktualBarang
                        ? Number(obj.aktualBarang) - Number(obj.jumlahBarang)
                        : 0}
                      &nbsp;Pcs
                    </p>
                  </div>
                </div>
              ) : (
                ""
              )}

              <div>
                <div className="flex_justify_between">
                  <p style={{ width: "50%", fontWeight: "bold" }}>
                    Perincian "NG"
                  </p>
                  <p style={{ width: "40%", fontWeight: "bold" }}>Jumlah</p>
                </div>
                {obj.perincianNG &&
                  obj.perincianNG.map((perincian) => {
                    return (
                      <div className="flex_justify_between">
                        <p
                          style={{
                            fontSize: "14px",
                            width: "50%",
                            margin: "0 0 6px 0",
                          }}
                        >
                          {perincian.id}
                        </p>
                        <p
                          style={{
                            fontSize: "14px",
                            width: "40%",
                            margin: "0 0 6px 0",
                          }}
                        >
                          {perincian.totalNG}&nbsp;Pcs
                        </p>
                      </div>
                    );
                  })}
                <div>
                  <p
                    className="font_bold"
                    style={{ fontWeight: "bold", margin: "6px 0" }}
                  >
                    Keterangan
                  </p>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: obj.keterangan.replace(/\n/g, "<br/>"),
                    }}
                  />
                </div>
              </div>
            </div>
          );
        })}
    </div>
  );
};
export default DashboardPPIC;
