import { useEffect, useState } from "react";
import { memoBahanBakuConstant } from "../../util/constant";
import { postAxios } from "../../util/apiCall";
import { getDateUtil, today } from "../../util/formatDate";

export const useNewMemoPPIC = () => {
  const dataAuth = localStorage.getItem("auth");
  const dataUser = JSON.parse(dataAuth);

  const [listProduk, setListProduk] = useState();
  const [subMenuMemo, setSubMenuMemo] = useState("BAKU");
  const [bahanPendukungTemp, setBahanPendukungTemp] = useState();
  const [dateMemoPPIC, setDateMemoPPIC] = useState(today);
  const [memoBahanPokok, setMemoBahanPokok] = useState(memoBahanBakuConstant);
  const [dataListMold, setDataListMold] = useState([]);
  const [memoGantiUtama, setMemoGantiUtama] = useState([]);
  const [checkArray, setCheckArray] = useState([]);
  const [popUpMemoPPIC, setPopUpMemoPPIC] = useState(false);
  const [isRefresh, setIsRefresh] = useState(false);

  useEffect(() => {
    postAxios(
      `${process.env.REACT_APP_ENDPOINT}/getMemoPpic`,
      { date: getDateUtil(dateMemoPPIC), jenis: "BAKU", tim: dataUser.tim },
      dataUser.auth,
      setCheckArray,
      ""
    );
  }, []);

  useEffect(() => {
    postAxios(
      `${process.env.REACT_APP_ENDPOINT}/getMemoGantiMold`,
      { date: getDateUtil(dateMemoPPIC), type: "UTAMA", tim: dataUser.tim },
      dataUser.auth,
      setMemoGantiUtama,
      ""
    );
    setIsRefresh(false);
  }, [dateMemoPPIC, isRefresh]);

  useEffect(() => {
    if (checkArray.length > 0) {
      setMemoBahanPokok(checkArray);
    } else {
      setMemoBahanPokok(memoBahanBakuConstant);
    }
  }, [checkArray]);

  useEffect(() => {
    postAxios(
      `${process.env.REACT_APP_ENDPOINT}/getListMold`,
      { tim: dataUser.tim },
      dataUser.auth,
      setDataListMold,
      ""
    );
  }, []);

  useEffect(() => {
    setListProduk(dataListMold);
  }, [dataListMold]);

  const handleChangeSubMenu = async (jenis) => {
    setSubMenuMemo(jenis);
    if (jenis === "BAKU") {
      await postAxios(
        `${process.env.REACT_APP_ENDPOINT}/getMemoPpic`,
        { date: getDateUtil(dateMemoPPIC), jenis: "BAKU", tim: dataUser.tim },
        dataUser.auth,
        setCheckArray,
        ""
      );
      if (checkArray.length > 0) {
        setMemoBahanPokok(checkArray);
      } else {
        setMemoBahanPokok(memoBahanBakuConstant);
      }
    }
  };

  const handleFormChange = (index, event, jenisBahan, type) => {
    if (jenisBahan === "BAKU") {
      let data = [...memoBahanPokok];
      if (type === "moldType") {
        const color = "color";
        const targetProduk = "targetProduk";
        const material = "material";
        const total = "total";

        let listDataPick;

        dataListMold &&
          dataListMold.forEach((element) => {
            if (element.company === data[index].customer) {
              listDataPick = element;
            }
          });

        let listProdukTemp;
        listDataPick &&
          listDataPick.data.map((data) => {
            if (data.id === event.value) {
              listProdukTemp = data;
            }
          });

        data[index][color] = listProdukTemp.color;
        data[index][targetProduk] = listProdukTemp.targetProduk;
        data[index][material] = listProdukTemp.material;
        data[index][total] = listProdukTemp.total;
        data[index][type] = event.name;
      } else {
        data[index][event.target.name] = event.target.value;
      }

      dataListMold &&
        dataListMold.forEach((obj) => {
          if (data[index].customer === obj.company) {
            let dataList = [];
            obj &&
              obj.data.forEach((element) => {
                dataList.push({
                  label: `${element.name} - ${element.color}`,
                  name: element.name,
                  value: element.id,
                });
              });
            data[index].listMoldProduk = dataList;
          }
        });

      setMemoBahanPokok(data);
    }
  };

  const handleFormChangeGantiUtama = (index, event, jenisBahan, type) => {
    let data = [...memoGantiUtama];

    if (type === "moldType") {
      const color = "color";
      const targetProduk = "targetProduk";
      const material = "material";
      const total = "total";

      let listDataPick;

      dataListMold &&
        dataListMold.forEach((element) => {
          if (element.company === data[index].customer) {
            listDataPick = element;
          }
        });

      let listProdukTemp;
      listDataPick &&
        listDataPick.data.map((data) => {
          if (data.id === event.value) {
            listProdukTemp = data;
          }
        });

      data[index][color] = listProdukTemp.color;
      data[index][targetProduk] = listProdukTemp.targetProduk;
      data[index][material] = listProdukTemp.material;
      data[index][total] = listProdukTemp.total;
      data[index][type] = event.name;
    } else {
      data[index][event.target.name] = event.target.value;
    }

    dataListMold &&
      dataListMold.forEach((obj) => {
        if (data[index].customer === obj.company) {
          let dataList = [];
          obj &&
            obj.data.forEach((element) => {
              dataList.push({
                label: `${element.name} - ${element.color}`,
                name: element.name,
                value: element.id,
              });
            });
          data[index].listMoldProduk = dataList;
        }
      });

    setMemoGantiUtama(data);
  };

  const addFields = (jenis, column) => {
    let newfield = {
      mesin: "1",
      customer: "",
      material: "",
      moldProduk: "",
      color: "",
      total: "",
      targetProduk: "",
      urutan: "",
      isNew: true,
    };
    setMemoGantiUtama([...memoGantiUtama, newfield]);
  };

  const removeFields = (jenis, column) => {
    let data = [...memoGantiUtama];
    data.splice(data.length - 1);
    setMemoGantiUtama(data);
  };

  const handleSubmitBahanBaku = async () => {
    let tempData = [];
    memoBahanPokok &&
      memoBahanPokok.forEach((obj) => {
        tempData.push({
          mesin: obj.mesin,
          customer: obj.customer,
          material: obj.material,
          color: obj.color,
          total: obj.total,
          targetProduk: obj.targetProduk,
          moldType: obj.moldType,
          status: "active",
        });
      });

    await postAxios(
      `${process.env.REACT_APP_ENDPOINT}/createMemoPpic`,
      {
        date: getDateUtil(dateMemoPPIC),
        creator: dataUser.name,
        jenis: "BAKU",
        data: tempData,
        tim: dataUser.tim,
      },
      dataUser.auth,
      "",
      ""
    );
    setPopUpMemoPPIC(true);
  };
  const handleSubmitGantiMold = async () => {
    let tempDataUtama = [];
    let resetIsNew = [];

    memoGantiUtama &&
      memoGantiUtama.forEach((obj) => {
        tempDataUtama.push({
          mesin: obj.mesin,
          urutan: obj.urutan,
          customer: obj.customer,
          material: obj.material,
          color: obj.color,
          isNew: obj.isNew,
          jenisBahan: obj.jenisBahan,
          total: Number(obj.total),
          totalBahan: Number(obj.totalBahan),
          moldProduk: obj.moldType,
          targetProduk: obj.targetProduk,
          status: "deactive",
          statusMold: {
            approver: "",
            isOk: "",
            date: "",
          },
          statusMesin: {
            isOk: "",
            approver: "",
            date: "",
          },
          statusWarehouse: {
            isOk: "",
            approver: "",
            date: "",
          },
          statusProduksi: {
            isOk: "",
            approver: "",
            date: "",
          },
          statusQuality: {
            isOk: "",
            approver: "",
            date: "",
          },
          comment: [],
        });

        resetIsNew.push({
          mesin: obj.mesin,
          urutan: obj.urutan,
          customer: obj.customer,
          material: obj.material,
          color: obj.color,
          jenisBahan: obj.jenisBahan,
          total: Number(obj.total),
          totalBahan: Number(obj.totalBahan),
          moldProduk: obj.moldType,
          targetProduk: obj.targetProduk,
          status: "deactive",
          statusMold: {
            approver: "",
            isOk: "",
            date: "",
          },
          statusMesin: {
            isOk: "",
            approver: "",
            date: "",
          },
          statusWarehouse: {
            isOk: "",
            approver: "",
            date: "",
          },
          statusProduksi: {
            isOk: "",
            approver: "",
            date: "",
          },
          statusQuality: {
            isOk: "",
            approver: "",
            date: "",
          },
          comment: [],
        });
      });

    await postAxios(
      `${process.env.REACT_APP_ENDPOINT}/createMemoGantiMold`,
      {
        date: getDateUtil(dateMemoPPIC),
        creator: dataUser.name,
        utama: tempDataUtama,
        status: "active",
        tim: dataUser.tim,
      },
      dataUser.auth,
      "",
      ""
    );
    setMemoGantiUtama(resetIsNew);
    setPopUpMemoPPIC(true);
  };

  const handleDeleteMemoGantiMold = async (input) => {
    await postAxios(
      `${process.env.REACT_APP_ENDPOINT}/deleteGantiMold`,
      {
        date: getDateUtil(dateMemoPPIC),
        data: input,
        tim: dataUser.tim,
      },
      dataUser.auth,
      "",
      ""
    );
    setIsRefresh(true);
  };

  return {
    listProduk,
    addFields,
    memoBahanPokok,
    handleFormChange,
    removeFields,
    handleChangeSubMenu,
    subMenuMemo,
    handleSubmitBahanBaku,
    setDateMemoPPIC,
    dateMemoPPIC,
    popUpMemoPPIC,
    setPopUpMemoPPIC,
    memoGantiUtama,
    handleFormChangeGantiUtama,
    handleSubmitGantiMold,
    handleDeleteMemoGantiMold,
  };
};
