import React from "react";
import "./TableInventory.css";
import moment from "moment";
import SearchLogo from "../../Assets/search-icon.png";

const TableInventory = ({ data, setPopUpSearch, setDataPickHistory }) => {
  const formatDot = (value) => {
    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  };

  return (
    <div className="wrapper_table">
      <div className="container_header">
        <div className="table_header">Material</div>
        <div className="table_header" style={{ width: "15%" }}>
          Stock (Kg)
        </div>
        <div className="table_header">Updated By</div>
        <div className="table_header">Last Update</div>
        <div className="table_header" style={{ width: "10%" }}></div>
      </div>
      {data &&
        data.map((obj, i) => {
          const splitNama =
            obj && obj.updatedBy.split(" ").slice(0, 2).join(" ");
          const handlePick = () => {
            setPopUpSearch(true);
            setDataPickHistory(obj);
          };

          let numberWithCommas =
            obj &&
            obj.weight.toLocaleString("en-US", {
              minimumFractionDigits: 0,
              maximumFractionDigits: 3,
            });

          const switchDotsAndCommas = (s) => {
            function switcher(match) {
              // Switch dot with comma and vice versa
              return match === "," ? "." : ",";
            }

            // Replace all occurrences of either dot or comma.
            // Use function switcher to decide what to replace them with.
            return s.replaceAll(/\.|\,/g, switcher);
          };

          return (
            <div
              className={
                i % 2 === 0 ? "container_data" : "container_data background_odd"
              }
              key={i}
            >
              <div className="font_data">{obj && obj.material}</div>
              <div
                className="font_data"
                style={
                  obj && obj.weight < 0
                    ? { width: "15%", color: "red" }
                    : { width: "15%" }
                }
              >
                {formatDot(switchDotsAndCommas(numberWithCommas))} Kg
              </div>
              <div className="font_data">{splitNama}</div>
              <div className="font_data">
                {moment(obj && obj.updateTime).format(
                  "ddd MMM D YYYY - H:mm:ss"
                )}
              </div>
              <div
                style={{ width: "10%", cursor: "pointer" }}
                onClick={() => handlePick()}
              >
                <img
                  className="img_search"
                  src={SearchLogo}
                  alt="search-logo"
                />
              </div>
            </div>
          );
        })}
    </div>
  );
};
export default TableInventory;
