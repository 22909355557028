import moment from "moment";

const dayDefault = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
export const monthDefaultFormat = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

export const filterMonthConstant = [
  {
    label: "Januari",
    value: 1,
  },
  {
    label: "Februari",
    value: 2,
  },
  {
    label: "Maret",
    value: 3,
  },
  {
    label: "April",
    value: 4,
  },
  {
    label: "Mei",
    value: 5,
  },
  {
    label: "Juni",
    value: 6,
  },
  {
    label: "Juli",
    value: 7,
  },
  {
    label: "Agustus",
    value: 8,
  },
  {
    label: "September",
    value: 9,
  },
  {
    label: "Oktober",
    value: 10,
  },
  {
    label: "November",
    value: 11,
  },
  {
    label: "Desember",
    value: 12,
  },
];

export const filterYearConstant = [
  {
    label: "2024",
    value: "2024",
  },
  {
    label: "2025",
    value: "2025",
  },
  {
    label: "2026",
    value: "2026",
  },
];

export const noFormat = moment().utcOffset("+0700").format();
export const formatDate = moment().utcOffset("+0700").format("YYYY-MM-DD");
export const today = moment().utcOffset("+0700").format("YYYY-MM-DD");
export const todayDefault = moment().utcOffset("+0700").format("YYYY/");

const newDate = new Date();
export const getBulanNow = newDate.getMonth() + 1;
export const getTahunNow = newDate.getFullYear();

export const yesterday = moment()
  .utcOffset("+0700")
  .add(-1, "days")
  .format("YYYY/MM/DD");
export const tomorrow = moment()
  .utcOffset("+0700")
  .add(1, "days")
  .format("YYYY/MM/DD");

export const getDateUtil = (data) => {
  return moment(data).utcOffset("+0700").format("ddd MMM DD YYYY");
};

export let changeFormatDate = (data) => {
  const d = new Date(data);

  let day = d.getDay();
  let date = d.getDate();
  let month = d.getMonth();
  let year = d.getFullYear();
  return `${dayDefault[day]} ${monthDefaultFormat[month]} ${date} ${year}`;
};

export const getTime = (e) => {
  const data = new Date(e);
  const hour = (data.getHours() < 10 ? "0" : "") + data.getHours();
  const minute = (data.getMinutes() < 10 ? "0" : "") + data.getMinutes();
  const seconds = (data.getSeconds() < 10 ? "0" : "") + data.getSeconds();
  return `${hour}:${minute}:${seconds}`;
};
