import React from "react";
import ApproveIcon from "../../Assets/approve-icon.png";
import CancelIcon from "../../Assets/cancel-icon.png";
import { kepalaShiftTimOne } from "../../util/constant";
import "./LemburKaryawan.css";
import { useLemburKaryawan } from "./useLemburKaryawan";
import Select from "react-select";
import { clockJam } from "../../util/constant";
import RefreshIcon from "../../Assets/refresh-icon.png";
import WaitingIcon from "../../Assets/waiting_icon.png";
import ApprovedIcon from "../../Assets/approved_icon.png";
import moment from "moment";

const LemburKaryawan = () => {
  const {
    dataUser,
    listLembur,
    filterCatatan,
    dateFilter,
    setDateFilter,
    filterKepala,
    setFilterKepala,
    reFormat,
    operatorPick,
    setOperatorPick,
    rencanaMasuk,
    setRencanaMasuk,
    rencanaKeluar,
    setRencanaKeluar,
    pekerjaan,
    setPekerjaan,
    catatan,
    setCatatan,
    handleKirimLembur,
    setIsReload,
    filterKepalaList,
    setFilterKepalaList,
    filterApproval,
  } = useLemburKaryawan();

  return (
    <div className="container_memo_ppic">
      <p className="title_inventory">Form Lembur</p>
      {dataUser.role === "produksi_leader" ||
      dataUser.role === "sp_admin" ||
      dataUser.role === "super_user" ||
      dataUser.role === "pr_admin" ||
      dataUser.role === "warehouse_admin" ? (
        <div style={{ display: "flex", flexWrap: "wrap" }}>
          <div className="wrapper_request_lembur">
            <p className="font_weight_bold" style={{ margin: "12px 0 6px 0" }}>
              Tanggal Lembur
            </p>
            <input
              type="date"
              className="date_pick_memo_ppic"
              style={{ margin: "0 12px 0 0" }}
              value={dateFilter}
              onChange={(e) => setDateFilter(e.target.value)}
            />
          </div>
          <div className="wrapper_request_lembur">
            <p className="font_weight_bold" style={{ margin: "12px 0 6px 0" }}>
              Kepala<label style={{ color: "red" }}>*</label>
            </p>
            <select
              defaultValue="Pilih Kepala"
              className="input_select_shift"
              value={filterKepala}
              style={{ marginRight: "12px" }}
              onChange={(e) => setFilterKepala(e.target.value)}
            >
              <option selected disabled>
                Pilih Kepala
              </option>
              {kepalaShiftTimOne.map((obj) => (
                <option value={obj.value}>{obj.name}</option>
              ))}
            </select>
          </div>
          <div className="wrapper_request_lembur width_select_lembur width_100_mobile">
            <p className="font_weight_bold" style={{ margin: "12px 0 6px 0" }}>
              Operator<label style={{ color: "red" }}>*</label>
            </p>
            <Select
              placeholder="Pilih Operator"
              options={reFormat}
              value={operatorPick || ""}
              onChange={setOperatorPick}
            />
          </div>
          <div style={{ marginRight: "24px" }}>
            <p className="font_weight_bold" style={{ margin: "12px 0 0 0" }}>
              Rencana Masuk<label style={{ color: "red" }}>*</label>
            </p>
            <div style={{ display: "flex", alignItems: "center" }}>
              <Select
                placeholder="Jam"
                options={clockJam}
                value={rencanaMasuk || ""}
                onChange={setRencanaMasuk}
              />
              <p style={{ marginLeft: "0" }}>&nbsp;: 00</p>
            </div>
          </div>
          <div style={{ marginRight: "12px" }}>
            <p className="font_weight_bold" style={{ margin: "12px 0 0 0" }}>
              Rencana Keluar<label style={{ color: "red" }}>*</label>
            </p>
            <div style={{ display: "flex", alignItems: "center" }}>
              <Select
                placeholder="Jam"
                options={clockJam}
                value={rencanaKeluar || ""}
                onChange={setRencanaKeluar}
              />
              <p style={{ marginLeft: "0" }}>&nbsp;: 00</p>
            </div>
          </div>
          <div className="wrapper_request_lembur">
            <p className="font_weight_bold" style={{ margin: "12px 0 6px 0" }}>
              Pekerjaan
            </p>
            <input
              type="text"
              className="input_jumlah"
              placeholder="Pekerjaan"
              style={{ width: "210px" }}
              value={pekerjaan}
              onChange={(e) => setPekerjaan(e.target.value)}
            />
          </div>
          <div className="wrapper_request_lembur">
            <p className="font_weight_bold" style={{ margin: "12px 0 6px 0" }}>
              Catatan
            </p>
            <input
              type="text"
              className="input_jumlah"
              placeholder="Catatan"
              style={{ width: "210px" }}
              value={catatan}
              onChange={(e) => setCatatan(e.target.value)}
            />
          </div>
          <div style={{ display: "flex", alignItems: "end" }}>
            <button
              className="button_popup background_red"
              onClick={() => handleKirimLembur()}
            >
              Tambah
            </button>
          </div>
        </div>
      ) : (
        ""
      )}
      <br />
      <p style={{ fontSize: "14px" }}>Form-HRD-A16-R0</p>
      <p
        style={{
          fontSize: "18px",
          textDecoration: "underline",
          fontWeight: "500",
        }}
      >
        Daftar Lembur
      </p>
      <div style={{ display: "flex", flexWrap: "wrap" }}>
        <input
          type="date"
          className="date_pick_memo_ppic"
          style={{ marginRight: "12px" }}
          value={dateFilter}
          onChange={(e) => setDateFilter(e.target.value)}
        />
        {(dataUser && dataUser.role === "super_user") ||
        (dataUser && dataUser.role === "ppic_admin") ? (
          <select
            defaultValue="Pilih Kepala"
            className="input_select_shift"
            style={{ marginRight: "12px" }}
            value={filterKepalaList}
            onChange={(e) => setFilterKepalaList(e.target.value)}
          >
            <option selected disabled>
              Pilih Kepala
            </option>
            {kepalaShiftTimOne.map((obj) => (
              <option value={obj.value}>{obj.name}</option>
            ))}
          </select>
        ) : (
          ""
        )}
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            // width: "-webkit-fill-available",
          }}
        >
          <div>
            <img
              src={RefreshIcon}
              alt="refresh"
              style={{ width: "36px", cursor: "pointer" }}
              onClick={() => {
                setIsReload(true);
              }}
            />
          </div>
          {filterApproval && filterApproval[0] === false ? (
            <img
              src={WaitingIcon}
              alt="status"
              style={{ width: "41px", height: "41px", marginLeft: "12px" }}
            />
          ) : filterApproval && filterApproval[0] === true ? (
            <img
              src={ApprovedIcon}
              alt="status"
              style={{ width: "41px", height: "41px", marginLeft: "12px" }}
            />
          ) : (
            ""
          )}
        </div>
      </div>
      <div style={{ overflowX: "auto" }}>
        <table style={{ width: "100%" }}>
          <tr>
            <th
              className="table_header_lembur"
              style={{
                background: "#e8e9f8",
                padding: "12px 6px",
                width: "6%",
                borderRight: "1px solid lightgray",
                width: "5%",
              }}
            >
              No
            </th>
            <th
              className="table_header_lembur"
              style={{
                background: "#e8e9f8",
                borderRight: "1px solid lightgray",
                padding: "6px",
                width: "25%",
              }}
            >
              Nama
            </th>
            <th
              className="table_header_lembur"
              style={{
                background: "#e8e9f8",
                borderRight: "1px solid lightgray",
                padding: "6px",
                width: "auto",
              }}
            >
              Rencana Masuk
            </th>
            <th
              className="table_header_lembur"
              style={{
                background: "#e8e9f8",
                borderRight: "1px solid lightgray",
                padding: "6px",
                width: "auto",
              }}
            >
              Aktual Masuk
            </th>
            <th
              className="table_header_lembur"
              style={{
                background: "#e8e9f8",
                borderRight: "1px solid lightgray",
                padding: "6px",
                width: "auto",
              }}
            >
              Rencana Keluar
            </th>
            <th
              className="table_header_lembur"
              style={{
                background: "#e8e9f8",
                borderRight: "1px solid lightgray",
                padding: "6px",
                width: "auto",
              }}
            >
              Aktual Keluar
            </th>
            <th
              className="table_header_lembur"
              style={{
                background: "#e8e9f8",
                borderRight: "1px solid lightgray",
                padding: "6px",
                width: "25%",
              }}
            >
              Pekerjaan
            </th>
            <th
              className="table_header_lembur"
              style={{
                background: "#e8e9f8",
                borderRight: "1px solid lightgray",
                padding: "6px",
                width: "4%",
              }}
            ></th>
          </tr>
          {listLembur &&
            listLembur.map((e, index) => {
              return (
                <tr
                  style={
                    index % 2 === 0
                      ? {
                          background: "white",
                        }
                      : {
                          background: "#f9f9f9",
                        }
                  }
                >
                  <td className="font_size_lembur">{index + 1}.</td>
                  <td
                    className="font_size_lembur"
                    style={{
                      textAlign: "left",
                    }}
                  >
                    {e.name}
                  </td>
                  <td className="font_size_lembur">
                    {moment(e && e.rencanaMasuk, "h:mm:ss A").format("HH:mm")}
                  </td>
                  <td className="font_size_lembur">
                    {e.aktualMasuk
                      ? moment(e && e.aktualMasuk, "h:mm:ss A").format("HH:mm")
                      : ""}
                  </td>
                  <td className="font_size_lembur">
                    {moment(e && e.rencanaKeluar, "h:mm:ss A").format("HH:mm")}
                  </td>
                  <td className="font_size_lembur">
                    {e.aktualKeluar
                      ? moment(e && e.aktualKeluar, "h:mm:ss A").format("HH:mm")
                      : ""}
                  </td>
                  <td className="font_size_lembur">{e.pekerjaan}</td>
                  <td
                    style={{
                      padding: "12px 6px 6px 6px",
                    }}
                  >
                    {e.aktualKeluar ? (
                      <img
                        style={{ width: "20px", height: "20px" }}
                        src={ApproveIcon}
                      />
                    ) : (
                      <img
                        style={{ width: "20px", height: "20px" }}
                        src={CancelIcon}
                      />
                    )}
                  </td>
                </tr>
              );
            })}
        </table>
      </div>
      <div style={{ display: "flex" }}>
        <div style={{ width: "250px", wordWrap: "break-word" }}>
          <p>Catatan: </p>
          <p>{filterCatatan[0]}</p>
        </div>
      </div>
    </div>
  );
};
export default LemburKaryawan;
