import { useEffect, useState } from "react";
import { postAxios } from "../../util/apiCall";
import { getDateUtil, today } from "../../util/formatDate";
import { validateRole } from "../../util/utilFunction";
import * as XLSX from "xlsx";
import moment from "moment";

export const useDashboard = () => {
  const dataAuth = localStorage.getItem("auth");
  const dataUser = JSON.parse(dataAuth);

  const [dateDashboard, setDateDashboard] = useState(today);
  const [shiftDashboard, setShiftDashboard] = useState("1");
  const [dataDashbord, setDataDashboard] = useState();
  const [historyDataOperator, setHistoryDataOperator] = useState();
  const [isRefresh, setIsRefresh] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [popUpSubmit, setPopUpSubmit] = useState(false);
  const [popUpDelete, setPopUpDelete] = useState(false);
  const [dataDelete, setDataDelete] = useState();
  const [dataDeletePasangKaret, setDataDeletePasangKaret] = useState();
  const [popUpDeletePasangKaret, setPopUpDeletePasangKaret] = useState(false);
  const [dataLaporanOperatorQc, setDataLaporanOperatorQc] = useState();
  const [dataLaporanPasangKaret, setDataLaporanPasangKaret] = useState();
  const [dataQc, setDataQc] = useState();
  const [noMesinEdit, setNoMesinEdit] = useState();
  const [subMenu, setSubMenu] = useState("Produksi");
  const [isEditSP, setIsEditSP] = useState(false);

  const [laporanSP, setLaporanSP] = useState();
  const [dateMonitoringSP, setDateMonitoringSP] = useState(today);
  const [isRefreshSP, setIsRefreshSP] = useState(false);

  const [dataLaporanBulananSP, setDataLaporanBulananSP] = useState();
  const [popUpLaporanBulananSP, setPopUpLaporanBulananSP] = useState(false);
  const [bulanExportSP, setBulanExportSP] = useState(
    moment().utcOffset("+0700").month() + 1
  );
  const [tahunExportSP, setTahunExportSP] = useState(
    moment().utcOffset("+0700").year()
  );

  const [isOn, setIsOn] = useState(false);

  const [filterBagian, setFilterBagian] = useState("Pilih Bagian");
  const [filterProduk, setFilterProduk] = useState("Pilih Produk");
  const [filterMesinInjeksi, setFilterMesinInjeksi] = useState("Pilih Mesin");

  const [target, setTarget] = useState("Pilih Target");

  useEffect(() => {
    postAxios(
      `${process.env.REACT_APP_ENDPOINT}/emergency`,
      { tim: dataUser.tim, dataType: "emergency", type: "get" },
      dataUser.auth,
      setIsOn,
      ""
    );
  }, []);

  useEffect(() => {
    if (
      dataUser.role === "ppic_admin" ||
      dataUser.role === "produksi_leader" ||
      dataUser.role === "super_user" ||
      dataUser.role === "mold_user"
    ) {
      postAxios(
        `${process.env.REACT_APP_ENDPOINT}/getDashboardOperator`,
        {
          tim: dataUser.tim,
          shift: shiftDashboard,
          date: getDateUtil(dateDashboard),
          type: "monitoring",
        },
        dataUser.auth,
        setDataDashboard,
        ""
      );
      postAxios(
        `${process.env.REACT_APP_ENDPOINT}/getLaporanPasangKaret`,
        {
          tim: dataUser.tim,
          date: getDateUtil(dateDashboard),
          shift: shiftDashboard,
          isKepala: true,
          type: "approvalKepala",
        },
        dataUser.auth,
        setDataLaporanPasangKaret,
        ""
      );
    }

    if (dataUser.role === "produksi_admin") {
      postAxios(
        `${process.env.REACT_APP_ENDPOINT}/getDashboardOperator`,
        {
          tim: dataUser.tim,
          operator: dataUser.name,
          shift: shiftDashboard,
          date: getDateUtil(dateDashboard),
          isAdmin: false,
          type: "admin",
        },
        dataUser.auth,
        setHistoryDataOperator,
        ""
      );
      postAxios(
        `${process.env.REACT_APP_ENDPOINT}/getLaporanPasangKaret`,
        {
          tim: dataUser.tim,
          date: getDateUtil(dateDashboard),
          shift: shiftDashboard,
          isKepala: true,
          isAdmin: false,
          type: "approvalKepala",
        },
        dataUser.auth,
        setDataLaporanPasangKaret,
        ""
      );
      postAxios(
        `${process.env.REACT_APP_ENDPOINT}/getQualityControl`,
        {
          date: getDateUtil(dateDashboard),
          shift: shiftDashboard,
          tim: dataUser.tim,
          type: "getMemoPPIC",
        },
        dataUser.auth,
        setDataQc,
        ""
      );
    }

    if (dataUser.role === "produksi_user") {
      postAxios(
        `${process.env.REACT_APP_ENDPOINT}/getDashboardOperator`,
        {
          operator: dataUser.name,
          shift: shiftDashboard,
          date: getDateUtil(dateDashboard),
          type: "operator",
          tim: dataUser.tim,
        },
        dataUser.auth,
        setHistoryDataOperator,
        ""
      );
      postAxios(
        `${process.env.REACT_APP_ENDPOINT}/getLaporanPasangKaret`,
        {
          tim: dataUser.tim,
          date: getDateUtil(dateDashboard),
          shift: shiftDashboard,
          operator: dataUser.name,
          // isKepala: false,
          type: "approvalKepala",
        },
        dataUser.auth,
        setDataLaporanPasangKaret,
        ""
      );
    }

    if (dataUser.role === "qc_user" || dataUser.role === "qc_admin") {
      postAxios(
        `${process.env.REACT_APP_ENDPOINT}/getLaporanOperator`,
        {
          tim: dataUser.tim,
          date: getDateUtil(dateDashboard),
          shift: shiftDashboard,
          type: "qc",
        },
        dataUser.auth,
        setDataLaporanOperatorQc,
        ""
      );
      postAxios(
        `${process.env.REACT_APP_ENDPOINT}/getLaporanPasangKaret`,
        {
          tim: dataUser.tim,
          date: getDateUtil(dateDashboard),
          shift: shiftDashboard,
          type: "approvalKepala",
        },
        dataUser.auth,
        setDataLaporanPasangKaret,
        ""
      );
    }
    setIsRefresh(false);
  }, [shiftDashboard, dateDashboard, isRefresh]);

  useEffect(() => {
    if (
      dataUser.role === "super_user" ||
      dataUser.role === "sp_admin" ||
      dataUser.role === "pr_admin" ||
      dataUser.role === "ppic_admin"
    ) {
      postAxios(
        `${process.env.REACT_APP_ENDPOINT}/secondProcess`,
        {
          tim: dataUser.tim,
          type: "getDashboardMonitoring",
          bagian: validateRole(),
          date: dateMonitoringSP,
          operator: "",
        },
        dataUser.auth,
        setLaporanSP,
        ""
      );
    } else if (dataUser.role === "pr_user" || dataUser.role === "sp_user") {
      postAxios(
        `${process.env.REACT_APP_ENDPOINT}/secondProcess`,
        {
          tim: dataUser.tim,
          type: "getDashboardMonitoring",
          bagian: validateRole(),
          date: dateMonitoringSP,
          operator: dataUser.name,
        },
        dataUser.auth,
        setLaporanSP,
        ""
      );
    }
    setIsRefreshSP(false);
  }, [subMenu, dateMonitoringSP, isRefreshSP]);

  useEffect(() => {
    if (
      validateRole() === "SP" ||
      validateRole() === "PR" ||
      dataUser.role === "document_control"
    ) {
      setSubMenu("SP");
    }
  }, []);

  useEffect(() => {
    if (dataUser.role === "qc_user" || dataUser.role === "qc_admin") {
      const interval = setInterval(() => {
        postAxios(
          `${process.env.REACT_APP_ENDPOINT}/getLaporanOperator`,
          {
            tim: dataUser.tim,
            date: getDateUtil(dateDashboard),
            shift: shiftDashboard,
            type: "qc",
          },
          dataUser.auth,
          setDataLaporanOperatorQc,
          ""
        );
      }, 60000);
      return () => clearInterval(interval);
    }
  }, [shiftDashboard]);

  const handleFormChange = (index, event) => {
    let data = [...historyDataOperator];

    if (event.target.name === "keterangan") {
      data[index][event.target.name] = event.target.value;
    } else {
      data[index][event.target.name] = Number(event.target.value);
    }

    setHistoryDataOperator(data);
  };

  const handleFormChangeSP = (index, event) => {
    let data = [...laporanSP];

    if (
      event.target.name === "keterangan" ||
      event.target.name === "jumlahPengerjaan" ||
      event.target.name === "mesin"
    ) {
      data[index][event.target.name] = event.target.value;
    } else {
      data[index][event.target.name] = Number(event.target.value);
    }
    setLaporanSP(data);
  };

  const handleFormChangePasangKaret = (index, event) => {
    let data = [...dataLaporanPasangKaret];

    data[index][event.target.name] = Number(event.target.value);

    setDataLaporanPasangKaret(data);
  };

  const handleKirimEditOperator = () => {
    postAxios(
      `${process.env.REACT_APP_ENDPOINT}/editOperator`,
      {
        data: historyDataOperator,
        editedBy: dataUser.name,
        type: "operator",
      },
      dataUser.auth,
      "",
      ""
    );
    setIsEdit(false);
  };

  const handleKirimEditAdmin = () => {
    if (isEdit === true) {
      postAxios(
        `${process.env.REACT_APP_ENDPOINT}/editOperator`,
        {
          data: historyDataOperator,
          pasangKaret: dataLaporanPasangKaret,
          editedByAdmin: dataUser.name,
          type: "admin",
          shift: shiftDashboard,
          date: getDateUtil(dateDashboard),
          tim: dataUser.tim,
        },
        dataUser.auth,
        "",
        ""
      );
      setIsRefresh(true);
      setPopUpSubmit(true);
    } else {
      postAxios(
        `${process.env.REACT_APP_ENDPOINT}/editOperator`,
        {
          data: historyDataOperator,
          type: "admin",
          shift: shiftDashboard,
          date: getDateUtil(dateDashboard),
          tim: dataUser.tim,
        },
        dataUser.auth,
        "",
        ""
      );
      setIsRefresh(true);
      setPopUpSubmit(true);
    }
    setIsEdit(false);
  };

  const handleSetDataDelete = (data) => {
    setDataDelete(data);
    setPopUpDelete(true);
  };

  const handleSetDataDeletePasangKaret = (data) => {
    setDataDeletePasangKaret(data);
    setPopUpDeletePasangKaret(true);
  };

  const handleCancelDelete = () => {
    setPopUpDelete(false);
    setDataDelete();
  };
  const handleCancelDeletePasangKaret = () => {
    setPopUpDeletePasangKaret(false);
    setDataDeletePasangKaret();
  };

  const handleDeleteLaporan = () => {
    const index = historyDataOperator.indexOf(
      dataDeletePasangKaret && dataDeletePasangKaret
    );

    if (index > -1) {
      historyDataOperator.splice(index, 1);
    }

    postAxios(
      `${process.env.REACT_APP_ENDPOINT}/deleteLaporanProduksi`,
      {
        data: dataDelete,
      },
      dataUser.auth,
      "",
      ""
    );
    setDataDelete();
    setPopUpDelete(false);
  };

  const handleDeleteLaporanPasangKaret = () => {
    const index = dataLaporanPasangKaret.indexOf(
      dataDeletePasangKaret && dataDeletePasangKaret
    );

    if (index > -1) {
      dataLaporanPasangKaret.splice(index, 1);
    }

    postAxios(
      `${process.env.REACT_APP_ENDPOINT}/deletePasangKaret`,
      {
        data: dataDeletePasangKaret,
      },
      dataUser.auth,
      "",
      ""
    );
    setDataDeletePasangKaret();
    setPopUpDeletePasangKaret(false);
  };

  const handleChangeMesin = (id, e) => {
    let qcTemp = dataQc && dataQc.data;
    const findNewValueQc = qcTemp.find((o) => o.moldType === e.target.value);
    setNoMesinEdit(findNewValueQc.mesin);
    let data = [...historyDataOperator];

    data.forEach((element, index) => {
      if (element.id === id) {
        data[index].mesin = findNewValueQc.mesin;
        data[index].bagian = findNewValueQc.bagian;
        data[index].beratQc = findNewValueQc.beratQc;
        data[index].color = findNewValueQc.color;
        data[index].customer = findNewValueQc.customer;
        data[index].material = findNewValueQc.material;
        data[index].mold = findNewValueQc.mold;
        data[index].moldType = findNewValueQc.moldType;
        data[index].namaBarang = findNewValueQc.namaBarang;
        data[index].runner = findNewValueQc.runner;
        data[index].targetProduk = findNewValueQc.targetProduk;
        data[index].total = findNewValueQc.total;
      }
    });

    setHistoryDataOperator(data);
  };

  const handleKirimEditSP = () => {
    postAxios(
      `${process.env.REACT_APP_ENDPOINT}/secondProcess`,
      {
        data: laporanSP,
        type: "editLaporan",
      },
      dataUser.auth,
      "",
      ""
    );
    setIsEditSP(false);
    setIsRefreshSP(true);
  };

  const exportBulanan = async () => {
    const formatDot = (value) => {
      return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    };

    postAxios(
      `${process.env.REACT_APP_ENDPOINT}/exportLaporanSP`,
      {
        tim: dataUser.tim,
        type:
          dataUser.role === "document_control" ? "exportAll" : "exportKepala",
        month: `${bulanExportSP} ${tahunExportSP}`,
        bagian: validateRole(),
      },
      dataUser.auth,
      setDataLaporanBulananSP,
      ""
    );

    let dataPrint = [];
    let dataPasangSticker = [];
    let dataPunching = [];
    let dataFillingPlug = [];
    let dataPasangHandle = [];
    let dataBnw = [];
    let dataValveFilter = [];
    let dataPutusRonce = [];
    let dataSortir = [];
    let dataTestBocor = [];
    let dataUltrasonic = [];

    for (let index = 0; index < dataLaporanBulananSP.length; index++) {
      const element = dataLaporanBulananSP[index];
      if (element.jenisPekerjaan === "Print") {
        dataPrint.push(element);
      } else if (element.jenisPekerjaan === "Pasang Sticker") {
        dataPasangSticker.push(element);
      } else if (element.jenisPekerjaan === "Punching") {
        dataPunching.push(element);
      } else if (element.jenisPekerjaan === "Filling Plug") {
        dataFillingPlug.push(element);
      } else if (element.jenisPekerjaan === "Pasang Handle") {
        dataPasangHandle.push(element);
      } else if (element.jenisPekerjaan === "Pemasangan BNW") {
        dataBnw.push(element);
      } else if (element.jenisPekerjaan === "Pemasangan Valve & Filter") {
        dataValveFilter.push(element);
      } else if (element.jenisPekerjaan === "Putus Ronce") {
        dataPutusRonce.push(element);
      } else if (element.jenisPekerjaan === "Sortir") {
        dataSortir.push(element);
      } else if (element.jenisPekerjaan === "Test Bocor") {
        dataTestBocor.push(element);
      } else if (element.jenisPekerjaan === "Ultrasonic") {
        dataUltrasonic.push(element);
      }
    }

    //Filling Plug Start
    const newListDataFillingPlug =
      dataFillingPlug &&
      dataFillingPlug.reduce((obj, item) => {
        let find = obj.find(
          (i) => i.produk === item.produk && i.color === item.color
        );
        let _d = {
          ...item,
        };
        if (find) {
          find.jumlahOk += item.jumlahOk;
          find.reject += item.reject;
          find.dataReject = find.dataReject.concat(item.dataReject);
        } else {
          obj.push(_d);
        }
        return obj;
      }, []);

    const dataExcelFilling =
      newListDataFillingPlug &&
      newListDataFillingPlug.map((obj) => {
        const reduceData =
          obj &&
          obj.dataReject.reduce((obj, item) => {
            let find = obj.find(
              (i) => i.id === item.id && i.produk === item.produk
            );
            let _d = {
              ...item,
            };
            if (find) {
              let findReject = Number(find.reject);
              let itemReject = Number(item.reject);

              find.id = item.id;
              find.reject = findReject + itemReject;
            } else {
              obj.push(_d);
            }
            return obj;
          }, []);

        const rejectBergeser =
          reduceData &&
          reduceData.find((e) => (e.id === "Bergeser" ? e.reject : ""));
        const rejectBocor =
          reduceData &&
          reduceData.find((e) => (e.id === "Bocor" ? e.reject : ""));
        const rejectCacatKosmetik =
          reduceData &&
          reduceData.find((e) => (e.id === "Cacat Kosmetik" ? e.reject : ""));
        const rejectDeformasi =
          reduceData &&
          reduceData.find((e) => (e.id === "Deformasi" ? e.reject : ""));
        const rejectDestruktif =
          reduceData &&
          reduceData.find((e) => (e.id === "Destruktif" ? e.reject : ""));
        const rejectBor =
          reduceData &&
          reduceData.find((e) => (e.id === "EX Bor" ? e.reject : ""));
        const rejectHangus =
          reduceData &&
          reduceData.find((e) => (e.id === "Hangus" ? e.reject : ""));
        const rejectNgambang =
          reduceData &&
          reduceData.find((e) => (e.id === "Plate Ngambang" ? e.reject : ""));
        const rejectSettingan =
          reduceData &&
          reduceData.find((e) => (e.id === "Settingan" ? e.reject : ""));

        return {
          "Part Name": obj.produk,
          WARNA: obj.color,
          Cust: obj.customer,
          OK: formatDot(obj.jumlahOk),
          REJECT: formatDot(obj.reject),
          TOTAL: formatDot(obj.jumlahOk + obj.reject),
          "Defect %": (
            (obj.reject / (obj.jumlahOk + obj.reject)) *
            100
          ).toFixed(2),
          Bergeser: rejectBergeser && rejectBergeser.reject,
          Bocor: rejectBocor && rejectBocor.reject,
          "Cacat Kosmetik": rejectCacatKosmetik && rejectCacatKosmetik.reject,
          Deformasi: rejectDeformasi && rejectDeformasi.reject,
          Destruktif: rejectDestruktif && rejectDestruktif.reject,
          "Ex Bor": rejectBor && rejectBor.reject,
          Hangus: rejectHangus && rejectHangus.reject,
          "Plate Ngambang": rejectNgambang && rejectNgambang.reject,
          Settingan: rejectSettingan && rejectSettingan.reject,
        };
      });

    let dataTempFilling = [];

    newListDataFillingPlug &&
      newListDataFillingPlug.map((obj) => {
        obj &&
          obj.dataReject.map((ele) => {
            dataTempFilling.push(ele);
          });
      });

    let reduceFilling =
      dataTempFilling &&
      dataTempFilling.reduce((obj, item) => {
        let find = obj.find((i) => i.id === item.id);
        let _d = {
          ...item,
        };
        if (find) {
          let findReject = Number(find.reject);
          let itemReject = Number(item.reject);
          find.id = item.id;
          find.reject = findReject + itemReject;
        } else {
          obj.push(_d);
        }
        return obj;
      }, []);

    const rejectBergeser =
      reduceFilling &&
      reduceFilling.find((e) => (e.id === "Bergeser" ? e.reject : ""));
    const rejectBocor =
      reduceFilling &&
      reduceFilling.find((e) => (e.id === "Bocor" ? e.reject : ""));
    const rejectCacatKosmetik =
      reduceFilling &&
      reduceFilling.find((e) => (e.id === "Cacat Kosmetik" ? e.reject : ""));
    const rejectDeformasi =
      reduceFilling &&
      reduceFilling.find((e) => (e.id === "Deformasi" ? e.reject : ""));
    const rejectDestruktif =
      reduceFilling &&
      reduceFilling.find((e) => (e.id === "Destruktif" ? e.reject : ""));
    const rejectBor =
      reduceFilling &&
      reduceFilling.find((e) => (e.id === "EX Bor" ? e.reject : ""));
    const rejectHangus =
      reduceFilling &&
      reduceFilling.find((e) => (e.id === "Hangus" ? e.reject : ""));
    const rejectNgambang =
      reduceFilling &&
      reduceFilling.find((e) => (e.id === "Plate Ngambang" ? e.reject : ""));
    const rejectSettingan =
      reduceFilling &&
      reduceFilling.find((e) => (e.id === "Settingan" ? e.reject : ""));

    const JumlahFillingPlug = [
      {
        "Part Name": "Total Proses Filling Plug",
        WARNA: "",
        Cust: "",
        OK: formatDot(
          newListDataFillingPlug.reduce((accumulator, object) => {
            return accumulator + object.jumlahOk;
          }, 0)
        ),
        REJECT: formatDot(
          newListDataFillingPlug.reduce((accumulator, object) => {
            return accumulator + object.reject;
          }, 0)
        ),
        TOTAL: formatDot(
          newListDataFillingPlug.reduce((accumulator, object) => {
            return accumulator + object.reject + object.jumlahOk;
          }, 0)
        ),
        "Defect %": newListDataFillingPlug
          .reduce((accumulator, object) => {
            return (
              accumulator +
              (object.reject / (object.reject + object.jumlahOk)) * 100
            );
          }, 0)
          .toFixed(2),
        Bergeser: rejectBergeser && rejectBergeser.reject,
        Bocor: rejectBocor && rejectBocor.reject,
        "Cacat Kosmetik": rejectCacatKosmetik && rejectCacatKosmetik.reject,
        Deformasi: rejectDeformasi && rejectDeformasi.reject,
        Destruktif: rejectDestruktif && rejectDestruktif.reject,
        "Ex Bor": rejectBor && rejectBor.reject,
        Hangus: rejectHangus && rejectHangus.reject,
        "Plate Ngambang": rejectNgambang && rejectNgambang.reject,
        Settingan: rejectSettingan && rejectSettingan.reject,
      },
    ];

    const concatFilling = [...dataExcelFilling, {}, ...JumlahFillingPlug, {}];

    //Filling Plug End

    //Handle Start
    const newListDataHandle =
      dataPasangHandle &&
      dataPasangHandle.reduce((obj, item) => {
        let find = obj.find(
          (i) => i.produk === item.produk && i.color === item.color
        );
        let _d = {
          ...item,
        };
        if (find) {
          find.jumlahOk += item.jumlahOk;
          find.reject += item.reject;
          find.dataReject = find.dataReject.concat(item.dataReject);
        } else {
          obj.push(_d);
        }
        return obj;
      }, []);

    const dataExcelHandle =
      newListDataHandle &&
      newListDataHandle.map((obj) => {
        const reduceData =
          obj &&
          obj.dataReject.reduce((obj, item) => {
            let find = obj.find(
              (i) => i.id === item.id && i.produk === item.produk
            );
            let _d = {
              ...item,
            };
            if (find) {
              let findReject = Number(find.reject);
              let itemReject = Number(item.reject);

              find.id = item.id;
              find.reject = findReject + itemReject;
            } else {
              obj.push(_d);
            }
            return obj;
          }, []);

        const rejectBergeserHandle =
          reduceData &&
          reduceData.find((e) => (e.id === "Bergeser" ? e.reject : ""));
        const rejectBocorHandle =
          reduceData &&
          reduceData.find((e) => (e.id === "Bocor" ? e.reject : ""));
        const rejectCacatKosmetikHandle =
          reduceData &&
          reduceData.find((e) => (e.id === "Cacat Kosmetik" ? e.reject : ""));
        const rejectDeformasiHandle =
          reduceData &&
          reduceData.find((e) => (e.id === "Deformasi" ? e.reject : ""));
        const rejectDestruktifHandle =
          reduceData &&
          reduceData.find((e) => (e.id === "Destruktif" ? e.reject : ""));
        const rejectBorHandle =
          reduceData &&
          reduceData.find((e) => (e.id === "EX Bor" ? e.reject : ""));
        const rejectHangusHandle =
          reduceData &&
          reduceData.find((e) => (e.id === "Hangus" ? e.reject : ""));
        const rejectNgambangHandle =
          reduceData &&
          reduceData.find((e) => (e.id === "Plate Ngambang" ? e.reject : ""));
        const rejectSettinganHandle =
          reduceData &&
          reduceData.find((e) => (e.id === "Settingan" ? e.reject : ""));

        return {
          "Part Name": obj.produk,
          WARNA: obj.color,
          Cust: obj.customer,
          OK: formatDot(obj.jumlahOk),
          REJECT: formatDot(obj.reject),
          TOTAL: formatDot(obj.jumlahOk + obj.reject),
          "Defect %": (
            (obj.reject / (obj.jumlahOk + obj.reject)) *
            100
          ).toFixed(2),
          Bergeser: rejectBergeserHandle && rejectBergeserHandle.reject,
          Bocor: rejectBocorHandle && rejectBocorHandle.reject,
          "Cacat Kosmetik":
            rejectCacatKosmetikHandle && rejectCacatKosmetikHandle.reject,
          Deformasi: rejectDeformasiHandle && rejectDeformasiHandle.reject,
          Destruktif: rejectDestruktifHandle && rejectDestruktifHandle.reject,
          "Ex Bor": rejectBorHandle && rejectBorHandle.reject,
          Hangus: rejectHangusHandle && rejectHangusHandle.reject,
          "Plate Ngambang": rejectNgambangHandle && rejectNgambangHandle.reject,
          Settingan: rejectSettinganHandle && rejectSettinganHandle.reject,
        };
      });

    let dataTempHandle = [];

    newListDataHandle &&
      newListDataHandle.map((obj) => {
        obj &&
          obj.dataReject.map((ele) => {
            dataTempHandle.push(ele);
          });
      });

    let reduceHandle =
      dataTempHandle &&
      dataTempHandle.reduce((obj, item) => {
        let find = obj.find((i) => i.id === item.id);
        let _d = {
          ...item,
        };
        if (find) {
          let findReject = Number(find.reject);
          let itemReject = Number(item.reject);
          find.id = item.id;
          find.reject = findReject + itemReject;
        } else {
          obj.push(_d);
        }
        return obj;
      }, []);

    const rejectBergeserHandle =
      reduceHandle &&
      reduceHandle.find((e) => (e.id === "Bergeser" ? e.reject : ""));
    const rejectBocorHandle =
      reduceHandle &&
      reduceHandle.find((e) => (e.id === "Bocor" ? e.reject : ""));
    const rejectCacatKosmetikHandle =
      reduceHandle &&
      reduceHandle.find((e) => (e.id === "Cacat Kosmetik" ? e.reject : ""));
    const rejectDeformasiHandle =
      reduceHandle &&
      reduceHandle.find((e) => (e.id === "Deformasi" ? e.reject : ""));
    const rejectDestruktifHandle =
      reduceHandle &&
      reduceHandle.find((e) => (e.id === "Destruktif" ? e.reject : ""));
    const rejectBorHandle =
      reduceHandle &&
      reduceHandle.find((e) => (e.id === "EX Bor" ? e.reject : ""));
    const rejectHangusHandle =
      reduceHandle &&
      reduceHandle.find((e) => (e.id === "Hangus" ? e.reject : ""));
    const rejectNgambangHandle =
      reduceHandle &&
      reduceHandle.find((e) => (e.id === "Plate Ngambang" ? e.reject : ""));
    const rejectSettinganHandle =
      reduceHandle &&
      reduceHandle.find((e) => (e.id === "Settingan" ? e.reject : ""));

    const JumlahHandle = [
      {
        "Part Name": "Total Proses Pasang Handle",
        WARNA: "",
        Cust: "",
        OK: formatDot(
          newListDataHandle.reduce((accumulator, object) => {
            return accumulator + object.jumlahOk;
          }, 0)
        ),
        REJECT: formatDot(
          newListDataHandle.reduce((accumulator, object) => {
            return accumulator + object.reject;
          }, 0)
        ),
        TOTAL: formatDot(
          newListDataHandle.reduce((accumulator, object) => {
            return accumulator + object.reject + object.jumlahOk;
          }, 0)
        ),
        "Defect %": newListDataHandle
          .reduce((accumulator, object) => {
            return (
              accumulator +
              (object.reject / (object.reject + object.jumlahOk)) * 100
            );
          }, 0)
          .toFixed(2),
        Bergeser: rejectBergeserHandle && rejectBergeserHandle.reject,
        Bocor: rejectBocorHandle && rejectBocorHandle.reject,
        "Cacat Kosmetik":
          rejectCacatKosmetikHandle && rejectCacatKosmetikHandle.reject,
        Deformasi: rejectDeformasiHandle && rejectDeformasiHandle.reject,
        Destruktif: rejectDestruktifHandle && rejectDestruktifHandle.reject,
        "Ex Bor": rejectBorHandle && rejectBorHandle.reject,
        Hangus: rejectHangusHandle && rejectHangusHandle.reject,
        "Plate Ngambang": rejectNgambangHandle && rejectNgambangHandle.reject,
        Settingan: rejectSettinganHandle && rejectSettinganHandle.reject,
      },
    ];

    const concatHandle = [...dataExcelHandle, {}, ...JumlahHandle, {}];

    //Handle End

    //BNW start
    const newListDataBnw =
      dataBnw &&
      dataBnw.reduce((obj, item) => {
        let find = obj.find(
          (i) => i.produk === item.produk && i.color === item.color
        );
        let _d = {
          ...item,
        };
        if (find) {
          find.jumlahOk += item.jumlahOk;
          find.reject += item.reject;
          find.dataReject = find.dataReject.concat(item.dataReject);
        } else {
          obj.push(_d);
        }
        return obj;
      }, []);

    const dataExcelBnw =
      newListDataBnw &&
      newListDataBnw.map((obj) => {
        const reduceData =
          obj &&
          obj.dataReject.reduce((obj, item) => {
            let find = obj.find(
              (i) => i.id === item.id && i.produk === item.produk
            );
            let _d = {
              ...item,
            };
            if (find) {
              let findReject = Number(find.reject);
              let itemReject = Number(item.reject);

              find.id = item.id;
              find.reject = findReject + itemReject;
            } else {
              obj.push(_d);
            }
            return obj;
          }, []);

        const rejectBergeserBnw =
          reduceData &&
          reduceData.find((e) => (e.id === "Bergeser" ? e.reject : ""));
        const rejectBocorBnw =
          reduceData &&
          reduceData.find((e) => (e.id === "Bocor" ? e.reject : ""));
        const rejectCacatKosmetikBnw =
          reduceData &&
          reduceData.find((e) => (e.id === "Cacat Kosmetik" ? e.reject : ""));
        const rejectDeformasiBnw =
          reduceData &&
          reduceData.find((e) => (e.id === "Deformasi" ? e.reject : ""));
        const rejectDestruktifBnw =
          reduceData &&
          reduceData.find((e) => (e.id === "Destruktif" ? e.reject : ""));
        const rejectBorBnw =
          reduceData &&
          reduceData.find((e) => (e.id === "EX Bor" ? e.reject : ""));
        const rejectHangusBnw =
          reduceData &&
          reduceData.find((e) => (e.id === "Hangus" ? e.reject : ""));
        const rejectNgambangBnw =
          reduceData &&
          reduceData.find((e) => (e.id === "Plate Ngambang" ? e.reject : ""));
        const rejectSettinganBnw =
          reduceData &&
          reduceData.find((e) => (e.id === "Settingan" ? e.reject : ""));

        return {
          "Part Name": obj.produk,
          WARNA: obj.color,
          Cust: obj.customer,
          OK: formatDot(obj.jumlahOk),
          REJECT: formatDot(obj.reject),
          TOTAL: formatDot(obj.jumlahOk + obj.reject),
          "Defect %": (
            (obj.reject / (obj.jumlahOk + obj.reject)) *
            100
          ).toFixed(2),
          Bergeser: rejectBergeserBnw && rejectBergeserBnw.reject,
          Bocor: rejectBocorBnw && rejectBocorBnw.reject,
          "Cacat Kosmetik":
            rejectCacatKosmetikBnw && rejectCacatKosmetikBnw.reject,
          Deformasi: rejectDeformasiBnw && rejectDeformasiBnw.reject,
          Destruktif: rejectDestruktifBnw && rejectDestruktifBnw.reject,
          "Ex Bor": rejectBorBnw && rejectBorBnw.reject,
          Hangus: rejectHangusBnw && rejectHangusBnw.reject,
          "Plate Ngambang": rejectNgambangBnw && rejectNgambangBnw.reject,
          Settingan: rejectSettinganBnw && rejectSettinganBnw.reject,
        };
      });

    let dataTempBnw = [];

    newListDataBnw &&
      newListDataBnw.map((obj) => {
        obj &&
          obj.dataReject.map((ele) => {
            dataTempBnw.push(ele);
          });
      });

    let reduceBnw =
      dataTempBnw &&
      dataTempBnw.reduce((obj, item) => {
        let find = obj.find((i) => i.id === item.id);
        let _d = {
          ...item,
        };
        if (find) {
          let findReject = Number(find.reject);
          let itemReject = Number(item.reject);
          find.id = item.id;
          find.reject = findReject + itemReject;
        } else {
          obj.push(_d);
        }
        return obj;
      }, []);

    const rejectBergeserBnw =
      reduceBnw && reduceBnw.find((e) => (e.id === "Bergeser" ? e.reject : ""));
    const rejectBocorBnw =
      reduceBnw && reduceBnw.find((e) => (e.id === "Bocor" ? e.reject : ""));
    const rejectCacatKosmetikBnw =
      reduceBnw &&
      reduceBnw.find((e) => (e.id === "Cacat Kosmetik" ? e.reject : ""));
    const rejectDeformasiBnw =
      reduceBnw &&
      reduceBnw.find((e) => (e.id === "Deformasi" ? e.reject : ""));
    const rejectDestruktifBnw =
      reduceBnw &&
      reduceBnw.find((e) => (e.id === "Destruktif" ? e.reject : ""));
    const rejectBorBnw =
      reduceBnw && reduceBnw.find((e) => (e.id === "EX Bor" ? e.reject : ""));
    const rejectHangusBnw =
      reduceBnw && reduceBnw.find((e) => (e.id === "Hangus" ? e.reject : ""));
    const rejectNgambangBnw =
      reduceBnw &&
      reduceBnw.find((e) => (e.id === "Plate Ngambang" ? e.reject : ""));
    const rejectSettinganBnw =
      reduceBnw &&
      reduceBnw.find((e) => (e.id === "Settingan" ? e.reject : ""));

    const JumlahBnw = [
      {
        "Part Name": "Total Proses Pemasangan BNW",
        WARNA: "",
        Cust: "",
        OK: formatDot(
          newListDataBnw.reduce((accumulator, object) => {
            return accumulator + object.jumlahOk;
          }, 0)
        ),
        REJECT: formatDot(
          newListDataBnw.reduce((accumulator, object) => {
            return accumulator + object.reject;
          }, 0)
        ),
        TOTAL: formatDot(
          newListDataBnw.reduce((accumulator, object) => {
            return accumulator + object.reject + object.jumlahOk;
          }, 0)
        ),
        "Defect %": newListDataBnw
          .reduce((accumulator, object) => {
            return (
              accumulator +
              (object.reject / (object.reject + object.jumlahOk)) * 100
            );
          }, 0)
          .toFixed(2),
        Bergeser: rejectBergeserBnw && rejectBergeserBnw.reject,
        Bocor: rejectBocorBnw && rejectBocorBnw.reject,
        "Cacat Kosmetik":
          rejectCacatKosmetikBnw && rejectCacatKosmetikBnw.reject,
        Deformasi: rejectDeformasiBnw && rejectDeformasiBnw.reject,
        Destruktif: rejectDestruktifBnw && rejectDestruktifBnw.reject,
        "Ex Bor": rejectBorBnw && rejectBorBnw.reject,
        Hangus: rejectHangusBnw && rejectHangusBnw.reject,
        "Plate Ngambang": rejectNgambangBnw && rejectNgambangBnw.reject,
        Settingan: rejectSettinganBnw && rejectSettinganBnw.reject,
      },
    ];

    const concatBnw = [...dataExcelBnw, {}, ...JumlahBnw, {}];

    //BNW end

    //Valve & Filter start
    const newListDataValveFilter =
      dataValveFilter &&
      dataValveFilter.reduce((obj, item) => {
        let find = obj.find(
          (i) => i.produk === item.produk && i.color === item.color
        );
        let _d = {
          ...item,
        };
        if (find) {
          find.jumlahOk += item.jumlahOk;
          find.reject += item.reject;
          find.dataReject = find.dataReject.concat(item.dataReject);
        } else {
          obj.push(_d);
        }
        return obj;
      }, []);

    const dataExcelValveFilter =
      newListDataValveFilter &&
      newListDataValveFilter.map((obj) => {
        const reduceData =
          obj &&
          obj.dataReject.reduce((obj, item) => {
            let find = obj.find(
              (i) => i.id === item.id && i.produk === item.produk
            );
            let _d = {
              ...item,
            };
            if (find) {
              let findReject = Number(find.reject);
              let itemReject = Number(item.reject);

              find.id = item.id;
              find.reject = findReject + itemReject;
            } else {
              obj.push(_d);
            }
            return obj;
          }, []);

        const rejectBergeserValveFilter =
          reduceData &&
          reduceData.find((e) => (e.id === "Bergeser" ? e.reject : ""));
        const rejectBocorValveFilter =
          reduceData &&
          reduceData.find((e) => (e.id === "Bocor" ? e.reject : ""));
        const rejectCacatKosmetikValveFilter =
          reduceData &&
          reduceData.find((e) => (e.id === "Cacat Kosmetik" ? e.reject : ""));
        const rejectDeformasiValveFilter =
          reduceData &&
          reduceData.find((e) => (e.id === "Deformasi" ? e.reject : ""));
        const rejectDestruktifValveFilter =
          reduceData &&
          reduceData.find((e) => (e.id === "Destruktif" ? e.reject : ""));
        const rejectBorValveFilter =
          reduceData &&
          reduceData.find((e) => (e.id === "EX Bor" ? e.reject : ""));
        const rejectHangusValveFilter =
          reduceData &&
          reduceData.find((e) => (e.id === "Hangus" ? e.reject : ""));
        const rejectNgambangValveFilter =
          reduceData &&
          reduceData.find((e) => (e.id === "Plate Ngambang" ? e.reject : ""));
        const rejectSettinganValveFilter =
          reduceData &&
          reduceData.find((e) => (e.id === "Settingan" ? e.reject : ""));

        return {
          "Part Name": obj.produk,
          WARNA: obj.color,
          Cust: obj.customer,
          OK: formatDot(obj.jumlahOk),
          REJECT: formatDot(obj.reject),
          TOTAL: formatDot(obj.jumlahOk + obj.reject),
          "Defect %": (
            (obj.reject / (obj.jumlahOk + obj.reject)) *
            100
          ).toFixed(2),
          Bergeser:
            rejectBergeserValveFilter && rejectBergeserValveFilter.reject,
          Bocor: rejectBocorValveFilter && rejectBocorValveFilter.reject,
          "Cacat Kosmetik":
            rejectCacatKosmetikValveFilter &&
            rejectCacatKosmetikValveFilter.reject,
          Deformasi:
            rejectDeformasiValveFilter && rejectDeformasiValveFilter.reject,
          Destruktif:
            rejectDestruktifValveFilter && rejectDestruktifValveFilter.reject,
          "Ex Bor": rejectBorValveFilter && rejectBorValveFilter.reject,
          Hangus: rejectHangusValveFilter && rejectHangusValveFilter.reject,
          "Plate Ngambang":
            rejectNgambangValveFilter && rejectNgambangValveFilter.reject,
          Settingan:
            rejectSettinganValveFilter && rejectSettinganValveFilter.reject,
        };
      });

    let dataTempValveFilter = [];

    newListDataValveFilter &&
      newListDataValveFilter.map((obj) => {
        obj &&
          obj.dataReject.map((ele) => {
            dataTempValveFilter.push(ele);
          });
      });

    let reduceValveFilter =
      dataTempValveFilter &&
      dataTempValveFilter.reduce((obj, item) => {
        let find = obj.find((i) => i.id === item.id);
        let _d = {
          ...item,
        };
        if (find) {
          let findReject = Number(find.reject);
          let itemReject = Number(item.reject);
          find.id = item.id;
          find.reject = findReject + itemReject;
        } else {
          obj.push(_d);
        }
        return obj;
      }, []);

    const rejectBergeserValveFilter =
      reduceValveFilter &&
      reduceValveFilter.find((e) => (e.id === "Bergeser" ? e.reject : ""));
    const rejectBocorValveFilter =
      reduceValveFilter &&
      reduceValveFilter.find((e) => (e.id === "Bocor" ? e.reject : ""));
    const rejectCacatKosmetikValveFilter =
      reduceValveFilter &&
      reduceValveFilter.find((e) =>
        e.id === "Cacat Kosmetik" ? e.reject : ""
      );
    const rejectDeformasiValveFilter =
      reduceValveFilter &&
      reduceValveFilter.find((e) => (e.id === "Deformasi" ? e.reject : ""));
    const rejectDestruktifValveFilter =
      reduceValveFilter &&
      reduceValveFilter.find((e) => (e.id === "Destruktif" ? e.reject : ""));
    const rejectBorValveFilter =
      reduceValveFilter &&
      reduceValveFilter.find((e) => (e.id === "EX Bor" ? e.reject : ""));
    const rejectHangusValveFilter =
      reduceValveFilter &&
      reduceValveFilter.find((e) => (e.id === "Hangus" ? e.reject : ""));
    const rejectNgambangValveFilter =
      reduceValveFilter &&
      reduceValveFilter.find((e) =>
        e.id === "Plate Ngambang" ? e.reject : ""
      );
    const rejectSettinganValveFilter =
      reduceValveFilter &&
      reduceValveFilter.find((e) => (e.id === "Settingan" ? e.reject : ""));

    const JumlahValveFilter = [
      {
        "Part Name": "Total Proses Pemasangan Valve & Filter",
        WARNA: "",
        Cust: "",
        OK: formatDot(
          newListDataValveFilter.reduce((accumulator, object) => {
            return accumulator + object.jumlahOk;
          }, 0)
        ),
        REJECT: formatDot(
          newListDataValveFilter.reduce((accumulator, object) => {
            return accumulator + object.reject;
          }, 0)
        ),
        TOTAL: formatDot(
          newListDataValveFilter.reduce((accumulator, object) => {
            return accumulator + object.reject + object.jumlahOk;
          }, 0)
        ),
        "Defect %": newListDataValveFilter
          .reduce((accumulator, object) => {
            return (
              accumulator +
              (object.reject / (object.reject + object.jumlahOk)) * 100
            );
          }, 0)
          .toFixed(2),
        Bergeser: rejectBergeserValveFilter && rejectBergeserValveFilter.reject,
        Bocor: rejectBocorValveFilter && rejectBocorValveFilter.reject,
        "Cacat Kosmetik":
          rejectCacatKosmetikValveFilter &&
          rejectCacatKosmetikValveFilter.reject,
        Deformasi:
          rejectDeformasiValveFilter && rejectDeformasiValveFilter.reject,
        Destruktif:
          rejectDestruktifValveFilter && rejectDestruktifValveFilter.reject,
        "Ex Bor": rejectBorValveFilter && rejectBorValveFilter.reject,
        Hangus: rejectHangusValveFilter && rejectHangusValveFilter.reject,
        "Plate Ngambang":
          rejectNgambangValveFilter && rejectNgambangValveFilter.reject,
        Settingan:
          rejectSettinganValveFilter && rejectSettinganValveFilter.reject,
      },
    ];

    const concatValveFilter = [
      ...dataExcelValveFilter,
      {},
      ...JumlahValveFilter,
      {},
    ];

    //valve & Filter end

    //Putus Ronce Start
    const newListDataPutusRonce =
      dataPutusRonce &&
      dataPutusRonce.reduce((obj, item) => {
        let find = obj.find(
          (i) => i.produk === item.produk && i.color === item.color
        );
        let _d = {
          ...item,
        };
        if (find) {
          find.jumlahOk += item.jumlahOk;
          find.reject += item.reject;
          find.dataReject = find.dataReject.concat(item.dataReject);
        } else {
          obj.push(_d);
        }
        return obj;
      }, []);

    const dataExcelPutusRonce =
      newListDataPutusRonce &&
      newListDataPutusRonce.map((obj) => {
        const reduceData =
          obj &&
          obj.dataReject.reduce((obj, item) => {
            let find = obj.find(
              (i) => i.id === item.id && i.produk === item.produk
            );
            let _d = {
              ...item,
            };
            if (find) {
              let findReject = Number(find.reject);
              let itemReject = Number(item.reject);

              find.id = item.id;
              find.reject = findReject + itemReject;
            } else {
              obj.push(_d);
            }
            return obj;
          }, []);

        const rejectBergeserPutusRonce =
          reduceData &&
          reduceData.find((e) => (e.id === "Bergeser" ? e.reject : ""));
        const rejectBocorPutusRonce =
          reduceData &&
          reduceData.find((e) => (e.id === "Bocor" ? e.reject : ""));
        const rejectCacatKosmetikPutusRonce =
          reduceData &&
          reduceData.find((e) => (e.id === "Cacat Kosmetik" ? e.reject : ""));
        const rejectDeformasiPutusRonce =
          reduceData &&
          reduceData.find((e) => (e.id === "Deformasi" ? e.reject : ""));
        const rejectDestruktifPutusRonce =
          reduceData &&
          reduceData.find((e) => (e.id === "Destruktif" ? e.reject : ""));
        const rejectBorPutusRonce =
          reduceData &&
          reduceData.find((e) => (e.id === "EX Bor" ? e.reject : ""));
        const rejectHangusPutusRonce =
          reduceData &&
          reduceData.find((e) => (e.id === "Hangus" ? e.reject : ""));
        const rejectNgambangPutusRonce =
          reduceData &&
          reduceData.find((e) => (e.id === "Plate Ngambang" ? e.reject : ""));
        const rejectSettinganPutusRonce =
          reduceData &&
          reduceData.find((e) => (e.id === "Settingan" ? e.reject : ""));

        return {
          "Part Name": obj.produk,
          WARNA: obj.color,
          Cust: obj.customer,
          OK: formatDot(obj.jumlahOk),
          REJECT: formatDot(obj.reject),
          TOTAL: formatDot(obj.jumlahOk + obj.reject),
          "Defect %": (
            (obj.reject / (obj.jumlahOk + obj.reject)) *
            100
          ).toFixed(2),
          Bergeser: rejectBergeserPutusRonce && rejectBergeserPutusRonce.reject,
          Bocor: rejectBocorPutusRonce && rejectBocorPutusRonce.reject,
          "Cacat Kosmetik":
            rejectCacatKosmetikPutusRonce &&
            rejectCacatKosmetikPutusRonce.reject,
          Deformasi:
            rejectDeformasiPutusRonce && rejectDeformasiPutusRonce.reject,
          Destruktif:
            rejectDestruktifPutusRonce && rejectDestruktifPutusRonce.reject,
          "Ex Bor": rejectBorPutusRonce && rejectBorPutusRonce.reject,
          Hangus: rejectHangusPutusRonce && rejectHangusPutusRonce.reject,
          "Plate Ngambang":
            rejectNgambangPutusRonce && rejectNgambangPutusRonce.reject,
          Settingan:
            rejectSettinganPutusRonce && rejectSettinganPutusRonce.reject,
        };
      });

    let dataTempPutusRonce = [];

    newListDataPutusRonce &&
      newListDataPutusRonce.map((obj) => {
        obj &&
          obj.dataReject.map((ele) => {
            dataTempPutusRonce.push(ele);
          });
      });

    let reducePutusRonce =
      dataTempPutusRonce &&
      dataTempPutusRonce.reduce((obj, item) => {
        let find = obj.find((i) => i.id === item.id);
        let _d = {
          ...item,
        };
        if (find) {
          let findReject = Number(find.reject);
          let itemReject = Number(item.reject);
          find.id = item.id;
          find.reject = findReject + itemReject;
        } else {
          obj.push(_d);
        }
        return obj;
      }, []);

    const rejectBergeserPutusRonce =
      reducePutusRonce &&
      reducePutusRonce.find((e) => (e.id === "Bergeser" ? e.reject : ""));
    const rejectBocorPutusRonce =
      reducePutusRonce &&
      reducePutusRonce.find((e) => (e.id === "Bocor" ? e.reject : ""));
    const rejectCacatKosmetikPutusRonce =
      reducePutusRonce &&
      reducePutusRonce.find((e) => (e.id === "Cacat Kosmetik" ? e.reject : ""));
    const rejectDeformasiPutusRonce =
      reducePutusRonce &&
      reducePutusRonce.find((e) => (e.id === "Deformasi" ? e.reject : ""));
    const rejectDestruktifPutusRonce =
      reducePutusRonce &&
      reducePutusRonce.find((e) => (e.id === "Destruktif" ? e.reject : ""));
    const rejectBorPutusRonce =
      reducePutusRonce &&
      reducePutusRonce.find((e) => (e.id === "EX Bor" ? e.reject : ""));
    const rejectHangusPutusRonce =
      reducePutusRonce &&
      reducePutusRonce.find((e) => (e.id === "Hangus" ? e.reject : ""));
    const rejectNgambangPutusRonce =
      reducePutusRonce &&
      reducePutusRonce.find((e) => (e.id === "Plate Ngambang" ? e.reject : ""));
    const rejectSettinganPutusRonce =
      reducePutusRonce &&
      reducePutusRonce.find((e) => (e.id === "Settingan" ? e.reject : ""));

    const JumlahPutusRonce = [
      {
        "Part Name": "Total Proses Putus Ronce",
        WARNA: "",
        Cust: "",
        OK: formatDot(
          newListDataPutusRonce.reduce((accumulator, object) => {
            return accumulator + object.jumlahOk;
          }, 0)
        ),
        REJECT: formatDot(
          newListDataPutusRonce.reduce((accumulator, object) => {
            return accumulator + object.reject;
          }, 0)
        ),
        TOTAL: formatDot(
          newListDataPutusRonce.reduce((accumulator, object) => {
            return accumulator + object.reject + object.jumlahOk;
          }, 0)
        ),
        "Defect %": newListDataPutusRonce
          .reduce((accumulator, object) => {
            return (
              accumulator +
              (object.reject / (object.reject + object.jumlahOk)) * 100
            );
          }, 0)
          .toFixed(2),
        Bergeser: rejectBergeserPutusRonce && rejectBergeserPutusRonce.reject,
        Bocor: rejectBocorPutusRonce && rejectBocorPutusRonce.reject,
        "Cacat Kosmetik":
          rejectCacatKosmetikPutusRonce && rejectCacatKosmetikPutusRonce.reject,
        Deformasi:
          rejectDeformasiPutusRonce && rejectDeformasiPutusRonce.reject,
        Destruktif:
          rejectDestruktifPutusRonce && rejectDestruktifPutusRonce.reject,
        "Ex Bor": rejectBorPutusRonce && rejectBorPutusRonce.reject,
        Hangus: rejectHangusPutusRonce && rejectHangusPutusRonce.reject,
        "Plate Ngambang":
          rejectNgambangPutusRonce && rejectNgambangPutusRonce.reject,
        Settingan:
          rejectSettinganPutusRonce && rejectSettinganPutusRonce.reject,
      },
    ];

    const concatPutusRonce = [
      ...dataExcelPutusRonce,
      {},
      ...JumlahPutusRonce,
      {},
    ];

    //Putus Ronce end

    //Sortir Start
    const newListDataSortir =
      dataSortir &&
      dataSortir.reduce((obj, item) => {
        let find = obj.find(
          (i) => i.produk === item.produk && i.color === item.color
        );
        let _d = {
          ...item,
        };
        if (find) {
          find.jumlahOk += item.jumlahOk;
          find.reject += item.reject;
          find.dataReject = find.dataReject.concat(item.dataReject);
        } else {
          obj.push(_d);
        }
        return obj;
      }, []);

    const dataExcelSortir =
      newListDataSortir &&
      newListDataSortir.map((obj) => {
        const reduceData =
          obj &&
          obj.dataReject.reduce((obj, item) => {
            let find = obj.find(
              (i) => i.id === item.id && i.produk === item.produk
            );
            let _d = {
              ...item,
            };
            if (find) {
              let findReject = Number(find.reject);
              let itemReject = Number(item.reject);

              find.id = item.id;
              find.reject = findReject + itemReject;
            } else {
              obj.push(_d);
            }
            return obj;
          }, []);

        const rejectBergeserSortir =
          reduceData &&
          reduceData.find((e) => (e.id === "Bergeser" ? e.reject : ""));
        const rejectBocorSortir =
          reduceData &&
          reduceData.find((e) => (e.id === "Bocor" ? e.reject : ""));
        const rejectCacatKosmetikSortir =
          reduceData &&
          reduceData.find((e) => (e.id === "Cacat Kosmetik" ? e.reject : ""));
        const rejectDeformasiSortir =
          reduceData &&
          reduceData.find((e) => (e.id === "Deformasi" ? e.reject : ""));
        const rejectDestruktifSortir =
          reduceData &&
          reduceData.find((e) => (e.id === "Destruktif" ? e.reject : ""));
        const rejectBorSortir =
          reduceData &&
          reduceData.find((e) => (e.id === "EX Bor" ? e.reject : ""));
        const rejectHangusSortir =
          reduceData &&
          reduceData.find((e) => (e.id === "Hangus" ? e.reject : ""));
        const rejectNgambangSortir =
          reduceData &&
          reduceData.find((e) => (e.id === "Plate Ngambang" ? e.reject : ""));
        const rejectSettinganSortir =
          reduceData &&
          reduceData.find((e) => (e.id === "Settingan" ? e.reject : ""));

        return {
          "Part Name": obj.produk,
          WARNA: obj.color,
          Cust: obj.customer,
          OK: formatDot(obj.jumlahOk),
          REJECT: formatDot(obj.reject),
          TOTAL: formatDot(obj.jumlahOk + obj.reject),
          "Defect %": (
            (obj.reject / (obj.jumlahOk + obj.reject)) *
            100
          ).toFixed(2),
          Bergeser: rejectBergeserSortir && rejectBergeserSortir.reject,
          Bocor: rejectBocorSortir && rejectBocorSortir.reject,
          "Cacat Kosmetik":
            rejectCacatKosmetikSortir && rejectCacatKosmetikSortir.reject,
          Deformasi: rejectDeformasiSortir && rejectDeformasiSortir.reject,
          Destruktif: rejectDestruktifSortir && rejectDestruktifSortir.reject,
          "Ex Bor": rejectBorSortir && rejectBorSortir.reject,
          Hangus: rejectHangusSortir && rejectHangusSortir.reject,
          "Plate Ngambang": rejectNgambangSortir && rejectNgambangSortir.reject,
          Settingan: rejectSettinganSortir && rejectSettinganSortir.reject,
        };
      });

    let dataTempSortir = [];

    newListDataSortir &&
      newListDataSortir.map((obj) => {
        obj &&
          obj.dataReject.map((ele) => {
            dataTempSortir.push(ele);
          });
      });

    let reduceSortir =
      dataTempSortir &&
      dataTempSortir.reduce((obj, item) => {
        let find = obj.find((i) => i.id === item.id);
        let _d = {
          ...item,
        };
        if (find) {
          let findReject = Number(find.reject);
          let itemReject = Number(item.reject);
          find.id = item.id;
          find.reject = findReject + itemReject;
        } else {
          obj.push(_d);
        }
        return obj;
      }, []);

    const rejectBergeserSortir =
      reduceSortir &&
      reduceSortir.find((e) => (e.id === "Bergeser" ? e.reject : ""));
    const rejectBocorSortir =
      reduceSortir &&
      reduceSortir.find((e) => (e.id === "Bocor" ? e.reject : ""));
    const rejectCacatKosmetikSortir =
      reduceSortir &&
      reduceSortir.find((e) => (e.id === "Cacat Kosmetik" ? e.reject : ""));
    const rejectDeformasiSortir =
      reduceSortir &&
      reduceSortir.find((e) => (e.id === "Deformasi" ? e.reject : ""));
    const rejectDestruktifSortir =
      reduceSortir &&
      reduceSortir.find((e) => (e.id === "Destruktif" ? e.reject : ""));
    const rejectBorSortir =
      reduceSortir &&
      reduceSortir.find((e) => (e.id === "EX Bor" ? e.reject : ""));
    const rejectHangusSortir =
      reduceSortir &&
      reduceSortir.find((e) => (e.id === "Hangus" ? e.reject : ""));
    const rejectNgambangSortir =
      reduceSortir &&
      reduceSortir.find((e) => (e.id === "Plate Ngambang" ? e.reject : ""));
    const rejectSettinganSortir =
      reduceSortir &&
      reduceSortir.find((e) => (e.id === "Settingan" ? e.reject : ""));

    const JumlahSortir = [
      {
        "Part Name": "Total Proses Sortir",
        WARNA: "",
        Cust: "",
        OK: formatDot(
          newListDataSortir.reduce((accumulator, object) => {
            return accumulator + object.jumlahOk;
          }, 0)
        ),
        REJECT: formatDot(
          newListDataSortir.reduce((accumulator, object) => {
            return accumulator + object.reject;
          }, 0)
        ),
        TOTAL: formatDot(
          newListDataSortir.reduce((accumulator, object) => {
            return accumulator + object.reject + object.jumlahOk;
          }, 0)
        ),
        "Defect %": newListDataSortir
          .reduce((accumulator, object) => {
            return (
              accumulator +
              (object.reject / (object.reject + object.jumlahOk)) * 100
            );
          }, 0)
          .toFixed(2),
        Bergeser: rejectBergeserSortir && rejectBergeserSortir.reject,
        Bocor: rejectBocorSortir && rejectBocorSortir.reject,
        "Cacat Kosmetik":
          rejectCacatKosmetikSortir && rejectCacatKosmetikSortir.reject,
        Deformasi: rejectDeformasiSortir && rejectDeformasiSortir.reject,
        Destruktif: rejectDestruktifSortir && rejectDestruktifSortir.reject,
        "Ex Bor": rejectBorSortir && rejectBorSortir.reject,
        Hangus: rejectHangusSortir && rejectHangusSortir.reject,
        "Plate Ngambang": rejectNgambangSortir && rejectNgambangSortir.reject,
        Settingan: rejectSettinganSortir && rejectSettinganSortir.reject,
      },
    ];

    const concatSortir = [...dataExcelSortir, {}, ...JumlahSortir, {}];

    //Sortir End

    //Test Bocor Start
    const newListDataTestBocor =
      dataTestBocor &&
      dataTestBocor.reduce((obj, item) => {
        let find = obj.find(
          (i) => i.produk === item.produk && i.color === item.color
        );
        let _d = {
          ...item,
        };
        if (find) {
          find.jumlahOk += item.jumlahOk;
          find.reject += item.reject;
          find.dataReject = find.dataReject.concat(item.dataReject);
        } else {
          obj.push(_d);
        }
        return obj;
      }, []);

    const dataExcelTestBocor =
      newListDataTestBocor &&
      newListDataTestBocor.map((obj) => {
        const reduceData =
          obj &&
          obj.dataReject.reduce((obj, item) => {
            let find = obj.find(
              (i) => i.id === item.id && i.produk === item.produk
            );
            let _d = {
              ...item,
            };
            if (find) {
              let findReject = Number(find.reject);
              let itemReject = Number(item.reject);

              find.id = item.id;
              find.reject = findReject + itemReject;
            } else {
              obj.push(_d);
            }
            return obj;
          }, []);

        const rejectBergeserTestBocor =
          reduceData &&
          reduceData.find((e) => (e.id === "Bergeser" ? e.reject : ""));
        const rejectBocorTestBocor =
          reduceData &&
          reduceData.find((e) => (e.id === "Bocor" ? e.reject : ""));
        const rejectCacatKosmetikTestBocor =
          reduceData &&
          reduceData.find((e) => (e.id === "Cacat Kosmetik" ? e.reject : ""));
        const rejectDeformasiTestBocor =
          reduceData &&
          reduceData.find((e) => (e.id === "Deformasi" ? e.reject : ""));
        const rejectDestruktifTestBocor =
          reduceData &&
          reduceData.find((e) => (e.id === "Destruktif" ? e.reject : ""));
        const rejectBorTestBocor =
          reduceData &&
          reduceData.find((e) => (e.id === "EX Bor" ? e.reject : ""));
        const rejectHangusTestBocor =
          reduceData &&
          reduceData.find((e) => (e.id === "Hangus" ? e.reject : ""));
        const rejectNgambangTestBocor =
          reduceData &&
          reduceData.find((e) => (e.id === "Plate Ngambang" ? e.reject : ""));
        const rejectSettinganTestBocor =
          reduceData &&
          reduceData.find((e) => (e.id === "Settingan" ? e.reject : ""));

        return {
          "Part Name": obj.produk,
          WARNA: obj.color,
          Cust: obj.customer,
          OK: formatDot(obj.jumlahOk),
          REJECT: formatDot(obj.reject),
          TOTAL: formatDot(obj.jumlahOk + obj.reject),
          "Defect %": (
            (obj.reject / (obj.jumlahOk + obj.reject)) *
            100
          ).toFixed(2),
          Bergeser: rejectBergeserTestBocor && rejectBergeserTestBocor.reject,
          Bocor: rejectBocorTestBocor && rejectBocorTestBocor.reject,
          "Cacat Kosmetik":
            rejectCacatKosmetikTestBocor && rejectCacatKosmetikTestBocor.reject,
          Deformasi:
            rejectDeformasiTestBocor && rejectDeformasiTestBocor.reject,
          Destruktif:
            rejectDestruktifTestBocor && rejectDestruktifTestBocor.reject,
          "Ex Bor": rejectBorTestBocor && rejectBorTestBocor.reject,
          Hangus: rejectHangusTestBocor && rejectHangusTestBocor.reject,
          "Plate Ngambang":
            rejectNgambangTestBocor && rejectNgambangTestBocor.reject,
          Settingan:
            rejectSettinganTestBocor && rejectSettinganTestBocor.reject,
        };
      });

    let dataTempTestBocor = [];

    newListDataTestBocor &&
      newListDataTestBocor.map((obj) => {
        obj &&
          obj.dataReject.map((ele) => {
            dataTempTestBocor.push(ele);
          });
      });

    let reduceTestBocor =
      dataTempTestBocor &&
      dataTempTestBocor.reduce((obj, item) => {
        let find = obj.find((i) => i.id === item.id);
        let _d = {
          ...item,
        };
        if (find) {
          let findReject = Number(find.reject);
          let itemReject = Number(item.reject);
          find.id = item.id;
          find.reject = findReject + itemReject;
        } else {
          obj.push(_d);
        }
        return obj;
      }, []);

    const rejectBergeserTestBocor =
      reduceTestBocor &&
      reduceTestBocor.find((e) => (e.id === "Bergeser" ? e.reject : ""));
    const rejectBocorTestBocor =
      reduceTestBocor &&
      reduceTestBocor.find((e) => (e.id === "Bocor" ? e.reject : ""));
    const rejectCacatKosmetikTestBocor =
      reduceTestBocor &&
      reduceTestBocor.find((e) => (e.id === "Cacat Kosmetik" ? e.reject : ""));
    const rejectDeformasiTestBocor =
      reduceTestBocor &&
      reduceTestBocor.find((e) => (e.id === "Deformasi" ? e.reject : ""));
    const rejectDestruktifTestBocor =
      reduceTestBocor &&
      reduceTestBocor.find((e) => (e.id === "Destruktif" ? e.reject : ""));
    const rejectBorTestBocor =
      reduceTestBocor &&
      reduceTestBocor.find((e) => (e.id === "EX Bor" ? e.reject : ""));
    const rejectHangusTestBocor =
      reduceTestBocor &&
      reduceTestBocor.find((e) => (e.id === "Hangus" ? e.reject : ""));
    const rejectNgambangTestBocor =
      reduceTestBocor &&
      reduceTestBocor.find((e) => (e.id === "Plate Ngambang" ? e.reject : ""));
    const rejectSettinganTestBocor =
      reduceTestBocor &&
      reduceTestBocor.find((e) => (e.id === "Settingan" ? e.reject : ""));

    const JumlahTestBocor = [
      {
        "Part Name": "Total Proses Test Bocor",
        WARNA: "",
        Cust: "",
        OK: formatDot(
          newListDataTestBocor.reduce((accumulator, object) => {
            return accumulator + object.jumlahOk;
          }, 0)
        ),
        REJECT: formatDot(
          newListDataTestBocor.reduce((accumulator, object) => {
            return accumulator + object.reject;
          }, 0)
        ),
        TOTAL: formatDot(
          newListDataTestBocor.reduce((accumulator, object) => {
            return accumulator + object.reject + object.jumlahOk;
          }, 0)
        ),
        "Defect %": newListDataTestBocor
          .reduce((accumulator, object) => {
            return (
              accumulator +
              (object.reject / (object.reject + object.jumlahOk)) * 100
            );
          }, 0)
          .toFixed(2),
        Bergeser: rejectBergeserTestBocor && rejectBergeserTestBocor.reject,
        Bocor: rejectBocorTestBocor && rejectBocorTestBocor.reject,
        "Cacat Kosmetik":
          rejectCacatKosmetikTestBocor && rejectCacatKosmetikTestBocor.reject,
        Deformasi: rejectDeformasiTestBocor && rejectDeformasiTestBocor.reject,
        Destruktif:
          rejectDestruktifTestBocor && rejectDestruktifTestBocor.reject,
        "Ex Bor": rejectBorTestBocor && rejectBorTestBocor.reject,
        Hangus: rejectHangusTestBocor && rejectHangusTestBocor.reject,
        "Plate Ngambang":
          rejectNgambangTestBocor && rejectNgambangTestBocor.reject,
        Settingan: rejectSettinganTestBocor && rejectSettinganTestBocor.reject,
      },
    ];

    const concatTestBocor = [...dataExcelTestBocor, {}, ...JumlahTestBocor, {}];

    //Test Bocor End

    //Ultrasonic Start
    const newListDataUltrasonic =
      dataUltrasonic &&
      dataUltrasonic.reduce((obj, item) => {
        let find = obj.find(
          (i) => i.produk === item.produk && i.color === item.color
        );
        let _d = {
          ...item,
        };
        if (find) {
          find.jumlahOk += item.jumlahOk;
          find.reject += item.reject;
          find.dataReject = find.dataReject.concat(item.dataReject);
        } else {
          obj.push(_d);
        }
        return obj;
      }, []);

    const dataExcelUltrasonic =
      newListDataUltrasonic &&
      newListDataUltrasonic.map((obj) => {
        const reduceData =
          obj &&
          obj.dataReject.reduce((obj, item) => {
            let find = obj.find(
              (i) => i.id === item.id && i.produk === item.produk
            );
            let _d = {
              ...item,
            };
            if (find) {
              let findReject = Number(find.reject);
              let itemReject = Number(item.reject);

              find.id = item.id;
              find.reject = findReject + itemReject;
            } else {
              obj.push(_d);
            }
            return obj;
          }, []);

        const rejectBergeserUltrasonic =
          reduceData &&
          reduceData.find((e) => (e.id === "Bergeser" ? e.reject : ""));
        const rejectBocorUltrasonic =
          reduceData &&
          reduceData.find((e) => (e.id === "Bocor" ? e.reject : ""));
        const rejectCacatKosmetikUltrasonic =
          reduceData &&
          reduceData.find((e) => (e.id === "Cacat Kosmetik" ? e.reject : ""));
        const rejectDeformasiUltrasonic =
          reduceData &&
          reduceData.find((e) => (e.id === "Deformasi" ? e.reject : ""));
        const rejectDestruktifUltrasonic =
          reduceData &&
          reduceData.find((e) => (e.id === "Destruktif" ? e.reject : ""));
        const rejectBorUltrasonic =
          reduceData &&
          reduceData.find((e) => (e.id === "EX Bor" ? e.reject : ""));
        const rejectHangusUltrasonic =
          reduceData &&
          reduceData.find((e) => (e.id === "Hangus" ? e.reject : ""));
        const rejectNgambangUltrasonic =
          reduceData &&
          reduceData.find((e) => (e.id === "Plate Ngambang" ? e.reject : ""));
        const rejectSettinganUltrasonic =
          reduceData &&
          reduceData.find((e) => (e.id === "Settingan" ? e.reject : ""));

        return {
          "Part Name": obj.produk,
          WARNA: obj.color,
          Cust: obj.customer,
          OK: formatDot(obj.jumlahOk),
          REJECT: formatDot(obj.reject),
          TOTAL: formatDot(obj.jumlahOk + obj.reject),
          "Defect %": (
            (obj.reject / (obj.jumlahOk + obj.reject)) *
            100
          ).toFixed(2),
          Bergeser: rejectBergeserUltrasonic && rejectBergeserUltrasonic.reject,
          Bocor: rejectBocorUltrasonic && rejectBocorUltrasonic.reject,
          "Cacat Kosmetik":
            rejectCacatKosmetikUltrasonic &&
            rejectCacatKosmetikUltrasonic.reject,
          Deformasi:
            rejectDeformasiUltrasonic && rejectDeformasiUltrasonic.reject,
          Destruktif:
            rejectDestruktifUltrasonic && rejectDestruktifUltrasonic.reject,
          "Ex Bor": rejectBorUltrasonic && rejectBorUltrasonic.reject,
          Hangus: rejectHangusUltrasonic && rejectHangusUltrasonic.reject,
          "Plate Ngambang":
            rejectNgambangUltrasonic && rejectNgambangUltrasonic.reject,
          Settingan:
            rejectSettinganUltrasonic && rejectSettinganUltrasonic.reject,
        };
      });

    let dataTempUltrasonic = [];

    newListDataUltrasonic &&
      newListDataUltrasonic.map((obj) => {
        obj &&
          obj.dataReject.map((ele) => {
            dataTempUltrasonic.push(ele);
          });
      });

    let reduceUltrasonic =
      dataTempUltrasonic &&
      dataTempUltrasonic.reduce((obj, item) => {
        let find = obj.find((i) => i.id === item.id);
        let _d = {
          ...item,
        };
        if (find) {
          let findReject = Number(find.reject);
          let itemReject = Number(item.reject);
          find.id = item.id;
          find.reject = findReject + itemReject;
        } else {
          obj.push(_d);
        }
        return obj;
      }, []);

    const rejectBergeserUltrasonic =
      reduceUltrasonic &&
      reduceUltrasonic.find((e) => (e.id === "Bergeser" ? e.reject : ""));
    const rejectBocorUltrasonic =
      reduceUltrasonic &&
      reduceUltrasonic.find((e) => (e.id === "Bocor" ? e.reject : ""));
    const rejectCacatKosmetikUltrasonic =
      reduceUltrasonic &&
      reduceUltrasonic.find((e) => (e.id === "Cacat Kosmetik" ? e.reject : ""));
    const rejectDeformasiUltrasonic =
      reduceUltrasonic &&
      reduceUltrasonic.find((e) => (e.id === "Deformasi" ? e.reject : ""));
    const rejectDestruktifUltrasonic =
      reduceUltrasonic &&
      reduceUltrasonic.find((e) => (e.id === "Destruktif" ? e.reject : ""));
    const rejectBorUltrasonic =
      reduceUltrasonic &&
      reduceUltrasonic.find((e) => (e.id === "EX Bor" ? e.reject : ""));
    const rejectHangusUltrasonic =
      reduceUltrasonic &&
      reduceUltrasonic.find((e) => (e.id === "Hangus" ? e.reject : ""));
    const rejectNgambangUltrasonic =
      reduceUltrasonic &&
      reduceUltrasonic.find((e) => (e.id === "Plate Ngambang" ? e.reject : ""));
    const rejectSettinganUltrasonic =
      reduceUltrasonic &&
      reduceUltrasonic.find((e) => (e.id === "Settingan" ? e.reject : ""));

    const JumlahUltrasonic = [
      {
        "Part Name": "Total Proses Ultrasonic",
        WARNA: "",
        Cust: "",
        OK: formatDot(
          newListDataUltrasonic.reduce((accumulator, object) => {
            return accumulator + object.jumlahOk;
          }, 0)
        ),
        REJECT: formatDot(
          newListDataUltrasonic.reduce((accumulator, object) => {
            return accumulator + object.reject;
          }, 0)
        ),
        TOTAL: formatDot(
          newListDataUltrasonic.reduce((accumulator, object) => {
            return accumulator + object.reject + object.jumlahOk;
          }, 0)
        ),
        "Defect %": newListDataUltrasonic
          .reduce((accumulator, object) => {
            return (
              accumulator +
              (object.reject / (object.reject + object.jumlahOk)) * 100
            );
          }, 0)
          .toFixed(2),
        Bergeser: rejectBergeserUltrasonic && rejectBergeserUltrasonic.reject,
        Bocor: rejectBocorUltrasonic && rejectBocorUltrasonic.reject,
        "Cacat Kosmetik":
          rejectCacatKosmetikUltrasonic && rejectCacatKosmetikUltrasonic.reject,
        Deformasi:
          rejectDeformasiUltrasonic && rejectDeformasiUltrasonic.reject,
        Destruktif:
          rejectDestruktifUltrasonic && rejectDestruktifUltrasonic.reject,
        "Ex Bor": rejectBorUltrasonic && rejectBorUltrasonic.reject,
        Hangus: rejectHangusUltrasonic && rejectHangusUltrasonic.reject,
        "Plate Ngambang":
          rejectNgambangUltrasonic && rejectNgambangUltrasonic.reject,
        Settingan:
          rejectSettinganUltrasonic && rejectSettinganUltrasonic.reject,
      },
    ];

    const concatUltrasonic = [
      ...dataExcelUltrasonic,
      {},
      ...JumlahUltrasonic,
      {},
    ];
    //Ultrasonic End

    const newListDataPrint =
      dataPrint &&
      dataPrint.reduce((obj, item) => {
        let find = obj.find(
          (i) => i.produk === item.produk && i.color === item.color
        );
        let _d = {
          ...item,
        };
        if (find) {
          find.jumlahOk += item.jumlahOk;
          find.reject += item.reject;
          find.repair += item.repair;
        } else {
          obj.push(_d);
        }
        return obj;
      }, []);

    const newListDataPasangSticker =
      dataPasangSticker &&
      dataPasangSticker.reduce((obj, item) => {
        let find = obj.find(
          (i) => i.produk === item.produk && i.color === item.color
        );
        let _d = {
          ...item,
        };
        if (find) {
          find.jumlahOk += item.jumlahOk;
          find.reject += item.reject;
          find.repair += item.repair;
        } else {
          obj.push(_d);
        }
        return obj;
      }, []);

    const newListDataPunching =
      dataPunching &&
      dataPunching.reduce((obj, item) => {
        let find = obj.find(
          (i) => i.produk === item.produk && i.color === item.color
        );
        let _d = {
          ...item,
        };
        if (find) {
          find.jumlahOk += item.jumlahOk;
          find.reject += item.reject;
          find.repair += item.repair;
        } else {
          obj.push(_d);
        }
        return obj;
      }, []);

    const dataExcelPrint =
      newListDataPrint &&
      newListDataPrint.map((obj) => {
        return {
          "Part Name": obj.produk,
          WARNA: obj.color,
          Cust: obj.customer,
          OK: formatDot(obj.jumlahOk),
          REJECT: formatDot(obj.reject),
          TOTAL: formatDot(obj.jumlahOk + obj.reject),
          "Defect %": (
            (obj.reject / (obj.jumlahOk + obj.reject)) *
            100
          ).toFixed(2),
          "Repair Printing": obj.repair,
          "% Repair": (
            (obj.repair / (obj.jumlahOk + obj.repair)) *
            100
          ).toFixed(2),
          "X-Pasang Sticker": "",
          "X-Punching": "",
        };
      });

    const JumlahPrint = [
      {
        "Part Name": "Total Proses Printing",
        WARNA: "",
        Cust: "",
        OK: formatDot(
          newListDataPrint.reduce((accumulator, object) => {
            return accumulator + object.jumlahOk;
          }, 0)
        ),
        REJECT: formatDot(
          newListDataPrint.reduce((accumulator, object) => {
            return accumulator + object.reject;
          }, 0)
        ),
        TOTAL: formatDot(
          newListDataPrint.reduce((accumulator, object) => {
            return accumulator + object.reject + object.jumlahOk;
          }, 0)
        ),
        "Defect %": newListDataPrint
          .reduce((accumulator, object) => {
            return (
              accumulator +
              (object.reject / (object.reject + object.jumlahOk)) * 100
            );
          }, 0)
          .toFixed(2),
        "Repair Printing": formatDot(
          newListDataPrint.reduce((accumulator, object) => {
            return accumulator + object.repair;
          }, 0)
        ),
        "% Repair": formatDot(
          newListDataPrint
            .reduce((accumulator, object) => {
              return (
                accumulator +
                ((object.repair / (object.repair + object.jumlahOk)) * 100) /
                  newListDataPrint.length
              );
            }, 0)
            .toFixed(2)
        ),
        "X-Pasang Sticker": "",
        "X-Punching": "",
      },
    ];
    const concatPrint = [...dataExcelPrint, {}, ...JumlahPrint, {}];

    const dataExcelPasangSticker =
      newListDataPasangSticker &&
      newListDataPasangSticker.map((obj) => {
        return {
          "Part Name": obj.produk,
          WARNA: obj.color,
          Cust: obj.customer,
          OK: formatDot(obj.jumlahOk),
          REJECT: formatDot(obj.reject),
          TOTAL: formatDot(obj.jumlahOk + obj.reject),
          "Defect %": (
            (obj.reject / (obj.jumlahOk + obj.reject)) *
            100
          ).toFixed(2),
          "Repair Printing": "",
          "X-Pasang Sticker": obj.reject,
          "X-Punching": "",
        };
      });

    const JumlahPasangSticker = [
      {
        "Part Name": "Total Proses Pasang Sticker",
        WARNA: "",
        Cust: "",
        OK: formatDot(
          newListDataPasangSticker.reduce((accumulator, object) => {
            return accumulator + object.jumlahOk;
          }, 0)
        ),
        REJECT: formatDot(
          newListDataPasangSticker.reduce((accumulator, object) => {
            return accumulator + object.reject;
          }, 0)
        ),
        TOTAL: formatDot(
          newListDataPasangSticker.reduce((accumulator, object) => {
            return accumulator + object.reject + object.jumlahOk;
          }, 0)
        ),
        "Defect %": newListDataPasangSticker
          .reduce((accumulator, object) => {
            return (
              accumulator +
              (object.reject / (object.reject + object.jumlahOk)) * 100
            );
          }, 0)
          .toFixed(2),
        "Repair Printing": "",
        "X-Pasang Sticker": formatDot(
          newListDataPasangSticker.reduce((accumulator, object) => {
            return accumulator + object.reject;
          }, 0)
        ),
        "X-Punching": "",
      },
    ];
    const concatPasangSticker = [
      ...dataExcelPasangSticker,
      {},
      ...JumlahPasangSticker,
      {},
    ];

    const dataExcelPunching =
      newListDataPunching &&
      newListDataPunching.map((obj) => {
        return {
          "Part Name": obj.produk,
          WARNA: obj.color,
          Cust: obj.customer,
          OK: formatDot(obj.jumlahOk),
          REJECT: formatDot(obj.reject),
          TOTAL: formatDot(obj.jumlahOk + obj.reject),
          "Defect %": (
            (obj.reject / (obj.jumlahOk + obj.reject)) *
            100
          ).toFixed(2),
          "Repair Printing": "",
          "X-Pasang Sticker": "",
          "X-Punching": obj.reject,
        };
      });

    const JumlahPunching = [
      {
        "Part Name": "Total Proses Punching",
        WARNA: "",
        Cust: "",
        OK: formatDot(
          newListDataPunching.reduce((accumulator, object) => {
            return accumulator + object.jumlahOk;
          }, 0)
        ),
        REJECT: formatDot(
          newListDataPunching.reduce((accumulator, object) => {
            return accumulator + object.reject;
          }, 0)
        ),
        TOTAL: formatDot(
          newListDataPunching.reduce((accumulator, object) => {
            return accumulator + object.reject + object.jumlahOk;
          }, 0)
        ),
        "Defect %": newListDataPunching
          .reduce((accumulator, object) => {
            return (
              accumulator +
              (object.reject / (object.reject + object.jumlahOk)) * 100
            );
          }, 0)
          .toFixed(2),
        "Repair Printing": "",
        "X-Pasang Sticker": "",
        "X-Punching": formatDot(
          newListDataPunching.reduce((accumulator, object) => {
            return accumulator + object.reject;
          }, 0)
        ),
      },
    ];

    const dataGrand = newListDataPrint.concat(
      newListDataPasangSticker,
      newListDataPunching
    );

    const GrandTotal = [
      {
        "Part Name": "Grand Total Printing",
        WARNA: "",
        Cust: "",
        OK: formatDot(
          dataGrand.reduce((accumulator, object) => {
            return accumulator + object.jumlahOk;
          }, 0)
        ),
        REJECT: formatDot(
          dataGrand.reduce((accumulator, object) => {
            return accumulator + object.reject;
          }, 0)
        ),
        TOTAL: formatDot(
          dataGrand.reduce((accumulator, object) => {
            return accumulator + object.reject + object.jumlahOk;
          }, 0)
        ),
        "Defect %": dataGrand
          .reduce((accumulator, object) => {
            return (
              accumulator +
              (object.reject / (object.reject + object.jumlahOk)) * 100
            );
          }, 0)
          .toFixed(2),
        "Repair Printing": formatDot(
          newListDataPrint.reduce((accumulator, object) => {
            return accumulator + object.repair;
          }, 0)
        ),
        "% Repair": formatDot(
          newListDataPrint
            .reduce((accumulator, object) => {
              return (
                accumulator +
                ((object.repair / (object.repair + object.jumlahOk)) * 100) /
                  newListDataPrint.length
              );
            }, 0)
            .toFixed(2)
        ),
        "X-Pasang Sticker": formatDot(
          newListDataPasangSticker.reduce((accumulator, object) => {
            return accumulator + object.reject;
          }, 0)
        ),
        "X-Punching": formatDot(
          newListDataPunching.reduce((accumulator, object) => {
            return accumulator + object.reject;
          }, 0)
        ),
      },
    ];

    const dataGrandFinishing = [
      ...newListDataFillingPlug,
      ...newListDataHandle,
      ...newListDataBnw,
      ...newListDataValveFilter,
      ...newListDataPutusRonce,
      ...newListDataSortir,
      ...newListDataTestBocor,
      ...newListDataUltrasonic,
    ];

    const mergeGrandReject = [
      ...dataTempFilling,
      ...dataTempHandle,
      ...dataTempBnw,
      ...dataTempValveFilter,
      ...dataTempPutusRonce,
      ...dataTempSortir,
      ...dataTempTestBocor,
      ...dataTempUltrasonic,
    ];

    let reduceGrandReject =
      mergeGrandReject &&
      mergeGrandReject.reduce((obj, item) => {
        let find = obj.find((i) => i.id === item.id);
        let _d = {
          ...item,
        };
        if (find) {
          let findReject = Number(find.reject);
          let itemReject = Number(item.reject);
          find.id = item.id;
          find.reject = findReject + itemReject;
        } else {
          obj.push(_d);
        }
        return obj;
      }, []);

    const grandBergeser = reduceGrandReject.find((e) =>
      e.id === "Bergeser" ? e.reject : ""
    );
    const grandBocor = reduceGrandReject.find((e) =>
      e.id === "Bocor" ? e.reject : ""
    );
    const grandCacatKosmetik = reduceGrandReject.find((e) =>
      e.id === "Cacat Kosmetik" ? e.reject : ""
    );
    const grandDeformasi = reduceGrandReject.find((e) =>
      e.id === "Deformasi" ? e.reject : ""
    );
    const grandDestruktif = reduceGrandReject.find((e) =>
      e.id === "Destruktif" ? e.reject : ""
    );
    const grandEXBor = reduceGrandReject.find((e) =>
      e.id === "EX Bor" ? e.reject : ""
    );
    const grandHangus = reduceGrandReject.find((e) =>
      e.id === "Hangus" ? e.reject : ""
    );
    const grandPlateNgambang = reduceGrandReject.find((e) =>
      e.id === "Plate Ngambang" ? e.reject : ""
    );
    const grandSettingan = reduceGrandReject.find((e) =>
      e.id === "Settingan" ? e.reject : ""
    );

    const GrandTotalFinishing = [
      {
        "Part Name": "Grand Total Finishing",
        WARNA: "",
        Cust: "",
        OK: formatDot(
          dataGrandFinishing.reduce((accumulator, object) => {
            return accumulator + object.jumlahOk;
          }, 0)
        ),
        REJECT: formatDot(
          dataGrandFinishing.reduce((accumulator, object) => {
            return accumulator + object.reject;
          }, 0)
        ),
        TOTAL: formatDot(
          dataGrandFinishing.reduce((accumulator, object) => {
            return accumulator + object.reject + object.jumlahOk;
          }, 0)
        ),
        "Defect %": dataGrandFinishing
          .reduce((accumulator, object) => {
            return (
              accumulator +
              (object.reject / (object.reject + object.jumlahOk)) * 100
            );
          }, 0)
          .toFixed(2),
        "Repair Printing": "",
        "X-Pasang Sticker": "",
        "X-Punching": "",
        Bergeser: grandBergeser && grandBergeser.reject,
        Bocor: grandBocor && grandBocor.reject,
        "Cacat Kosmetik": grandCacatKosmetik && grandCacatKosmetik.reject,
        Deformasi: grandDeformasi && grandDeformasi.reject,
        Destruktif: grandDestruktif && grandDestruktif.reject,
        "EX Bor": grandEXBor && grandEXBor.reject,
        Hangus: grandHangus && grandHangus.reject,
        "Plate Ngambang": grandPlateNgambang && grandPlateNgambang.reject,
        Settingan: grandSettingan && grandSettingan.reject,
      },
    ];

    const concatPunching = [
      ...dataExcelPunching,
      {},
      ...JumlahPunching,
      {},
      ...GrandTotal,
      {},
    ];

    const workSheet = XLSX.utils.json_to_sheet([
      ...concatPrint,
      ...concatPasangSticker,
      ...concatPunching,
      ...concatFilling,
      ...concatHandle,
      ...concatBnw,
      ...concatValveFilter,
      ...concatPutusRonce,
      ...concatSortir,
      ...concatTestBocor,
      ...concatUltrasonic,
      {},
      ...GrandTotalFinishing,
    ]);

    workSheet["!cols"] = [
      { wch: 35 },
      { wch: 15 },
      { wch: 6 },
      { wch: 10 },
      { wch: 8 },
      { wch: 10 },
      { wch: 8 },
      { wch: 13 },
      { wch: 14 },
      { wch: 9 },
      { wch: 8 },
      { wch: 8 },
      { wch: 13 },
      { wch: 9 },
      { wch: 9 },
      { wch: 8 },
      { wch: 8 },
      { wch: 14 },
      { wch: 8 },
      { wch: 8 },
    ];

    workSheet["!rows"] = [{ hpt: 30 }];

    const workBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workBook, workSheet, "Laporan Bulanan");
    XLSX.write(workBook, { bookType: "xlsx", type: "buffer" });
    XLSX.write(workBook, { bookType: "xlsx", type: "binary" });
    XLSX.writeFile(
      workBook,
      `Laporan SP Bulan ${bulanExportSP} ${tahunExportSP}.xlsx`
    );

    setTahunExportSP("Pilih Tahun");
    setBulanExportSP("Pilih Bulan");
    setPopUpLaporanBulananSP(false);
    window.location.reload();
  };

  const searchFeature = () => {
    if (filterBagian !== "Pilih Bagian" || filterProduk !== "Pilih Produk") {
      let searchName = [];
      laporanSP &&
        laporanSP.forEach((value) => {
          if (
            value.bagian === filterBagian &&
            filterProduk === "Pilih Produk"
          ) {
            searchName.push(value);
          } else if (
            value.produk === filterProduk &&
            filterBagian === "Pilih Bagian"
          ) {
            searchName.push(value);
          } else if (
            value.bagian === filterBagian &&
            value.produk === filterProduk
          ) {
            searchName.push(value);
          }
        });
      return searchName;
    } else {
      return laporanSP && laporanSP;
    }
  };
  let dataTemp = searchFeature(filterBagian);

  const handleReset = () => {
    setFilterBagian("Pilih Bagian");
    setFilterProduk("Pilih Produk");
  };

  let filterProdukSelect = [];

  laporanSP &&
    laporanSP.forEach((e) => {
      let check =
        filterProdukSelect && filterProdukSelect.find((o) => o === e.produk);
      if (!check) {
        filterProdukSelect.push(e.produk);
      }
    });

  const searchFeatureMesinInjeksi = () => {
    if (filterMesinInjeksi !== "Pilih Mesin" || target !== "Pilih Target") {
      let searchName = [];
      if (filterMesinInjeksi !== "Pilih Mesin" && target === "Pilih Target") {
        dataDashbord &&
          dataDashbord.forEach((value) => {
            if (value.mesin === filterMesinInjeksi) {
              searchName.push(value);
            }
          });
        return searchName;
      } else if (filterMesinInjeksi === "Pilih Mesin" && target === "Target") {
        dataDashbord &&
          dataDashbord.forEach((value) => {
            if (
              Number(value && value.targetProduk) *
                Number(value && value.aktualCavity) <=
              Number(value && value.jumlahBarang) +
                Number(value && value.jumlahNG)
            ) {
              searchName.push(value);
            }
          });
        return searchName;
      } else if (filterMesinInjeksi === "Pilih Mesin" && target === "Tidak") {
        dataDashbord &&
          dataDashbord.forEach((value) => {
            if (
              Number(value && value.targetProduk) *
                Number(value && value.aktualCavity) >=
              Number(value && value.jumlahBarang) +
                Number(value && value.jumlahNG)
            ) {
              searchName.push(value);
            }
          });
        return searchName;
      } else if (filterMesinInjeksi !== "Pilih Mesin" && target === "Target") {
        dataDashbord &&
          dataDashbord.forEach((value) => {
            if (
              value.mesin === filterMesinInjeksi &&
              Number(value && value.targetProduk) *
                Number(value && value.aktualCavity) <=
                Number(value && value.jumlahBarang) +
                  Number(value && value.jumlahNG)
            ) {
              searchName.push(value);
            }
          });
        return searchName;
      } else if (filterMesinInjeksi !== "Pilih Mesin" && target === "Tidak") {
        dataDashbord &&
          dataDashbord.forEach((value) => {
            if (
              value.mesin === filterMesinInjeksi &&
              Number(value && value.targetProduk) *
                Number(value && value.aktualCavity) >=
                Number(value && value.jumlahBarang) +
                  Number(value && value.jumlahNG)
            ) {
              searchName.push(value);
            }
          });
        return searchName;
      }
    } else {
      return dataDashbord && dataDashbord;
    }
  };
  let dataTempMesinInjeksi = searchFeatureMesinInjeksi(filterMesinInjeksi);

  let filterMesinSelect = [];
  dataDashbord &&
    dataDashbord.forEach((e) => {
      let check =
        filterMesinSelect && filterMesinSelect.find((o) => o === e.mesin);
      if (!check) {
        filterMesinSelect.push(e.mesin);
      }
    });

  let filterMesinOperator = [];
  historyDataOperator &&
    historyDataOperator.forEach((e) => {
      let check =
        filterMesinOperator && filterMesinOperator.find((o) => o === e.mesin);
      if (!check) {
        filterMesinOperator.push(e.mesin);
      }
    });

  const searchFeatureMesinOperator = () => {
    if (filterMesinInjeksi !== "Pilih Mesin") {
      let searchName = [];
      historyDataOperator &&
        historyDataOperator.forEach((value) => {
          if (value.mesin === filterMesinInjeksi) {
            searchName.push(value);
          }
        });
      return searchName;
    } else {
      return historyDataOperator && historyDataOperator;
    }
  };
  let dataTempMesinOperator = searchFeatureMesinOperator(filterMesinInjeksi);

  return {
    dataUser,
    dateDashboard,
    setDateDashboard,
    shiftDashboard,
    setShiftDashboard,
    historyDataOperator,
    handleFormChange,
    isEdit,
    setIsEdit,
    handleKirimEditOperator,
    handleKirimEditAdmin,
    popUpSubmit,
    setPopUpSubmit,
    dataLaporanOperatorQc,
    popUpDelete,
    handleDeleteLaporan,
    handleSetDataDelete,
    dataDelete,
    handleCancelDelete,
    dataLaporanPasangKaret,
    handleDeleteLaporanPasangKaret,
    handleSetDataDeletePasangKaret,
    popUpDeletePasangKaret,
    dataDeletePasangKaret,
    handleCancelDeletePasangKaret,
    dataQc,
    handleChangeMesin,
    handleFormChangePasangKaret,
    subMenu,
    setSubMenu,
    setDateMonitoringSP,
    dateMonitoringSP,
    isEditSP,
    setIsEditSP,
    handleFormChangeSP,
    handleKirimEditSP,
    exportBulanan,
    popUpLaporanBulananSP,
    setPopUpLaporanBulananSP,
    bulanExportSP,
    setBulanExportSP,
    tahunExportSP,
    setTahunExportSP,
    isOn,
    filterBagian,
    setFilterBagian,
    dataTemp,
    handleReset,
    filterProduk,
    setFilterProduk,
    filterProdukSelect,
    filterMesinInjeksi,
    setFilterMesinInjeksi,
    filterMesinSelect,
    dataTempMesinInjeksi,
    filterMesinOperator,
    dataTempMesinOperator,
    target,
    setTarget,
  };
};
