import { useEffect, useState } from "react";
import { postAxios } from "../../util/apiCall";
import { validateRole } from "../../util/utilFunction";

export const useListProdukSP = () => {
  const dataAuth = localStorage.getItem("auth");
  const dataUser = JSON.parse(dataAuth);

  const [isRefresh, setIsRefresh] = useState(false);
  const [listProduk, setListProduk] = useState();
  const [customer, setCustomer] = useState("GS");
  const [namaProduk, setNamaProduk] = useState("");
  const [addNewCustomer, setAddNewCustomer] = useState("Pilih Perusahaan");
  const [popUpTambahProduk, setPopUpTambahProduk] = useState(false);
  const [targetPrinting, setTargetPrinting] = useState("");
  const [targetPunching, setTargetPunching] = useState("");
  const [targetSticker, setTargetSticker] = useState("");

  const [targetUltrasonic, setTargetUltrasonic] = useState("");
  const [targetTestBocor, setTargetTestBocor] = useState("");
  const [targetFillingPlug, setTargetFillingPlug] = useState("");
  const [targetSortir, setTargetSortir] = useState("");
  const [targetPutusRonce, setTargetPutusRonce] = useState("");
  const [targetBnw, setTargetBnw] = useState("");
  const [targetPasangHandle, setTargetPasangHandle] = useState("");
  const [targetValveFilter, setTargetValveFilter] = useState("");

  const [popUpEdit, setPopUpEdit] = useState(false);
  const [pickProduk, setPickProduk] = useState();

  useEffect(() => {
    postAxios(
      `${process.env.REACT_APP_ENDPOINT}/secondProcess`,
      { tim: dataUser.tim, bagian: validateRole(), type: "getProduk" },
      dataUser.auth,
      setListProduk,
      ""
    );
    setIsRefresh(false);
  }, [isRefresh]);

  const dataPick = () => {
    let data;
    listProduk &&
      listProduk.forEach((element) => {
        if (element.customer === customer) {
          data = element;
        }
      });
    return data;
  };
  const Material = dataPick();

  const handleAddProduk = () => {
    const dataPR = {
      targetPrint: Number(targetPrinting),
      targetPunching: Number(targetPunching),
      targetPasangStiker: Number(targetSticker),
      produk: namaProduk,
    };
    const dataSP = {
      targetUltrasonic: Number(targetUltrasonic),
      targetTestBocor: Number(targetTestBocor),
      targetFilling: Number(targetFillingPlug),
      targetSortir: Number(targetSortir),
      targetPutusRonce: Number(targetPutusRonce),
      targetValveFilter: Number(targetValveFilter),
      targetBnw: Number(targetBnw),
      targetPasangHandle: Number(targetPasangHandle),
      produk: namaProduk,
    };

    postAxios(
      `${process.env.REACT_APP_ENDPOINT}/secondProcess`,
      {
        tim: dataUser.tim,
        bagian: validateRole(),
        type: "addNewProduk",
        customer: addNewCustomer,
        data: validateRole() === "SP" ? dataSP : dataPR,
      },
      dataUser.auth,
      "",
      ""
    );
    setIsRefresh(true);
    setNamaProduk("");
    setTargetPrinting("");
    setTargetPunching("");
    setTargetSticker("");
    setTargetUltrasonic("");
    setTargetTestBocor("");
    setTargetFillingPlug("");
    setTargetPutusRonce("");
    setTargetSortir("");
    setTargetBnw("");
    setTargetValveFilter("");
    setTargetPasangHandle("");
    setPopUpTambahProduk(false);
    setAddNewCustomer("Pilih Perusahaan");
  };

  const handlePickEdit = (e) => {
    setPickProduk(e);
    setPopUpEdit(true);
  };

  const handleChangeEditProduk = (event) => {
    setPickProduk({
      ...pickProduk,
      [event.target.name]: Number(event.target.value),
    });
  };

  const handleKirimEditProduk = () => {
    postAxios(
      `${process.env.REACT_APP_ENDPOINT}/secondProcess`,
      {
        tim: dataUser.tim,
        bagian: validateRole(),
        type: "editProduk",
        customer: customer,
        data: pickProduk,
      },
      dataUser.auth,
      "",
      ""
    );
    setIsRefresh(true);
    setPopUpEdit(false);
  };

  return {
    dataUser,
    customer,
    setCustomer,
    Material,
    handleAddProduk,
    namaProduk,
    setNamaProduk,
    targetPrinting,
    setTargetPrinting,
    targetPunching,
    setTargetPunching,
    targetSticker,
    setTargetSticker,
    targetUltrasonic,
    setTargetUltrasonic,
    targetTestBocor,
    setTargetTestBocor,
    targetFillingPlug,
    setTargetFillingPlug,
    popUpTambahProduk,
    setPopUpTambahProduk,
    addNewCustomer,
    setAddNewCustomer,
    popUpEdit,
    setPopUpEdit,
    handlePickEdit,
    pickProduk,
    handleChangeEditProduk,
    handleKirimEditProduk,
    targetSortir,
    setTargetSortir,
    targetPutusRonce,
    setTargetPutusRonce,
    targetBnw,
    setTargetBnw,
    targetPasangHandle,
    setTargetPasangHandle,
    targetValveFilter,
    setTargetValveFilter,
  };
};
