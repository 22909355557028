import { useEffect, useState } from "react";
import { postAxios } from "../../util/apiCall";
import { today } from "../../util/formatDate";
import { v4 as uuidv4 } from "uuid";

export const useLemburKaryawan = () => {
  const v4Id = uuidv4();
  const dataAuth = localStorage.getItem("auth");
  const dataUser = JSON.parse(dataAuth);

  const [dateFilter, setDateFilter] = useState(today);
  const [filterKepala, setFilterKepala] = useState("Pilih Kepala");
  const [listOperator, setListOperator] = useState();
  const [operatorPick, setOperatorPick] = useState();
  const [rencanaMasuk, setRencanaMasuk] = useState();
  const [rencanaKeluar, setRencanaKeluar] = useState();
  const [pekerjaan, setPekerjaan] = useState("");
  const [catatan, setCatatan] = useState("");
  const [isReload, setIsReload] = useState(false);

  const [filterKepalaList, setFilterKepalaList] = useState("Pilih Kepala");

  const [listLembur, setListLembur] = useState();

  useEffect(() => {
    postAxios(
      `${process.env.REACT_APP_ENDPOINT}/formLembur`,
      {
        tim: dataUser.tim,
        type: "getLembur",
        id: dataUser.id,
        kepala: filterKepalaList,
        date: dateFilter,
      },
      dataUser.auth,
      setListLembur,
      ""
    );
    setIsReload(false);
  }, [isReload, filterKepalaList, dateFilter]);

  useEffect(() => {
    postAxios(
      `${process.env.REACT_APP_ENDPOINT}/formLembur`,
      { tim: dataUser.tim, type: "getUser" },
      dataUser.auth,
      setListOperator,
      ""
    );
  }, []);

  let filterCatatan = [];

  listLembur &&
    listLembur.forEach((e) => {
      let check = filterCatatan && filterCatatan.find((o) => o === e.catatan);

      if (!check) {
        filterCatatan.push(e.catatan);
      }
    });

  let filterApproval = [];

  listLembur &&
    listLembur.forEach((e) => {
      let checkApproval =
        filterApproval && filterApproval.find((o) => o === e.isApprove);
      if (!checkApproval) {
        filterApproval.push(e.isApprove);
      }
    });

  let reFormat = [];
  listOperator &&
    listOperator.forEach((element) => {
      reFormat.push({ value: element.id, label: element.name });
    });

  const handleKirimLembur = async () => {
    if (
      operatorPick &&
      rencanaMasuk &&
      rencanaKeluar &&
      filterKepala !== "Pilih Kepala"
    ) {
      await postAxios(
        `${process.env.REACT_APP_ENDPOINT}/formLembur`,
        {
          uuid: v4Id,
          name: operatorPick && operatorPick.label,
          id: operatorPick && operatorPick.value,
          tim: dataUser.tim,
          isApprove: false,
          aktualMasuk: null,
          aktualKeluar: null,
          date: dateFilter,
          rencanaMasuk: `${rencanaMasuk && rencanaMasuk.value}:00:00`,
          rencanaKeluar: `${rencanaKeluar && rencanaKeluar.value}:00:00`,
          pekerjaan: pekerjaan,
          catatan: catatan,
          kepala: filterKepala,
          type: "submitLembur",
        },
        dataUser.auth,
        "",
        ""
      );
      setIsReload(true);
      setOperatorPick();
    }
  };

  return {
    listLembur,
    filterCatatan,
    dateFilter,
    setDateFilter,
    filterKepala,
    setFilterKepala,
    reFormat,
    dataUser,
    operatorPick,
    setOperatorPick,
    rencanaMasuk,
    setRencanaMasuk,
    rencanaKeluar,
    setRencanaKeluar,
    pekerjaan,
    setPekerjaan,
    catatan,
    setCatatan,
    handleKirimLembur,
    setIsReload,
    filterKepalaList,
    setFilterKepalaList,
    filterApproval,
  };
};
