import React from "react";
import LogoGs from "../../Assets/GS-Logo.png";
import LogoYBI from "../../Assets/YBI-Logo.jpeg";
import LogoTBP from "../../Assets/TBP-Logo.jpeg";
import LogoMelco from "../../Assets/MELCO-Logo.png";
import LogoTIM from "../../Assets/TimLogo.png";
import LogoCBI from "../../Assets/CBI-Logo.png";
import LogoMitra from "../../Assets/Mitra_logo.png";
import CardComponent from "../../components/Card/CardComponent";
import { useMold } from "./useMold";
import TableListMold from "../../components/TableListMold/TableListMold";
import PopUpComponent from "../../components/PopUp/PopUpComponent";
import CloseLogo from "../../Assets/close_icon.png";

const Mold = () => {
  const {
    dataUser,
    companyPickList,
    setCompanyPickList,
    MoldList,
    setDataListDelete,
    popUpDeleteMold,
    setPopUpDeleteMold,
    handleDeleteMold,
    handleEditMold,
    popUpEditMold,
    setPopUpEditMold,
    popUpTambahMold,
    setPopUpTambahMold,
    dataListMold,
    handleKirimNewMold,
    newMold,
    setNewMold,
    newMoldColor,
    setNewMoldColor,
    companyNewMold,
    setCompanyNewMold,
    setNewTargetProduk,
    newTargetProduk,
    editNamaMold,
    setEditNamaMold,
    setEditColorMold,
    editColorMold,
    material,
    setMaterial,
    targetProduk,
    setTargetProduk,
    total,
    setTotal,
  } = useMold();

  return (
    <div className="container_inventory">
      {popUpTambahMold ? (
        <PopUpComponent scroll>
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <img
              className="width_edit_logo"
              src={CloseLogo}
              onClick={() => setPopUpTambahMold(false)}
              alt="edit_logo"
            />
          </div>
          <div className="wrapper_content_popup">
            <div className="wrapper_input">
              <p className="font_weight_bold">
                Perusahaan<label style={{ color: "red" }}>*</label>
              </p>
              <select
                defaultValue="Pilih Nama Perusahaan"
                onChange={(e) => setCompanyNewMold(e.target.value)}
                className="input_select"
                style={{ width: "192px" }}
              >
                <option selected={companyNewMold === ""} disabled>
                  Pilih Nama Perusahaan
                </option>
                {dataListMold &&
                  dataListMold.map((data) => (
                    <option value={data.company}>{data.company}</option>
                  ))}
              </select>
            </div>

            <div className="wrapper_input">
              <p className="font_weight_bold">
                Nama Mold<label style={{ color: "red" }}>*</label>
              </p>
              <input
                type="text"
                className="input_jumlah"
                placeholder="Masukkan Nama Mold"
                value={newMold}
                onChange={(e) => setNewMold(e.target.value)}
              />
            </div>
            <div className="wrapper_input">
              <p className="font_weight_bold">
                Warna Mold<label style={{ color: "red" }}>*</label>
              </p>
              <input
                type="text"
                className="input_jumlah"
                placeholder="Masukkan Warna Mold"
                value={newMoldColor}
                onChange={(e) => setNewMoldColor(e.target.value)}
              />
            </div>

            <div className="wrapper_input">
              <p className="font_weight_bold">
                Material<label style={{ color: "red" }}>*</label>
              </p>
              <input
                type="text"
                className="input_jumlah"
                placeholder="Material"
                value={material}
                onChange={(e) => setMaterial(e.target.value)}
              />
            </div>
            <div className="wrapper_input">
              <p className="font_weight_bold">Target Per Cavity</p>
              <input
                type="number"
                className="input_jumlah"
                placeholder="Target"
                value={targetProduk}
                onChange={(e) => setTargetProduk(e.target.value)}
                onWheel={(e) => e.target.blur()}
              />
            </div>
            <div className="wrapper_input">
              <p className="font_weight_bold">Total Material Per Hari</p>
              <input
                type="number"
                className="input_jumlah"
                placeholder="Total Material"
                value={total}
                onChange={(e) => setTotal(e.target.value)}
                onWheel={(e) => e.target.blur()}
              />
            </div>
          </div>
          <br />
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <button
              className="button_popup background_red"
              onClick={() => handleKirimNewMold()}
            >
              Kirim
            </button>
          </div>
        </PopUpComponent>
      ) : (
        ""
      )}
      <div className="wrapper_header">
        <p className="title_inventory">LIST MOLD</p>
        <div
          className="wrapper_tambah_list"
          onClick={() => setPopUpTambahMold(true)}
        >
          <div className="icon_add_list">+&nbsp;</div>
          <div className="font_tambah_button">Tambah</div>
        </div>
      </div>
      <div className="flex_wrap">
        <CardComponent
          active={companyPickList === "GS"}
          title="PT. GS"
          logo={LogoGs}
          onClick={() => setCompanyPickList("GS")}
        />
        <CardComponent
          active={companyPickList === "YBI"}
          title="PT. YBI"
          logo={LogoYBI}
          onClick={() => setCompanyPickList("YBI")}
        />
        <CardComponent
          active={companyPickList === "TBP"}
          title="PT. TBP"
          logo={LogoTBP}
          onClick={() => setCompanyPickList("TBP")}
        />
        <CardComponent
          active={companyPickList === "MELCO"}
          title="PT. MELCO"
          logo={LogoMelco}
          onClick={() => setCompanyPickList("MELCO")}
        />
        <CardComponent
          active={companyPickList === "CBI"}
          title="PT. CBI"
          logo={LogoCBI}
          onClick={() => setCompanyPickList("CBI")}
        />
        {dataUser.tim === 2 ? (
          <CardComponent
            active={companyPickList === "MITRA"}
            title="PT. MITRA"
            logo={LogoMitra}
            onClick={() => setCompanyPickList("MITRA")}
          />
        ) : (
          ""
        )}
        <CardComponent
          active={companyPickList === "TIM"}
          title="PT. TIM"
          logo={LogoTIM}
          onClick={() => setCompanyPickList("TIM")}
        />
      </div>
      <TableListMold
        data={MoldList && MoldList.data}
        setDataPickDelete={setDataListDelete}
        popUpDeleteProduk={popUpDeleteMold}
        setPopUpDeleteProduk={setPopUpDeleteMold}
        onClick={() => handleDeleteMold()}
        onClickEdit={() => handleEditMold()}
        popUpEditProduk={popUpEditMold}
        setPopUpEditMold={setPopUpEditMold}
        setNewTargetProduk={setNewTargetProduk}
        newTargetProduk={newTargetProduk}
        editNamaMold={editNamaMold}
        setEditNamaMold={setEditNamaMold}
        setEditColorMold={setEditColorMold}
        editColorMold={editColorMold}
        material={material}
        setMaterial={setMaterial}
        total={total}
        setTotal={setTotal}
      />
    </div>
  );
};
export default Mold;
